import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/contactAction';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

class ContactList extends Component {

  componentDidMount() {
    const shutterId = this.props.match.params.shutterId;
    this.props.getContactList(shutterId)
  }

  render(){
    this.shutterId = this.props.match.params.shutterId;

    return(
      <div className="card-text card-body-common">
        <Row noGutters={true} className="content-header">
          <Col xs="auto" className="d-flex align-items-end">
            連絡先一覧
          </Col>
          <Col></Col>
          <Col xs="auto">
            <Link to={`/contact/create/${this.shutterId}`}
                  className="btn btn-common btn-success">
              新規作成
            </Link>
          </Col>
        </Row>

        {this.formContents()}
      </div>
    );
  }

  formContents(){
    const list = this.props.contact.list;
    if(list.length){
      return(
        <table className="table table-hover table-common">
          <tbody>
            {
              list.map(data => {
                return(
                  <tr key={data.contact_id} className=""
                      onClick={() => this.onClickItem(data.contact_id)}>
                    <td>
                      <Row noGutters={true} className="">
                        <Col xs="auto" className="contact-list-item">
                          {data.company}
                        </Col>
                        <Col xs="auto" className="contact-list-item">
                          {data.department}
                        </Col>
                        <Col xs="auto" className="contact-list-item">
                          {data.person_name}
                        </Col>
                      </Row>

                      <Row noGutters={true} className="contact-list-tel-row">
                        {this.formItemData("Tel",data.tel)}
                        {this.formItemData("Fax",data.fax)}
                      </Row>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      )
    }else{
      return(
        <div className="content-body">
          データがありません。新規作成を行ってください
        </div>
      )
    }
  }

  formItemData(title, data){
    if(data === ""){
      return null
    }else{
      return(
        <Col xs="auto" className="contact-list-tel-item">
          <div>{title} : {data}</div>
        </Col>
      )
    }
  }

  onClickItem(contactId){
    this.props.history.push(`/contact/edit/${this.shutterId}/${contactId}`);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);
