import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/trendAction';
import DriveGraph from 'modules/driveGraph';

class ItemDiTrend extends Component {
  constructor(props) {
    super(props);
    this.shutterId = this.props.shutterId;
    this.dataNo = this.props.dataNo;
    this.idKey = `${this.props.shutterId}-${this.props.dataNo}`;
    this.canvasKey = `di-trend-canvas-${this.idKey}`;
    this.driveGraph = new DriveGraph(this.canvasKey);
  }

  componentDidMount() {}

  render(){
    const diDataForIds = this.props.top.diData[this.props.shutterId];
    let diData = null;
    let driveCount = "-";
    let driveTime = "-";
    let maxDriveTime = "-";

    if(diDataForIds){
      const data = diDataForIds[this.props.dataNo];

      diData = data.diData;
      driveCount = data.driveCount;
      driveTime = data.driveTime;
      maxDriveTime = data.maxDriveTime;
    }

    let name;
    if(this.props.name){ name = `${this.props.name} : `}
    this.driveGraph.draw(diData);

    return(
      <div className="col-sm-6">
        <div className="card item-di-trend" id={`di-trend-area-${this.idKey}`}>
          <div className="card-header item-di-trend-title">
            {name}{this.props.dataName}
          </div>

          <div className="card-text item-di-trend-data card-body-common">

            <canvas className="di-trend-canvas" id={this.canvasKey}/>

            <div className="row no-gutters justify-content-between">
              <div className="col di-trend-v-area">
                <div className="row no-gutters justify-content-between">
                  <div className="col-auto di-trend-v-title">開閉回数</div>
                  <div className="col-auto di-trend-v-value">
                    <span className="di-trend-v-value-font">{driveCount}</span>回
                  </div>
                </div>
              </div>

              <div className="col di-trend-v-area di-trend-v-area-space">
                <div className="row no-gutters justify-content-between">
                  <div className="col-auto di-trend-v-title">開時間合計</div>
                  <div className="col-auto di-trend-v-value">
                    <span className="di-trend-v-value-font">{driveTime}</span>分
                  </div>
                </div>
              </div>

              <div className="col di-trend-v-area">
                <div className="row no-gutters justify-content-between">
                  <div className="col-auto di-trend-v-title">最大開時間</div>
                  <div className="col-auto di-trend-v-value">
                    <span className="di-trend-v-value-font">{maxDriveTime}</span>分
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ItemDiTrend);
