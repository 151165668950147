import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/mainMapAction';

class ModalBodyPartsList extends Component {
  componentDidMount() {
    this.props.clearShutterPopover();
  }

  render() {
    return (
      <table className="table table-sm table-striped">
      	<thead>
      	  <tr>
      	    <th scope="col" className="parts-list-header-item">部品CD</th>
      	    <th scope="col" className="parts-list-header-item">部品名称</th>
      	    <th scope="col" className="parts-list-header-item">数量</th>
      	  </tr>
      	</thead>
      	<tbody>
          {this.props.shutter.parts.map((data, i) => {
            return (
              <tr key={i}>
        	      <td>{data.part_cd}</td>
        	      <td>{data.name}</td>
        	      <td>{data.count}</td>
        	    </tr>
            );
          })}
      	</tbody>
      </table>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalBodyPartsList);
