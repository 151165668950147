import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/mainMapAction';
import * as cookies from 'modules/cookies.js';
import * as module from 'modules/moduleMainMap.js';
import MenuHome from 'images/menu_home.png'
import MenuDataMAP from 'images/menu_data_map.png'
import MenuStatus from 'images/menu_status.png'
import MenuSetting from 'images/menu_setting.png'
import MenuReport from 'images/menu_report.png'
import MenuMaintenance from 'images/menu_maintenance.png'
import MenuInspection from 'images/menu_inspection.png'
import MenuContact from 'images/menu_contact.png'

const MENU_LIST = [
  { menuCD: 1, menuKey: "main_maps",
    name: "ホーム", icon: MenuHome },
  { menuCD: 2, menuKey: "data_maps",
    name: "データ一覧", icon: MenuDataMAP },
  { menuCD: 3, menuKey: "shutter",
    name: "状態表示", icon: MenuStatus },
  { menuCD: 4, menuKey: "setting",
    name: "遠隔設定", icon: MenuSetting },
  //{ menuCD: 5, menuKey: "contact",
  //  name: "警報履歴",   icon: MenuErrorList },
  { menuCD: 6, menuKey: "report",
   name: "日報",   icon: MenuReport },
  { menuCD: 7, menuKey: "maintenance",
   name: "ﾒﾝﾃﾅﾝｽ", icon: MenuMaintenance },
  { menuCD: 8, menuKey: "inspection",
    name: "設備点検", icon: MenuInspection,
    userTypes: [7, 8, 9] },
  { menuCD: 9, menuKey: "contact",
   name: "連絡先", icon: MenuContact }
]

class MenuList extends Component {
  render() {
    const layoutW = this.props.layoutW;
    const selectedMenu = cookies.get("menuKey");
    const shutterId = cookies.getShutterId();
    const sMap = this.props.common.shuttersMap[shutterId];

    return (
      <div className="menu-list">
        {
          MENU_LIST.map(data =>{
            // 対象設備をまだ選択していない場合、「設備リスト」のみ表示
            if((shutterId < 0 || sMap === undefined) && data["menuKey"] !== "main_maps"){
              return undefined;
            }

            // 接続できる設備が一台もない場合、データ一覧メニューは非表示
            if(data["menuKey"] === "data_maps" && !this.props.common.enableConnectShutters){
              return undefined;
            }

            // マーカー施設は遠隔設定メニュー非表示
            if(data["menuKey"] === "setting" && sMap.deviceType >= 90){
              return undefined;
            }

            // ユーザータイプによるメニュー表示
            if(data["userTypes"]){
              const userType = Number(cookies.get("userType"));
              const isAuthType = data["userTypes"].find(d => d === userType);
              if(!isAuthType){
                return undefined;
              }
            }

            let selected = "";
            if(selectedMenu !== "main_maps") {
              selected = selectedMenu === data["menuKey"] ? "selected" : "";
            }

            const title = data["menuCD"] === 1 ? "設備リスト" : data["name"]

            let html = (
              <div key={data["menuCD"]} className="menu-item">
                <img src={data["icon"]} alt={data["menuCD"]} className={selected}
                     title={`${title}を表示します`}
                     onClick={() => this.onClickMenu(shutterId, data["menuKey"])}/>
                <div className="menu-text">{data["name"]}</div>
              </div>
            )
            if(layoutW < 576){
              html = (
                <div key={data["menuCD"]} className="menu-item float-left">
                  <img src={data["icon"]} alt={data["menuCD"]} className={selected}
                       title={`${title}を表示します`}
                       onClick={() => this.onClickMenu(shutterId, data["menuKey"])}/>
                  <div className="menu-text">{data["name"]}</div>
                </div>
              )
            }

            return html
          }).filter(e => e)
        }
      </div>
    );
  }

  onClickMenu(shutterId, menuKey){
    module.showMenu(shutterId, menuKey, this.props)
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuList));
