import { store } from 'modules/store.js';
import { instance as http, instanceImage as http2, } from 'modules/httpConnection'
import * as cookies from 'modules/cookies.js';
import * as module from 'modules/utils';
import { getMaintenanceList } from 'modules/actions/maintenanceAction';
import { getContactList } from 'modules/actions/contactAction';
import { getInspectionList } from 'modules/actions/inspectionAction';
import { getFinderList } from 'modules/actions/finderAction';
import { clearReporCacheData } from 'modules/actions/reportAction';
import { getShutterStatus } from 'modules/actions/shutterAction';
import { getRemoteSettingData } from 'modules/actions/settingAction';


export function mapStateToProps(state) {
  return state;
}

export function mapDispatchToProps(dispatch) {
  return {
    /*
      シャッターリストからの対象シャッターを選択
    */
    storeShutterListId:(factoryId, shutterId) => {
      cookies.set("factoryId", factoryId);
      cookies.set("shutterId", shutterId);
      store.dispatch( {
        type: 'SELECTED_ID',
        factoryId: factoryId,
        shutterId: shutterId
      });
    },

    /*
      シャッター写真を取得
    */
    getFactoryMapImage: (factoryId, callback) => {
      connectFactoryMapImage(factoryId, callback);
    },

    /*
      シャッターリストからの対象工場を選択し、平面図を取得する
    */
    changeFlowMap:(factoryId) => {
      store.dispatch( {
        type: 'SELECTED_ID',
        factoryId: factoryId,
        shutterId: ""
      });

      cookies.set("factoryId", factoryId);
      cookies.set("shutterId", "");

      connectFactoryMapImage(factoryId);
    },

    /*
      平面図情報を取得
    */
    getFactoryMapData: (factoryId, callback = () => {}) => {
      const menuKey = cookies.get("menuKey")
      const companyId = cookies.get("companyId");
      let buildingId = 0;
      if(factoryId > 0){ buildingId = cookies.get('buildingId') }

      const params = new URLSearchParams();
      params.append('company_id', companyId);
      params.append('factory_id', factoryId);
      params.append('building_id', buildingId);

      http.post('main_maps/map_data', params)
        .then((response) => {
          const shutters = response.data.shutters;
          const analogs = response.data.analogs;
          const pulses = response.data.pulses;
          const resFactoryId = response.data.factory_id;
          const shuttersMap = {}

          const ary = shutters.filter(val => {
            return val["device_type"] < 90;
          })
          const enableConnectShutters = ary.length ? true : false;

          shutters.forEach(data => {
            parseShutterSpecData(data);

            shuttersMap[data.id] = {
              name: data.name,
              deviceType: data.device_type
            };
     
            if(menuKey === "main_maps"){
              connectGetShutterPhoto(data.id, "LIST", {resize: "small"})
            }
          });

          cookies.set("factoryId", resFactoryId);
          cookies.set("buildingId", response.data.building_id);
          store.dispatch( {
            type: 'FLOW_MAP_DATA',
            factoryId: resFactoryId,
            shutters: shutters,
            analogs: analogs,
            pulses: pulses,
            shuttersMap: shuttersMap,
            enableConnectShutters: enableConnectShutters
          });

          callback(response.data);
        })
        .catch((data) => {
          console.error(data);
        });
    },

    /*
      シャッター情報のポップアップ表示
    */
    showShutterPopover: (shutterId, showFlg) => {
      cookies.set("shutterId", shutterId);

      store.dispatch( {
        type: 'SHOW_POPOVER',
        shutterId: shutterId,
        showPopover: {[shutterId]: showFlg}
      });
    },

    /*
      シャッター情報のポップアップ表示を全て非表示にする
    */
    clearShutterPopover: () => {
      clearShutterPopover();
    },

    /*
      シャッターのMAP位置設定モード切り替え
    */
    changeMapPositionMode:(bool) => {
      store.dispatch( {type: 'MAINMAP_CHANGE_MAP_MODE', positionMode: bool});
    },

    /*
      シャッターアイコンのMAP位置移動
    */
    moveMapPosition:(shutterId, x, y) => {
      const pos = {}
      pos[shutterId] = {x: x, y: y}
      store.dispatch( {type: 'MAINMAP_MOVE_MAP_POSITION', ePos: pos});
    },

    /*
      シャッターのMAP位置設定モード切り替え
    */
    saveMapPosition:(shutterId, x, y) => {
      store.dispatch( {type: 'MAINMAP_SAVE_MAP_POSITION_LOADING'});

      const params = new URLSearchParams();
      params.append('x', x);
      params.append('y', y);

      http.post(`shutters/save_position/${shutterId}`,params)
        .then(response => {
          store.dispatch( {
            type: 'MAINMAP_SAVE_MAP_POSITION_RESULT',
            success: response.data.success,
            shutterId: shutterId,
            x: x, y: y
          });
        })
        .catch(data => {
          console.error(data);
        });
    },

    /*
      シャッター写真を取得
    */
    getShutterPhoto: (shutterId) => {
      store.dispatch( {
        type: 'MAINMAP_FLOW_SHUTTER_PHOTO',
        flowMapImage: undefined
      });

      connectGetShutterPhoto(shutterId, "FLOW", {resize: "small"})
    },

    /*
      シャッター情報を取得
    */
    getShutterStatus: (shutterId) => {
      getShutterStatus(shutterId, () => {});
    },

    /*
      遠隔設定情報を取得
    */
    getRemoteSettingData: (shutterId) => {
      getRemoteSettingData(shutterId);
    },

    /*
      メンテナンス情報リストを取得
    */
    getMaintenanceList: (shutterId) => {
      getMaintenanceList(shutterId);
    },

    /*
      設備点検情報リストを取得
    */
    getInspectionList: (shutterId) => {
      getInspectionList(shutterId);
    },

    /*
      資料情報リストを取得
    */
    getFinderList: (shutterId) => {
      getFinderList(shutterId)
    },

    /*
      連絡先情報リストを取得
    */
    getContactList: (shutterId) => {
      getContactList(shutterId)
    },

    /*
      帳票データのクリア
    */
    clearReporCacheData: () => {
      clearReporCacheData()
    },

    /*
      新規シャッター情報Modal表示
    */
    showNewShutterModal: () => {
      store.dispatch({type: 'MAINMAP_SHOW_MODAL', shutterId: -1});
    },

    /*
      シャッター情報Modal非表示
    */
    hideModal: () => {
      store.dispatch({type: 'MAINMAP_HIDE_MODAL'});
    },

    /*
      シャッタースペック情報の取得
    */
    getShutterSpec: (shutterId) => {
      cookies.set("shutterId", shutterId);
      // シャッター情報Modal表示
      store.dispatch( { type: 'MAINMAP_SHOW_MODAL', shutterId: shutterId});

      http.post(`shutters/spec_data/${shutterId}`)
        .then(response => {
          parseShutterSpecData(response.data, "");

          store.dispatch( {
            type: 'MAINMAP_MODAL_SHUTTER_SPEC',
            shutterId: shutterId,
            data: response.data
          });

          if(response.data.has_picture){
            connectGetShutterPhoto(shutterId, "MODAL", {resize: "medium"})
          }
        })
        .catch(data => {
          console.error(data);
        });
    },

    /*
      シャッター情報の保存
    */
    saveShutterSpec: (paramsId, params, callback) => {
      params.append('company_id', cookies.get("companyId"));
      params.append('factory_id', cookies.get("factoryId"));
      params.append('building_id', cookies.get("buildingId"));

      let url = paramsId < 0 ? "create" : `save_spec_data/${paramsId}`;

      http.post(`shutters/${url}`, params)
        .then(response => {
          let sId = paramsId;
          if(response.data.success){
            const shutter = response.data.data;
            sId = shutter.id;
            parseShutterSpecData(shutter, "")

            // 対象の設備として変更する
            cookies.set("shutterId", shutter.id);

            const type = paramsId < 0 ? "MAINMAP_MODAL_CREATE_SHUTTER" : "MAINMAP_MODAL_SAVE_SHUTTER_SPEC";
            store.dispatch( { type: type, data: shutter });

            if(shutter.has_picture){
              connectGetShutterPhoto(shutter.id, "MODAL", {resize: "medium"})
            }
          }

          callback(response.data.success, sId)
        })
        .catch(data => {
          console.error(data);
        });

    },

    /*
      シャッター情報の削除
    */
    deleteShutter: (shutterId, callback) => {
      http.post(`shutters/delete/${shutterId}`)
        .then(response => {
          if(response.data.success){
            const shutters = store.getState().main_map.shutters
            let sIndex = shutters.findIndex(s => s.id === shutterId);
            // シャッターリストから該当のシャッターを削除
            const sList = shutters.filter(s => s.id !== shutterId);

            // 削除後のシャッターリストで、今回削除したシャッターのリスト位置を比較し
            // 削除した項目の次の項目を選択設備とする（一番最後の場合は一番最後）
            sIndex = sIndex === sList.length ? (sIndex - 1) : sIndex;
            const selectedId = sList[sIndex] ? sList[sIndex].id : "";

            cookies.set("shutterId", selectedId);

            store.dispatch( {
              type: "MAINMAP_MODAL_DELETE_SHUTTER",
              shutters: sList,
              selectedId: selectedId
            });
          }

          callback(response.data.success);
        })
        .catch(data => {
          console.error(data);
        });

    },

    selectSpecYear: (year) => {
      store.dispatch({type: 'MAINMAP_MODAL_SELECT_YEAR', year: year});
    },

    selectSpecMonth: (month) => {
      store.dispatch({type: 'MAINMAP_MODAL_SELECT_MONTH', month: month});
    }

  }
}

export function clearShutterPopover(){
  store.dispatch( { type: 'CLEAR_POPOVER' });
}

function parseShutterSpecData(data, defaultValue = "-"){
  if(data.device_type >= 90){
    data.drive_time = "-"
  }else{
    data.drive_time = Math.floor(data.drive_time / 60);
  }
  if(data.product_no === "") data.product_no = defaultValue;
  if(data.product_type === "") data.product_type = defaultValue;
  if(data.maker === "") data.maker = defaultValue;
  if(data.width === null) data.width = defaultValue;
  if(data.height === null) data.height = defaultValue;
  if(data.drive_count === -1) data.drive_count = defaultValue;
  if(data.total_drive_count === -1 || data.total_drive_count === null){
    data.total_drive_count = defaultValue;
  }
  if(data.construction_date === "") {
    data.construction_date = defaultValue;
    data.construction_year = 0;
    data.construction_month = 0;
  }else{
    const dispDate = module.formatDateTime(data.construction_date, "yyyy年mm月");
    const year = module.formatDateTime(data.construction_date, "yyyy");
    const month = module.formatDateTime(data.construction_date, "mm");
    data.construction_date = dispDate;
    data.construction_year = Number(year);
    data.construction_month = Number(month);
  }

}

function connectFactoryMapImage(factoryId, callback){
  const companyId = cookies.get("companyId");
  let buildingId = 0;
  if(factoryId > 0){ buildingId = cookies.get('buildingId') }

  const params = new URLSearchParams();
  params.append('company_id', companyId);
  params.append('factory_id', factoryId);
  params.append('building_id', buildingId);

  http2.post(`main_maps/file_data`, params)
    .then(response => {
      const blob = new Blob([ response.data ], { type: "image/jpeg" });

      store.dispatch( {
        type: 'FLOW_MAP_IMAGE',
        flowMapImage: blob
      });

      callback();
    })
    .catch(data => {
      console.error(data);
    });
}

function connectGetShutterPhoto(shutterId, type, params={}){
  const setParams = new URLSearchParams();
  if(params["resize"]){
    setParams.append('resize', params["resize"]);
  }

  http2.post(`shutters/photo/${shutterId}`, setParams)
    .then(response => {
      const blob = new Blob([ response.data ], { type: "image/*" });

      store.dispatch( {
        type: `MAINMAP_${type}_SHUTTER_PHOTO`,
        shutterId: shutterId,
        photoData: blob
      });
    })
    .catch(data => {
      console.error(data);
    });
}

export function reflectDataToMainMap(id){
  console.log(`DEBUG:getSensorData start: sensorId=${id}`);
  http.post(`main_maps/sensor_data/${id}`)
    .then((response) => {
      console.log(`DEBUG:getSensorData ok: sensorId=${id}`);
      store.dispatch( {
        type: 'UPDATE_SENSOR_DATA_FOR_MAP',
        sensor_data: response.data
      });
    })
    .catch((data) => {
      console.error(data);
    });
}

/*
  工場面図データをクリア
    ::工場平面図画面を非表示にする際には、この関数を呼ぶこと
*/
export function clearMainMapInfo(){
  store.dispatch( {type: 'CLEAR_MAINMAP_INFO'} );
}
