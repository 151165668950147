import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/shutterAction';

class ContentNowInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {fontSize: 25};
    window.addEventListener('resize', () => {
      this.onResize();
    });
  }

  componentDidMount() {
    this.onResize();
  }

  render() {
    const props = this.props.shutter;

    return (
      <div className="row no-gutters shutter-info-content-group p-0">

        <div className="col text-center s-now-info-group">
          <div className="s-now-info-height" id="s-now-open-count">
            <div className="s-now-info-item">
              <div className="now-info-item-title"
                   style={{fontSize: `${this.state.fontSize}px`}}>
                   本日の開閉回数
              </div>
              <div className="now-info-item-data"
                   style={{fontSize: `${this.state.fontSize + 5}px`}}>
                   { props.driveCount }回
              </div>
            </div>
          </div>
        </div>

        <div className="col text-center s-now-info-group">
          <div className="s-now-info-height" id="s-now-open-time">
            <div className="s-now-info-item">
              <div className="now-info-item-title"
                   style={{fontSize: `${this.state.fontSize}px`}}>
                本日の開時間合計
              </div>
              <div className="now-info-item-data"
                   style={{fontSize: `${this.state.fontSize + 5}px`}}>
                   {props.driveTime}分
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onResize(){
    const obj = document.getElementById("s-now-open-count");

    if(obj != null){
      const width = obj.clientWidth;
      let fontSize = width * 0.112 - 0.9;
      if(fontSize >= 30) fontSize = 30;

      this.setState({
        fontSize: fontSize
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentNowInfo);
