import { store } from 'modules/store.js';
import { instance as http } from 'modules/httpConnection'

export function mapStateToProps(state) {
  return state;
}

export function mapDispatchToProps(dispatch) {
  return {
    /*
      資料情報リストを取得
    */
    getFinderList: (shutterId) => {
      getFinderList(shutterId);
    },

    /*
      資料あ情報リストを取得
    */
    getFinderDetail: (contactId, params) => {
      http.post(`finders/detail/${contactId}`, params)
        .then((response) => {
          store.dispatch( {
            type: 'FINDER_DETAIL',
            data: response.data
          });
        })
        .catch((data) => {
          console.error(data);
        });
    },

    /*
      資料情報の保存
    */
    save: (contactId, params, callback) => {
      http.post(`finders/save/${contactId}`, params)
        .then((response) => {
          let resultMsg = "保存しました"
          if(contactId <= 0) resultMsg = "新規に登録しました"

          store.dispatch( {
            type: 'FINDER_SAVE',
            saveResult: response.data.success,
            resultMsg: resultMsg,
            data: response.data.data
          });

          callback(response.data)
        })
        .catch((data) => {
          console.error(data);
        });
    },

    /*
      資料情報の削除
    */
    delete: (contactId, params, callback) => {
      http.post(`finders/delete/${contactId}`, params)
        .then((response) => {
          let message = "削除に失敗しました。しばらくしてから再度行ってください";
          if(response.data.success) message = "削除が完了しました"

          callback(response.data, message)
        })
        .catch((data) => {
          console.error(data);
        });
    }

  }
}

export function getFinderList(shutterId){
  http.post(`finders/list/${shutterId}`)
    .then((response) => {
      store.dispatch( {
        type: 'FINDER_LIST',
        shutterId: shutterId,
        list: response.data
      });
    })
    .catch((data) => {
      console.error(data);
    });
}
