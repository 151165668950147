import BaseDevice from 'devices/baseDevice'

const IO_MAP = {
  position: [0, 1, 2, 4],
  driveMode: 1,
  ecoMode: 1,
  sensor: [0, 1]
};

const MODE_MAP = {
  auto: 0,
  eco: 1
}

const DRIVE_STATUS = {
  0: {4: "open",  6: "close"},
  1: {7: "stop"},
  2: {0: "opening"},
  4: {0: "closing"},
}

const DRIVE_SHUTTER_DINO = 5;
const RESOLUTION = 8;

class BbController extends BaseDevice{
  getDriveShutterDiNo() {
    return DRIVE_SHUTTER_DINO;
  }

  parseRawData(rData) {
    return super.parseRawData(rData, IO_MAP, MODE_MAP, DRIVE_STATUS, RESOLUTION);
  }

}


export default BbController;
