import axios from 'axios';
import * as cookies from 'modules/cookies.js';

//// production
const ORIGIN   = "https://monban-iot.komatsuelec.co.jp";
// pre_stage
//const ORIGIN   = "https://namuchi-dev.uzyyn647ae.ap-northeast-1.elasticbeanstalk.com";
// development
//const ORIGIN   = "http://localhost:4553";

const BASE_URL = `${ORIGIN}/namuchi/api/`;

//通常時用
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || BASE_URL,
  headers: {
    Accept: "application/json",
    ContentType: "application/json"
  }
});

//バイナリデータの通信時用
const instanceImage = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || BASE_URL,
  responseType: 'blob',
  headers: {
    Accept: "application/json",
    ContentType: "application/json"
  }
});

/*
  ヘッダー情報設定
*/
function onRequestHeader(config) {
  if (cookies.verifyCredentials) {
    config.headers.Authorization = `Bearer ${cookies.get("accessToken")}`;
    config.headers.common["user-account"] = cookies.get("account");
  }
  return config;
}

/*
  通信成功時の共通項目
*/
function onResponseSuccess(response) {
  if(response.data.result === "failed"){
    console.log("Error::Unauthorized");
    cookies.clearAuthData();
    return Promise.reject(response);
  }else{
    return response;
  }
}

/*
  通信失敗時の共通項目
  -資格認証失敗(401)が起こったら、ログインページに戻す
*/
function onResponseError(error) {
  if(error.response.status === 401){
    console.log("Error::Unauthorized");
    cookies.clearAuthData();
  }
  return Promise.reject(error);
}

instance.interceptors.request.use(onRequestHeader);
instance.interceptors.response.use(onResponseSuccess, onResponseError);

instanceImage.interceptors.request.use(onRequestHeader);
instanceImage.interceptors.response.use(onResponseSuccess, onResponseError);

export { instance, instanceImage };
