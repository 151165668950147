const initialState = {
  loading: true,
  shutterId: undefined,
  name: "-",
  productNo: "-",
  imsi: "",
  monbanType: "-",
  deviceType: "-",
  width: "-",
  height: "-",
  constructionDate: "-",
  gettingAiDateTime: null,
  aiItems:[],
  aiData: null,
  parts:[],
  totalDriveCount: "-",
  driveCount: "-",
  driveTime: "-",
  driveMode: "-",
  driveStatus: "-",
  driveStatusKey: "",
  ecoMode: "-",
  errorCount: -1,
  errors:[],
  errorContents:{},
  hasPicture: false,
  remoteControllable: false,
  ecoModeFlg: undefined,
  autoModeFlg: undefined,
  execAlertStop: false,
  execDriveMode: false,
  execEcoMode: false,
  execShutterControl: false,
  showErrorModal: false,
  enableAiReload: false,
  enableDiReload: false,
  settings: [],
  inputStatus:[]
}

export default function(state = initialState, action) {
  switch(action.type) {
    case 'LOAD_SHUTTER_DATA':
      return {
        ...state,
        loading: true,
        shutterId: undefined,
        name: "-",
        productNo: "-",
        imsi: "",
        monbanType: "-",
        deviceType: "-",
        width: "-",
        height: "-",
        aiItems:[],
        aiData: null,
        parts:[],
        settings: [],
        hasPicture: false,
        inputStatus:[]
      };
    case 'INIT_IO_STATUS':
      return {
        ...state,
        loading: false,
        enableAiReload: true,
        enableDiReload: true,
        shutterId: action.shutterId,
        name: action.name,
        productNo: action.productNo,
        imsi: action.imsi,
        monbanType: action.monbanType,
        deviceType: action.deviceType,
        width: action.width,
        height: action.height,
        constructionDate: action.constructionDate,
        aiItems: action.aiItems,
        parts: action.parts,
        totalDriveCount: action.totalDriveCount,
        driveCount: action.driveCount,
        driveTime: action.driveTime,
        driveMode: action.driveMode,
        driveStatus: action.driveStatus,
        driveStatusKey: action.driveStatusKey,
        ecoMode: action.ecoMode,
        ecoModeFlg: action.ecoModeFlg,
        autoModeFlg: action.autoModeFlg,
        hasPicture: action.hasPicture,
        remoteControllable: action.remoteControllable,
        errorCount: action.errorCount,
        errors: action.errors,
        inputStatus: action.inputStatus
      };
    case 'RECV_SHUTTER_STATUS':
      if(state.shutterId !== action.shutterId){ return state; }
      
      const ary = [];
      state.inputStatus.forEach(hash => {
        let addr = hash["addr"];
        hash["status"] = action.sensorInfo[addr] ? true : false;
        ary.push(hash);
      });

      return {
        ...state,
        enableAiReload: true,
        enableDiReload: true,
        totalDriveCount: action.totalDriveCount,
        driveCount: action.driveCount,
        driveTime: action.driveTime,
        driveMode: action.driveMode,
        driveStatus: action.driveStatus,
        driveStatusKey: action.driveStatusKey,
        ecoMode: action.ecoMode,
        ecoModeFlg: action.ecoModeFlg,
        autoModeFlg: action.autoModeFlg,
        execAlertStop: false,
        execDriveMode: false,
        execEcoMode: false,
        execShutterControl: false,
        inputStatus: ary,
        errorCount: action.errorCount,
        errors: action.errors
      };
    case 'COUNTUP_DRIVETIME':
      return {
        ...state,
        driveTime: action.driveTime
      };
    case 'EXEC_ALERT_STOP':
      return {
        ...state,
        execAlertStop: action.value
      };
    case 'EXEC_ECO_MODE':
      return {
        ...state,
        execEcoMode: action.value,
        ecoModeFlg: action.checked
      };
    case 'EXEC_DRIVE_MODE':
      return {
        ...state,
        execDriveMode: action.value,
        autoModeFlg: action.checked
      };
    case 'EXEC_SHUTTER_CONTROL':
      return {
        ...state,
        execShutterControl: action.value
      };
    case 'ERROR_CONTENTS':
      return {
        ...state,
        errorContents: action.errorContents
      };
    case 'ERROR_MODAL':
      return {
        ...state,
        showErrorModal: action.showErrorModal
      };
    case 'SHUTTER_GET_AI_TREND':
      return {
        ...state,
        enableAiReload: false,
        aiData: action.aiData,
        gettingAiDateTime: new Date()
      };
    case 'DISABLE_DI_RELOAD':
      return {
        ...state,
        enableDiReload: false
      };
    default:
      return state;
  }
}
