import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/mainMapAction';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import NoImage from 'images/noimage.png'
import * as module from 'modules/utils';
import { scrollShutterList } from 'modules/moduleMainMap';

class ModalShutterInfo extends Component {
  constructor(props) {
    super(props);

    this.shutterId = this.props.main_map.shutterId;
    this.todayYear = Number(module.getTodayString("yyyy"));
    this.state = {
      selectYear: 0,
      selectMonth: 0,
      uploadFileData: null,
      uploadPhotoData: null,
      formAlert: "",
      loading: false,
      resultMsg: "",
      resultCss: "text-danger",
      deleteMode: false,
      execDeleted: false
    };
  }

  render(){
    const data = this.props.main_map.modalData;
    const showModal = this.props.showModal;
    const body = this.state.deleteMode ? this.formDeleteBody(data) : this.formEditBody(data)
    let title = (this.shutterId <= 0) ? "新規作成" : "編集";
    if(this.state.deleteMode) title = "削除"

    return(
      <Modal show={showModal} onHide={() => this.props.clearModal()}
             aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton onClick={() => this.props.clearModal()}
                       className="main-map-modal-header">
          <Modal.Title className="main-map-modal-title">{title}：設備情報</Modal.Title>
        </Modal.Header>

        <Form onSubmit={(e) => {this.handleSubmit(e);}}>
          <Modal.Body className="main-map-modal-body">
            {this.formPhotoData(data)}
            {body}
          </Modal.Body>

          {this.formFooter()}
        </Form>
      </Modal>
    )
  }

  formDeleteBody(data){
    return(
      <div className="del-body">
        <div>「{data.name}」の設備登録を削除します。</div>
        <div>削除してもよろしいですか？</div>
      </div>
    );
  }

  formEditBody(data){
    const readOnly = (data.device_type < 90)

    // 発表用
    let productType = data.product_type;
    if(productType === "RW01")  productType = "-";

    return(
      <>
        <Form.Group as={Row} noGutters={true} controlId="formName" className="mb-2">
          <Form.Label column sm="3">設備名</Form.Label>
          <Col sm="8">
            <Form.Control type="text" className={this.state.formAlert} defaultValue={data.name}/>
          </Col>
        </Form.Group>

        <Row noGutters={true}>
          <Col sm="3" className="col-form-label">サイズ</Col>
          <Col xs="3">
            <Form.Group controlId="formWidth" className="mb-2">
              <Form.Control type="number" defaultValue={data.width}
                            readOnly={readOnly}/>
            </Form.Group>
          </Col>
          <Col xs="1" className="col-form-label modal-item-size-x">x</Col>
          <Col xs="3">
            <Form.Group controlId="formHeight" className="mb-2">
              <Form.Control type="number" defaultValue={data.height}
                            readOnly={readOnly}/>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group as={Row} noGutters={true} controlId="formProductNo" className="mb-2">
          <Form.Label column sm="3">製造番号</Form.Label>
          <Col sm="8">
            <Form.Control type="text" defaultValue={data.product_no}
                          readOnly={readOnly}/>
          </Col>
        </Form.Group>

        <Form.Group as={Row} noGutters={true} controlId="formProductType" className="mb-2">
          <Form.Label column sm="3">型式</Form.Label>
          <Col sm="8">
            <Form.Control type="text" defaultValue={productType}
                          readOnly={readOnly}/>
          </Col>
        </Form.Group>

        {this.formConstructionDate(data)}

        <Form.Group as={Row} noGutters={true} controlId="formDriveCount" className="mb-2">
          <Form.Label column sm="3">動作回数</Form.Label>
          <Col sm="8">
            <Form.Control type="number" defaultValue={data.total_drive_count}
                          readOnly={readOnly}/>
          </Col>
        </Form.Group>

        <Form.Group as={Row} noGutters={true} controlId="formAgency" className="mb-2">
          <Form.Label column sm="3">代理店</Form.Label>
          <Col sm="8">
            <Form.Control type="text" defaultValue={data.agency}
                          readOnly={readOnly}/>
          </Col>
        </Form.Group>

        <Form.Group as={Row} noGutters={true} controlId="formMaker" className="mb-2">
          <Form.Label column sm="3">メーカー</Form.Label>
          <Col sm="8">
            <Form.Control type="text" defaultValue={data.maker}
                          readOnly={readOnly} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} noGutters={true} controlId="formNote" className="mb-2">
          <Form.Label column sm="3">備考</Form.Label>
          <Col sm="8">
            <Form.Control type="text" defaultValue={data.note} />
          </Col>
        </Form.Group>

        {this.formChangeMapModeBtn(data)}
      </>
    )
  }

  formPhotoData(data){
    let css = "photo-data-select"
    const photoData = this.props.main_map.modalPhotoData

    let url;
    if(this.state.uploadPhotoData !== null){
      url = this.state.uploadPhotoData;
    }else if(photoData !== undefined){
      url = (window.URL || window.webkitURL).createObjectURL(photoData);
    }else{
      css = ""
      url = NoImage;
    }

    return(
      <Row noGutters={true}>
        <Col></Col>
        <Form.Group as={Col} xs="auto" className="main-map-photo-group mb-2"
                    controlId="formFile">
          <Form.Label>
            <div className="main-map-photo-layout " title="設備画像を選択">
              <img className={`main-map-photo-data ${css}`} src={url} alt="PhotoData"/>
            </div>
            <Form.Control type="file" className="display-none"
                          accept="image/*" onChange={(e) => this.selectPhotoData(e)}/>
          </Form.Label>
        </Form.Group>
        <Col></Col>
      </Row>
    )
  }

  selectPhotoData(e){
    if(e.target.files.length <= 0) return;

    const file = e.target.files[0]
    const reader = new FileReader()

    reader.onload = (e) => {
      this.setState({ uploadFileData: file, uploadPhotoData: e.target.result})
    };
    reader.readAsDataURL(file)
  }

  formConstructionDate(data){
    const readOnly = (data.device_type < 90)
    const props = this.props.main_map;
    const years  = [];
    const months = [];

    years.push(<option key={0} value={0}>-</option>);
    for (let i = this.todayYear; i >= 1900; i--) {
      years.push(<option key={i} value={i}>{i}</option>);
    }

    for (let i = 0; i <= 12; i++) {
      let dispValue = i;
      if(i === 0) dispValue = "-";
      months.push(<option key={i} value={i}>{dispValue}</option>);
    }

    return(
      <Row noGutters={true}>
        <Col sm="3" className="col-form-label">設置年月</Col>
        <Col xs="3">
          <Form.Group controlId="formYear" className="mb-2">
            <Form.Control as="select" value={props.modalSelectYear}
              className="form-control" readOnly={readOnly}
              onChange={e => {this.props.selectSpecYear(e.target.value)}}>
              {years}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs="1" className="col-form-label modal-item-size-x">年</Col>
        <Col xs="3">
          <Form.Group controlId="formMonth" className="mb-2">
            <Form.Control as="select" value={props.modalSelectMonth}
              className="form-control" readOnly={readOnly}
              onChange={e => {this.props.selectSpecMonth(e.target.value)}}>
              {months}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs="1" className="col-form-label modal-item-size-x">月</Col>
      </Row>
    )
  }

  /*
    MAP位置設定モード切り替えボタン
  */
  formChangeMapModeBtn(data){
    // 新規作成の場合は表示しない
    if(this.shutterId <= 0) return null;

    return(
      <Row noGutters={true} className="mb-0">
        <Col sm="3"></Col>
        <Col sm="8">
          <Button variant="primary" className="btn-ch-pos"
                onClick={() => this.onClickChangeMapMode()}>
            MAP位置変更
          </Button>
        </Col>
      </Row>
    )
  }

  formFooter(data){
    let delBtn = null;
    let form = null;

    if(this.state.loading){
      let msg = (this.shutterId <= 0) ? "新規作成" : "更新";
      if(this.state.deleteMode) msg = "登録情報を削除"

      form = (
        <div className="loading-text d-flex align-items-center loading">
          <div className="spinner-border text-success" role="status"></div>
          <div className="loading-title">{msg}しています。しばらくお待ちください...</div>
        </div>
      )
    }else{
      if(this.state.deleteMode){
        if(!this.state.execDeleted){
          delBtn = (
            <Button variant="danger" onClick={() => this.onClickDelete()}>
              削除
            </Button>);
        }

        form = (
          <>
            <div className={this.state.resultCss}>{this.state.resultMsg}</div>

            {delBtn}

            <Button variant="secondary" onClick={() => this.props.clearModal()}>
              閉じる
            </Button>
          </>
        )

      }else{
        if(this.props.main_map.modalData.device_type >= 90){
          delBtn = (
            <Button variant="outline-danger" className="mr-auto"
                  onClick={() => this.onClickShowDeleteModal(true)}>
              削除
            </Button>);
        }

        form = (
          <>
            {delBtn}

            <div className={this.state.resultCss}>{this.state.resultMsg}</div>

            <Button variant="success" type="submit">
              登録
            </Button>
            <Button variant="secondary" onClick={() => this.props.clearModal()}>
              閉じる
            </Button>
          </>
        )
      }
    }

    return(
      <Modal.Footer className="main-map-modal-footer no-gutters">
        {form}
      </Modal.Footer>
    );
  }

  onClickChangeMapMode() {
    this.props.changeMapPositionMode(true);
    this.props.clearModal();
    this.props.showShutterPopover(this.shutterId, true);
  }

  onClickShowDeleteModal(bool){
    this.setState({ execDeleted: false, deleteMode: bool, resultMsg: ""});
  }

  /*
  シャッター情報の削除を実行
  */
  onClickDelete(){
    this.setState({loading: true});

    const callback = (success) => {
      let message = "削除に失敗しました。しばらくしてから再度実行してください";
      let css = "text-danger";

      if(success){
        message = "設備情報を削除しました。";
        css = "text-success";
      }

      this.setState({
        loading: false, resultCss: css, resultMsg: message,
        execDeleted: success
      });
    }

    this.props.deleteShutter(this.shutterId, callback);
  }

  handleSubmit(e) {
    // 再リロード防止
    e.preventDefault();
    const name = e.target.formName.value.trim();
    const width  = e.target.formWidth.value.trim();
    const height  = e.target.formHeight.value.trim();
    const productNo  = e.target.formProductNo.value.trim();
    const poductType  = e.target.formProductType.value.trim();
    const year  = e.target.formYear.value.trim();
    const month  = e.target.formMonth.value.trim();
    const driveCount  = e.target.formDriveCount.value.trim();
    const agency  = e.target.formAgency.value.trim();
    const maker  = e.target.formMaker.value.trim();
    const note  = e.target.formNote.value.trim();
    const photoData = this.state.uploadFileData;

    if(name === ""){
      alert("設備名を記入してください");
      this.setState({formAlert: "form-alert"});
      return;
    }

    let constructionDate = ""
    if(year !== "" && year !== "0"){
      constructionDate = year

      if(month !== "" && month !== "0"){
        constructionDate += module.zeroPadding(month, 2)
      }
    }

    this.setState({loading: true, resultMsg: ""});

    const params = new FormData();
    params.append('name', name);
    params.append('note', note);

    if(this.shutterId <= 0 || this.props.main_map.modalData.device_type >= 90){
      params.append('width', width);
      params.append('height', height);
      params.append('product_no', productNo);
      params.append('product_type', poductType);
      params.append('construction_date', constructionDate);
      params.append('drive_count', driveCount);
      params.append('agency', agency);
      params.append('maker', maker);
    }

    if(photoData !== null){
      params.append('file', photoData);
    }

    const callback = (success, id) => {
      let message = "保存に失敗しました。内容を確認してください";
      let css = "text-danger";
      if(success){
        message = this.shutterId < 0 ? "新規作成しました。" : "内容を更新しました。";
        css = "text-success";
      }

      this.shutterId = id;
      this.setState({
        loading: false, resultCss: css, resultMsg: message,
        uploadFileData: null, uploadPhotoData: null
      });

      if(id > 0) scrollShutterList(id);
    }

    this.props.saveShutterSpec(this.shutterId, params, callback);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalShutterInfo);
