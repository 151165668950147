import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/settingAction';

class ModalSetting extends Component {
  render() {
    const props = this.props.setting;
    const data = props.dataList.find(
      item => item.addr === props.targetAddress
    )

    if(data === undefined){ return null; }

    return (
      <Modal show={props.showModal}
             onHide={() => this.onClickHideModal()}
             animation={true}
             aria-labelledby="contained-modal-title-vcenter"
             centered>
        <Modal.Header closeButton onClick={() => this.onClickHideModal()}>
          <Modal.Title>{`${data.cd} ${data.name}`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.modalBody(data)}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="success" onClick={() => this.onClickExecSetting(data.addr)}>
            設定
          </Button>
          <Button variant="secondary" onClick={() => this.onClickHideModal()}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  modalBody(data){
    let form;
    const shutterId = this.props.shutterId;
    const dataKey = `${shutterId}-${data.addr}`

    if(data.select_list.length){
      //設定値ーリスト選択フォーム
      form = (
        <form className="list-group" id={`settings-value-${dataKey}`}>
        {
          data.select_list.map((item, i) => {
            const value = i * data.step_count + data.min;
            let selected = "";
            let checked = false;
            let selectItem = this.props.setting.selectItem;
            if(selectItem === undefined) {selectItem = data.value}

            if(value === selectItem){
              selected = "setting-item-selected";
              checked = true;
            }

            return (
              <div key={`setting-item-${value}`}
                   className={`list-group-item list-group-item-action setting-list-item ${selected}`}
                   onClick={() => this.onClickSelectItem(value)}>
                <input type="radio" name="settings" style={{display: 'none'}}
                value={value} checked={checked}
                onChange={() => {}}/>
                {item}
              </div>
            );
          })
        }
          <input type="hidden"  id={`data-type-${dataKey}`} value="radioForm" />
        </form>
      );
    }else{
      // 設定値ー入力フォーム
      const value = this.convertValue(data.value, data.decimal_point);
      const min = this.convertValue(data.min, data.decimal_point);
      const max = this.convertValue(data.max, data.decimal_point);
      const step = this.stepCount(data.decimal_point);

      form = (
        <div>
          <div className="d-flex align-items-center">
            <input type="number" name="settings" id={`settings-value-${dataKey}`}
              style={{width:"230px"}}
              className="form-control"
              defaultValue={value}
              step={step}
              min={min} max={max}
              placeholder="必ず入力してください"/>
            <div style={{marginLeft:"10px"}}>{ data.unit }</div>
          </div>
          <div style={{padding: "6px"}}>
            設定範囲：{ min } 〜 { max } { data.unit }
          </div>
          <input type="hidden"  id={`data-type-${dataKey}`} value="inputForm" />
        </div>
      );
    }
    return form;
  }

  convertValue(rawValue, dicimal){
    const value = rawValue * this.stepCount(dicimal);
    return value.toFixed(dicimal);
  }

  stepCount(dicimal){
    return Math.pow(10, (-1 * dicimal));
  }

  onClickHideModal(){
    this.props.showModalSetting(0, false);
  }

  onClickSelectItem(value){
    this.props.selectDataItem(value);
  }

  onClickExecSetting(address){
    const shutterId = this.props.shutterId;
    const dataKey = `${shutterId}-${address}`;
    const typeElement = document.getElementById(`data-type-${dataKey}`);
    const valElement = document.getElementById(`settings-value-${dataKey}`);
    let setValue = undefined;

    if(typeElement.value === "inputForm"){
      setValue = valElement.value ;
    }else{
      const radioNodeList = valElement.settings;
      setValue = radioNodeList.value;
    }

    this.props.execSettingData(shutterId, address, setValue);
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ModalSetting);
