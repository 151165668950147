import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/inspectionAction';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import * as utils from 'modules/utils';
import Contents from './Contents';
import ModalNotice from 'components/common/ModalNotice';

class InspectionEdit extends Component {
  constructor(props) {
    super(props);
    this.shutterId = this.props.match.params.shutterId;
    this.inspectionId = this.props.match.params.inspectionId;
    this.maxFileCount = 2
    this.state = {
      info: {},
      items: [],
      status: {},
      charColor: {}, // 点検詳細のItemNoは1000番以降とする
      detailCount: 1,
      photoData: {},
      deleteFlg: {},
      delete: false,
      loadingMsg: "",
      loading: false,
      resultMsg: "",
      formDateAlert: "",
      showModal: false,
      modalData: {}
    };
  }

  componentDidMount() {
    const callback = (data) => {
      this.initReport(data.contents, data.details)

      this.setState({
        info: {
          inspectionDate: utils.formatDateTime(data.date,"yyyy-mm-dd"),
          createdUser: data.created_user,
          company: data.company_name,
          customerName: data.user_name,
          productNo: data.product_no,
          productType: data.product_type,
          size: utils.shutterSize(data),
          productionDate: utils.formatDateTime(data.production_date,"yyyy年mm月"),
          controllerVer: data.controller_ver,
          count: data.count,
        },
        items: data.contents,
        details: data.details
      })
    };

    if(this.props.inspection.newCreated){
      this.setState({ resultCss: "result-message", resultMsg:"新規に作成しました。"})
    }
    this.props.getInspectionDetail(this.inspectionId, callback);
  }

  render() {
    return(
      <Form className="card-body-common" onSubmit={(e) => {this.handleSubmit(e);}}>
        <Row noGutters={true} className="content-header inspection-content-header">
          <Col xs="auto">
            <Link to={`/inspection/list/${this.shutterId}`}
                  className="btn btn-common btn-outline-success">
              点検一覧
            </Link>
          </Col>
          <Col></Col>
          <Col xs="auto" className={`d-flex align-items-end ${this.state.resultCss}`}>
            { this.state.resultMsg }
          </Col>

          { this.formButtons() }
        </Row>

        <Contents shutterId={this.shutterId}
                  fileCount={this.maxFileCount}
                  state={this.state}
                  updateState={this.updateState.bind(this)}/>

        {this.formModalNotice()}
      </Form>
    );
  }

  formButtons(){
    if(this.state.items.length === 0) return null;
    if(this.state.delete){ return null; }

    let form = (
      <>
        <Col xs="auto">
          <Button variant="success" type="submit" className="btn-common">
            登録
          </Button>
        </Col>
        <Col xs="auto" style={{marginLeft: "8px"}}>
          <Button variant="success" className="btn-common"
                  onClick={() => this.onClickDownload()}>
            ダウンロード
          </Button>
        </Col>
        <Col xs="auto" style={{marginLeft: "20px"}}>
          <Button variant="danger" className="btn-common"
                  onClick={() => this.onClickShowDeleteModal()}>
            削除
          </Button>
        </Col>
      </>
    )

    if(this.state.loading){
      form = (
        <div className="loading-text d-flex align-items-center loading">
          <div className="spinner-border text-success" role="status"></div>
          <div className="loading-title">{this.state.loadingMsg}</div>
        </div>
      )
    }
    return form;
  }

  handleSubmit(e) {
    // 再リロード防止
    e.preventDefault();

    const date     = e.target.date.value.trim().replace(/-/g, '');
    const company  = e.target.company.value.trim();
    const customerName  = e.target.customerName.value.trim();
    const version  = e.target.controllerVer.value.trim();
    const count    = e.target.count.value.trim();
    const photoData = this.state.photoData;

    if(date.length < 8){
      alert("日付を記入してください");
      return;
    }

    this.setState({loading: true, resultMsg: "",
                   loadingMsg: "更新しています。しばらくお待ちください..."});

    const params = new FormData();
    params.append('shutter_id', this.shutterId);
    params.append('date', date);
    params.append('company', company);
    params.append('customer_name', customerName);
    params.append('version', version);
    params.append('count', count);

    this.state.items.forEach(data =>{
      const itemNo = data.item_no
      const status = this.state.status[itemNo] || ""
      const color = this.state.charColor[itemNo] || ""
      const memo  = e.target[`memo_${data.item_no}`].value.trim()
      params.append(`items[][item_no]`, itemNo);
      params.append(`items[][status]`, status);
      params.append(`items[][color]`, color);
      params.append(`items[][memo]`, memo);
    });

    for (let i = 1; i <= this.state.detailCount; i++) {
      const itemCharNo = 1000 + i
      const color = this.state.charColor[itemCharNo] || "";
      const title = e.target[`detail_title_${i}`].value.trim()
      const memo  = e.target[`detail_memo_${i}`].value.trim()
      const photoInfo = photoData[i]
      params.append(`details[][item_no]`, i);
      params.append(`details[][title]`, title);
      params.append(`details[][memo]`, memo);
      params.append(`details[][color]`, color);

      for (let n = 1; n <= this.maxFileCount; n++) {
        const imgTitle = e.target[`detail_imgtitle${n}_${i}`].value.trim()
        let deleteFlg = 0;
        if(this.state.deleteFlg[`${n}_${i}`]){
          deleteFlg = this.state.deleteFlg[`${n}_${i}`]
        }

        let fileData = null;
        if(photoInfo && photoInfo[n]){
          fileData = photoInfo[n]["file"]
        }

        if(imgTitle === "" && fileData === null && deleteFlg === 0) continue;

        params.append(`details[][files[][file_no]]`, n);
        params.append(`details[][files[][title]]`, imgTitle);
        params.append(`details[][files[][delete_file_flg]]`, deleteFlg);

        if(fileData){
          params.append(`details[][files[][file]]`, fileData);
        }
      }
    }

    const callback = (data) => {
      let message = "保存に失敗しました。設定内容を確認してください";
      let css = "failure-message";
      if(data.success){
        message = "内容を更新しました。";
        css = "result-message";
      }

      this.setState({
        loading: false, resultCss: css, resultMsg: message
      });
    }

    this.props.save(this.inspectionId, params, callback);
  }

  initReport(contents, details){
    const status = this.state.status;
    const charColor = this.state.charColor;

    contents.forEach(data =>{
      status[data.item_no] = data.check_status;
      charColor[data.item_no] = data.text_color;
    });

    for (let i = 1; i <= details.length; i++) {
      const index = i - 1;
      const data = details[index];
      const itemCharNo = 1000 + i;
      charColor[itemCharNo] = data.text_color;
    }

    const detailCount = (details.length === 0) ? 1 : details.length

    this.setState({
      status: status, charColor: charColor, detailCount: detailCount
    })
  }

  updateState(state){
    this.setState(state);
  }

  onClickDownload(){
    const dateTag    = document.getElementById(`date`);
    const valDate = dateTag ? dateTag.value.trim().replace(/-/g, '') : null;
    const date    = utils.formatDateTime(valDate,"_yyyy年mm月dd日")
    const sMap = this.props.common.shuttersMap[this.shutterId];
    let shutterName = ""
    if(sMap !== undefined) shutterName = `_${sMap.name}`;

    const fileName = `シートシャッター点検報告書${shutterName}${date}.xlsx`

    this.setState({
      loading: true, resultMsg: "",
      loadingMsg: "ダウンロードしています。しばらくお待ちください..."
    });

    const callback = (success) => {
      const msg = success ? "" : "ダウンロードに失敗しました。";

      this.setState({
        loading: false, resultCss: "failure-message", resultMsg: msg
      });
    }
    this.props.download(this.inspectionId, this.shutterId, fileName, callback);
  }

  formModalNotice(){
    const data = this.state.modalData;

    return(
      <ModalNotice showModal={this.state.showModal}
                   title={data.title}
                   bodyData={data.bodyData}
                   disableBtnDone={data.disableBtnDone}
                   btnDoneName={data.btnDoneName}
                   btnDoneColor={data.btnDoneColor}
                   size={data.size}
                   done={data.doneAction}
                   cancel={() => {this.onClickHideModal()}}/>
    )
  }

  onClickShowDeleteModal(){
    const bodyData = (
      <>
        <div>点検データを削除します。</div>
        <div>削除してもよろしいですか？</div>
      </>
    )
    const callback = () => {
      this.delete()
    }
    const data = {
      bodyData: bodyData, btnDoneName: "削除",
      btnDoneColor: "danger", doneAction: callback
    }

    this.setState({ showModal: true, modalData: data});
  }

  delete(){
    this.setState({
      showModal: false, loading: true, resultMsg: "",
      loadingMsg: "削除しています。しばらくお待ちください..."
    });

    const callback = (data) => {
      let message = "削除に失敗しました。しばらくしてから再度行ってください";
      let css = "failure-message";
      if(data.success) {
        message = "削除が完了しました"
        css = "result-message";
      }

      this.setState({
        loading: false, delete: data.success,
        resultCss: css, resultMsg: message
      });
    }

    this.props.delete(this.inspectionId, this.shutterId, callback);
  }

  onClickHideModal(){
    this.setState({ showModal: false });
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionEdit);
