const initialState = {
  list: [],
  enableAiTrend: {},
  enableReload: {},
  aiDateTime: {},
  diDateTime: {},
  aiData:{},
  diData:{},
  piData:{},
  sensorData:{}
}

export default function(state = initialState, action) {
  switch(action.type) {
    case 'DISPLAY_INFO_LIST':
      return {
        ...state,
        list: action.list,
        enableReload: action.enableReload,
      };
    case 'TOP_GET_AI_TREND':
      state.aiData[action.id] = action.aiData
      state.aiDateTime[action.id] = new Date()

      return {...state,
        aiData: state.aiData, aiDateTime: state.aiDateTime
      };
    case 'TOP_GET_DI_TREND':
      if(state.diData[action.id] === undefined){ state.diData[action.id] = {} }
      if(state.diData[action.id][action.dino] === undefined){
        state.diData[action.id][action.dino] = {}
      }
      state.diData[action.id][action.dino] = action.diData
      state.diDateTime[action.id] = new Date()

      return {...state,
        diData: state.diData, diDateTime: state.diDateTime};
    case 'TOP_GET_SENSOR_DATA':
      state.sensorData[action.id] = action.data
      return {...state, sensorData: state.sensorData};
    case 'DISABLE_RELOAD':
    case 'DISABLE_DI_RELOAD':
      state.enableReload[action.imsi] = false
      return {...state, enableReload: state.enableReload};
    case 'RECV_TOP_IO_STATUS':
      state.enableAiTrend[action.id] = false
      return {
        ...state,
        enableAiTrend: state.enableAiTrend
      };
    default:
      return state;
  }
}
