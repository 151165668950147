import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/authAction';

class Footer extends Component {
  render() {
    if(!this.props.auth.logined){
      this.d = new Date();

      return (
        <footer className="fixed-bottom footer small">
          Copyright© 1985-{this.d.getFullYear()} Komatsuelectric Industry Co.,Ltd.
        </footer>
      );
    }else{
      return null;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
