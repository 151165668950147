import { store } from 'modules/store.js';
import { download } from 'modules/utils';
import { instance as http, instanceImage as http2 } from 'modules/httpConnection'
import { clearShutterPopover } from 'modules/actions/mainMapAction';

export function mapStateToProps(state) {
  return state;
}

export function mapDispatchToProps(dispatch) {
  return {
    /*
      平面図上のすべてのバルーンを非表示
    */
    clearShutterPopover: () => {
      clearShutterPopover();
    },

    /*
      メンテナンス情報リストを取得
    */
    getMaintenanceList: (shutterId) => {
      getMaintenanceList(shutterId);
    },

    /*
      メンテナンス情報リストを取得
    */
    getMaintenanceDetail: (maintenanceId, shutterId, callback) => {
      const params = new URLSearchParams();
      params.append('shutter_id', shutterId);

      http.post(`maintenances/detail/${maintenanceId}`, params)
        .then((response) => {
          store.dispatch( {
            type: 'MAINTENANCE_DETAIL',
            data: response.data
          });

          callback(response.data);

          response.data.files.forEach(data => {
            if(data.file_type === "image"){
              getMaintenanceImage(maintenanceId, data.file_id, "DATA");
            }
          });
        })
        .catch((data) => {
          console.error(data);
        });
    },

    /*
      メンテナンス情報の保存
    */
    save: (maintenanceId, params, callback) => {
      http.post(`maintenances/save/${maintenanceId}`, params)
        .then((response) => {
          let resultMsg = "保存しました"
          if(maintenanceId <= 0) resultMsg = "新規に登録しました"

          store.dispatch( {
            type: 'MAINTENANCE_SAVE',
            maintenanceId: Number(maintenanceId),
            saveResult: response.data.success,
            resultMsg: resultMsg,
            data: response.data.data
          });

          callback(response.data)

          response.data.data.files.forEach(data => {
            if(data.file_type === "image"){
              getMaintenanceImage(response.data.data.maintenance_id,
                                  data.file_id, "DATA", {resize: "large"});
            }
          });
        })
        .catch(data => {
          console.error(data);
        });
    },

    /*
      メンテナンスファイルのダウンロード
    */
    download: (maintenanceId, fileId, fileName) => {
      http2.post(`maintenances/download/${maintenanceId}/${fileId}`)
        .then((response) => {
          download(response.data, fileName);
        })
        .catch((data) => {
          console.error(data);
        });
    },

    /*
      メンテナンス情報の削除
    */
    delete: (maintenanceId, shutterId, callback) => {
      const params = new URLSearchParams();
      params.append('shutter_id', shutterId);

      http.post(`maintenances/delete/${maintenanceId}`, params)
        .then((response) => {
          let message = "削除に失敗しました。しばらくしてから再度行ってください";
          if(response.data.success) message = "削除が完了しました"

          callback(response.data, message)
        })
        .catch((data) => {
          console.error(data);
        });
    }

  }
}

export function getMaintenanceList(shutterId){
  store.dispatch( {type: 'CLEAR_MAINTENANCE_DATA'} );

  http.post(`maintenances/list/${shutterId}`)
    .then((response) => {
      store.dispatch( {
        type: 'MAINTENANCE_LIST',
        shutterId: shutterId,
        list: response.data
      });
    })
    .catch((data) => {
      console.error(data);
    });
}

function getMaintenanceImage(maintenanceId, fileId, type, params={}){
  const setParams = new URLSearchParams();
  if(params["resize"]){
    setParams.append('resize', params["resize"]);
  }

  http2.post(`maintenances/photo/${maintenanceId}/${fileId}`, setParams)
    .then(response => {
      if(response.data !== null){
        const blob = new Blob([ response.data ], { type: "image/*" });

        store.dispatch( {
          type: `MAINTENANCE_PHOTO_${type}`,
          maintenanceId: maintenanceId,
          fileId: Number(fileId),
          photoData: blob
        });
      }
    })
    .catch(data => {
      console.error(data);
    });
}
