import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/mainMapAction';

class ContentSensorIcon extends Component {
  constructor(props) {
    super(props);

    this.initFontSize = 12;
    this.offsetSize = 0.3
  }

  render(){
    const mainKey = `flowmap-sensor-${this.props.mainKey}`;
    const style = this.sensorStyle();

    return(
      <div className="flowmap-sensor-item" key={mainKey} id={mainKey}
           style={style}>
        <span className="flowmap-sensor-item-title">{this.props.dataName}</span>
        <span className="flowmap-sensor-item-data">{this.props.value}</span>
        <span className="flowmap-sensor-item-unit">{this.props.unit}</span>

        <input type="hidden" id={`${mainKey}-left`} key={`${mainKey}-left`}
               value={this.props.figx}/>
        <input type="hidden" id={`${mainKey}-top`} key={`${mainKey}-top`}
               value={this.props.figy}/>
      </div>
    );
  }

  sensorStyle(){
    const left = this.props.figx * this.props.bai + this.props.marginLeft;
    const top = this.props.figy * this.props.bai;
    const fontSize = this.initFontSize * this.props.bai + this.offsetSize;

    return {
      left: `${left}px`,
      top: `${top}px`,
      fontSize: `${fontSize}px`
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentSensorIcon);
