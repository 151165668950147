import BaseDevice from 'devices/baseDevice'

const IO_MAP = {
  position: [0, 4],
  driveMode: 5,
  ecoMode: 4,
  sensor: [0, 1, 2, 4, 5, 6, 11]
};

const MODE_MAP = {
  auto: 1,
  eco1: 2,
  eco2: 3
}

//コントローラーのIO入出力位置に元づいた開閉状態信号位置
//16bitグループの1番目の
const DRIVE_STATUS = {
  0:{1: "stop"},
  4:{1: "open", 2: "eco", 3: "eco", 4: "close", 8: "opening", 9:"closing"},
}

const DRIVE_SHUTTER_DINO = 66;
const RESOLUTION = 16;
const OFFSET_GENERAL_DINO = 200;

class RwController extends BaseDevice{
  getDriveShutterDiNo() {
    return DRIVE_SHUTTER_DINO;
  }

  parseRawData(rData) {
    return super.parseRawData(rData, IO_MAP, MODE_MAP,
                              DRIVE_STATUS, RESOLUTION, OFFSET_GENERAL_DINO);
  }

}

export default RwController;
