const initialState = {
  loading: false,
  shutterId: undefined,
  year: null,
  month: null,
  day: null,
  displayDate: "",
  reportType: "yearly",
  dataType: "drData",
  displyTime: 60,
  emptyData: false,
  editMode: false,
  saveResult: false,
  resultMsg: "",
  drData: [],
  aiData: [],
  piData: []
}

export default function(state = initialState, action) {
  switch(action.type) {
    case 'LOAD_REPORT_DATA':
      let displayDate = `${action.year}年`;
      let reportType = "yearly";

      if(action.month > 0) {
        displayDate += `${action.month}月`;
        reportType = "monthly";
      }
      if(action.day > 0) {
        displayDate += `${action.day}日`;
        reportType = "daily"
      }

      return {
        ...state,
        loading: true,
        shutterId: action.shutterId,
        year: action.year,
        month: action.month,
        day: action.day,
        displayDate: displayDate,
        reportType: reportType,
        emptyData: false,
        editMode: false,
      };
    case 'REPORT_DATA':
      let emptyData = true;
      if(action.drData.length > 0 || action.aiData.length > 0 || action.piData.length > 0) {
        emptyData = false;
      }

      if((state.dataType === "aiData" && action.aiData.length === 0) ||
         (state.dataType === "piData" && action.piData.length === 0)){
          state.dataType = "drData"
      }
      return {
        ...state,
        loading: false,
        editMode: false,
        emptyData: emptyData,
        dataType: state.dataType,
        drData: action.drData,
        aiData: action.aiData,
        piData: action.piData
      };
    case 'RESULT_SAVE_REPORT_DATA':
      return {
        ...state,
        loading: false,
        drData: action.drData
      };
    case 'CHANGE_REPORT_DATA_TYPE':
      return { ...state, dataType: action.dataType };
    case 'CHANGE_REPORT_AI_TIME':
      return { ...state, displyTime: action.displyTime };
    case 'CHANGE_REPORT_EDIT_MODE':
      return { ...state, editMode: action.mode };
    case 'CLEAR_REPORT_DATA':
      return {
        ...state,
        loading: false,
        shutterId: undefined,
        year: null,
        month: null,
        day: null,
        displayDate: "",
        reportType: "",
        dataType: "drData",
        displyTime: 60,
        emptyData: false,
        editMode: false,
        drData: [],
        aiData: [],
        piData: []
      };
    default:
      return state;
  }
}
