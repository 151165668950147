import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/authAction';
import { initWebSocket } from 'modules/monbanWebSocket'
import { Switch, Route, Redirect } from 'react-router-dom';
import HeaderFactory from './HeaderFactory';
import MenuList from './MenuList';
import Top from 'components/tops/Top';
import MainMap from 'components/main_maps/MainMap';
import Inspection from 'components/inspections/Inspection';
import * as cookies from 'modules/cookies.js';

class BaseContent extends Component {
  constructor(props) {
    super(props);

    this.ws = undefined;
    this.factoryId = cookies.get('factoryId');
    this.state = {
      layoutW: window.innerWidth
    };

    //メインバックグラウンドカラーの変更
    document.getElementById('main-body').style.backgroundColor = "#e0e0e0";

    window.addEventListener('resize', () => {
      this.setState({layoutW: window.innerWidth});
    });
  }

  componentDidMount() {
    const callback = () => {
      this.connectWebSocket()
    }

    this.props.getShutterWatchList(callback);
  }

  render() {
    const rowCss = this.state.layoutW >= 576 ? "row no-gutters" : ""
    const colCss1 = this.state.layoutW >= 576 ? "col-auto" : ""
    const colCss2 = this.state.layoutW >= 576 ? "col" : ""

    return (
      <div className={`fill-height ${rowCss}`}>
        <div className={`menu-content ${colCss1}`}>
          <MenuList layoutW={this.state.layoutW}/>
        </div>
        <div className={`main-content ${colCss2}`} id="main-content">
          <HeaderFactory factoryId={this.factoryId}/>

          <Switch>
            {/* Top画面 */}
            <Route path="/top" component={ Top }/>

            {/* その他マップあり画面 */}
            <Route exact path="/main_map" component={ MainMap }/>
            <Route exact path="/main_map/:factoryId" component={ MainMap }/>
            {/* シャッター情報画面 */}
            <Route path="/shutter" component={ MainMap }/>
            {/* 遠隔設定画面 */}
            <Route path="/setting" component={ MainMap }/>
            {/* メンテナンス機能 */}
            <Route path="/maintenance" component={ MainMap }/>
            {/* 資料機能 */}
            <Route path="/finder" component={ MainMap }/>
            {/* 連絡先機能 */}
            <Route path="/contact" component={ MainMap }/>
            {/* データ台帳機能 */}
            <Route path="/report" component={ MainMap }/>
            {/* 設備点検機能 */}
            <Route path="/inspection/(create|edit)" component={ Inspection }/>
            <Route path="/inspection" component={ MainMap }/>
            <Redirect to="/login"/>
          </Switch>
        </div>
      </div>
    );
  }

  connectWebSocket(){
    this.ws = initWebSocket("DeviceChannel");
    // 通信が接続された場合
    this.ws.onopen = this.onOpen.bind(this);
    // メッセージを受け取った場合
    this.ws.onmessage = this.onMessage.bind(this);
  }

  onOpen(e) {
    const watches = this.props.common.watches;

    watches.forEach(fItem => {
      fItem.shutters.forEach(sItem => {
        this.ws.subscribe(sItem.imsi);
      });
      fItem.sensors.forEach(sItem => {
        this.ws.subscribe(sItem.imsi);
      });
    });
  }

  onMessage(e) {
    const response = JSON.parse(e.data);
    const type_data = response.type;
    const identifier = response.identifier;
    const rcevData = response.message;

    if(type_data !== "ping" && identifier !== undefined && rcevData !== undefined){
      console.log(rcevData);
      this.props.receivedStatus(rcevData, this.props.location.pathname);
    }
  }

  /*
    Component終了時に呼ばれる
  */
  componentWillUnmount(){
    if(this.ws !== undefined) this.ws.close();
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BaseContent));
