import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/shutterAction';

class ModalErrorContents extends Component {
  hideModal(){
    this.props.showErrorModal(false);
  }

  render() {
    const props = this.props.shutter;

    return (
      <Modal show={props.showErrorModal}
             onHide={() => this.hideModal()}
             aria-labelledby="contained-modal-title-vcenter"
             centered>
        <Modal.Header closeButton onClick={() => this.hideModal()}>
          <Modal.Title>
            <h5 className="shutter-alert-text-color">
              {`${props.errorContents.cd} ${props.errorContents.name} `}
            </h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="shutter-alert-text-color">
            エラーの発生原因
          </div>
          <div className="shutter-alert-item-messege">
            {props.errorContents.cause}
          </div>

          <div className="shutter-alert-text-color">
            解決方法
          </div>
          <div className="shutter-alert-item-messege">
            {props.errorContents.solution}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.hideModal()}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalErrorContents);
