import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/reportAction';

class DailyPiData extends Component {
  render() {
    const piData = this.props.report.piData;

    return (
      <div className="content-report-data"
           style={{height:`${this.props.contentH}px`}}>
        <table className="table-sm rp-table">
          <tbody>
            <tr className="rp-header-record">
              <td className="rp-h-item-title">
                <div className="rp-h-cell"></div>
                <div className="rp-h-cell l-b-1"></div>
                <div className="rp-h-cell l-b-1">日計</div>
                <div className="rp-h-cell"></div>
                <div className="rp-h-cell l-b-1"></div>
              </td>

              { this.headerData(piData) }
            </tr>

            { this.hourlyData(piData) }
          </tbody>
        </table>
      </div>
    );
  }

  headerData(piData){
    return(
      piData.map(pi => {
        const unit  = pi.unit !== null ? pi.unit : "-";
        const dVal = pi.value !== null ? pi.value : "-";
        const max  = pi.max !== null ? pi.max : "-";
        const min  = pi.min !== null ? pi.min : "-";
        const ave  = pi.ave !== null ? pi.ave : "-";

        return(
          <td key={pi.item_no} className="rp-h-data">
            <div className="rp-h-cell rp-h-d-w-pi rp-h-d-title">{pi.name}</div>
            <div className="rp-h-cell rp-h-d-item l-b-1">{unit}</div>
            <div className="rp-h-cell rp-h-d-item">{dVal}</div>
            <Row noGutters={true} className="rp-h-cell rp-h-d-category">
              <Col xm={4} >最大</Col>
              <Col xm={4} >最小</Col>
              <Col xm={4} >平均</Col>
            </Row>

            <Row noGutters={true} className="rp-h-cell rp-h-d-val l-b-1">
              <Col xm={4} >{max}</Col>
              <Col xm={4} >{min}</Col>
              <Col xm={4} >{ave}</Col>
            </Row>
          </td>
        )
      })
    );
  }

  hourlyData(piData){
    const hdata = [];
    let backColor = "";

    for(let h = 0; h < 24; h++){
      const tdData = [];
      let hour = "-"

      piData.forEach((pi, i) => {
        const hpi = pi.hourly_data[h]
        if(hpi === undefined) return;

        if(i === 0) {
          hour = `${hpi.hour + 1}時`
        }

        const value = hpi.value !== null ? hpi.value : "-";

        tdData.push(
          <td key={`${pi.item_no}-${hpi.hour}`}>{value}</td>
        )
      })

      if(tdData.length === 0) continue;
      if(h % 4 === 0){
        backColor = backColor === "" ? "change-bk-color" : "";
      }

      hdata.push(
        <tr key={h} className={`rp-p-data ${backColor}`}>
          <td>{hour}</td>
          {tdData}
        </tr>
      );
    }

    return hdata;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DailyPiData);
