import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/shutterAction';

class ContentSensorInfo extends Component {

  render() {
    const props = this.props.shutter;
    if(props.inputStatus.length === 0){
      return null;
    }

    return (
      <div className="card main-contents-layout">
        <div className="card-body-common shutter-info-content-group">
          <div className="shutter-info-content-header">
            入出力項目
          </div>

          <div className="row no-gutters shutter-sensor-status">
            {props.inputStatus.map((data, i) => {
              const statusCss = data.status ? "-on" : "";

              return (
                <div className={`col-lg-4 col-md-6 status-text${statusCss}`}
                    key={i}>
                  <span className={`status-icon${statusCss}`}>&nbsp;</span>
                  { data.name }
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentSensorInfo);
