import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/reportAction';
import { Button, Form, Row, Col } from 'react-bootstrap';

class SelectDate extends Component {
  constructor(props) {
    super(props);

    this.years = [];
    this.months = ["ー", 1, 2, 3,4, 5, 6, 7, 8, 9, 10, 11, 12];

    const dt = new Date();
    const nowYear = dt.getFullYear();
    const days = ["ー"];
    
    for(let y = nowYear; y >= 2020; y--){
      this.years.push(y);
    }

    this.state = {
      initYear: nowYear,
      initMonth: 0,
      initDay: 0,
      days: days
    };
  }

  render() {
    return (
      <Row noGutters={true} className="card-body-common report-select-date-layout">
        <Col xs={"auto"} className="d-flex flex-row form-select-col">
          {this.formSelectYear()}年
        </Col>
        <Col xs={"auto"} className="d-flex flex-row form-select-col">
          {this.formSelectMonth()}月
        </Col>
        <Col xs={"auto"} className="d-flex flex-row form-select-col">
          {this.formSelectDay()}日
        </Col>

        <Col xs="col-auto" style={{marginLeft:"30px"}}>
          <Button variant="success" className="btn-common" onClick={() => this.showReportData()}>
            表示
          </Button>
        </Col>
      </Row>

    );
  }

  formSelectYear(){
    return(
      <Form.Control as="select" size="sm" className="form-control"
        id="select-report-year" defaultValue={this.state.initYear}
        onChange={e => { this.selectYear(e.target.value) }}>
        {
          this.years.map(year => {
            return(
              <option key={year} value={year}>
                {year}
              </option>
            )
          })
        }
      </Form.Control>
    );
  }

  formSelectMonth(){
    return(
      <Form.Control as="select" size="sm" className="form-control"
        id="select-report-month" defaultValue={this.state.initMonth}
        onChange={e => { this.selectMonth(e.target.value) }}>
        {
          this.months.map((month, i) => {
            return(
              <option key={i} value={i}>
                {month}
              </option>
            )
          })
        }
      </Form.Control>
    );
  }

  formSelectDay(){
    return(
      <Form.Control as="select" size="sm" className="form-control"
        id="select-report-day" defaultValue={this.state.initDay}
        onChange={e => { this.selectDay(e.target.value) }}>
        {
          this.state.days.map((day, i) => {
            return(
              <option key={i} value={i}>
                {day}
              </option>
            )
          })
        }
      </Form.Control>
    );
  }

  selectYear(year){

  }

  selectMonth(month){
    const days = ["ー"];
    if(month > 0){
      const year  = document.getElementById('select-report-year').value;
      const dt = new Date(year, month, 0);

      for(let day = 1; day <= dt.getDate(); day++){
        days.push(day);
      }
    }

    this.setState({days: days});
  }

  selectDay(day){

  }

  showReportData(){
    const year  = document.getElementById('select-report-year').value;
    const month = document.getElementById('select-report-month').value;
    const day   = document.getElementById('select-report-day').value;

    this.props.getReportData(this.props.shutterId,
                             Number(year), Number(month), Number(day));
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDate);
