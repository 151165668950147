import { store } from 'modules/store.js';
import { instance as http } from 'modules/httpConnection'

export function mapStateToProps(state) {
  return state;
}

export function mapDispatchToProps(dispatch) {
  return {
    /*
      連絡先情報リストを取得
    */
    getContactList: (shutterId) => {
      getContactList(shutterId)
    },

    /*
      連絡先情報リストを取得
    */
    getContactDetail: (contactId, shutterId) => {
      const params = new URLSearchParams();
      params.append('shutter_id', shutterId);

      http.post(`contacts/detail/${contactId}`, params)
        .then((response) => {
          store.dispatch( {
            type: 'CONTACT_DETAIL',
            data: response.data
          });
        })
        .catch((data) => {
          console.error(data);
        });
    },

    /*
      連絡先情報の保存
    */
    save: (contactId, params, callback) => {
      http.post(`contacts/save/${contactId}`, params)
        .then((response) => {
          let resultMsg = "保存しました"
          if(contactId <= 0) resultMsg = "新規に登録しました"

          store.dispatch( {
            type: 'CONTACT_SAVE',
            saveResult: response.data.success,
            resultMsg: resultMsg,
            data: response.data.data
          });

          callback(response.data)
        })
        .catch((data) => {
          console.error(data);
        });
    },

    /*
      連絡先情報の削除
    */
    delete: (contactId, shutterId, callback) => {
      const params = new URLSearchParams();
      params.append('shutter_id', shutterId);

      http.post(`contacts/delete/${contactId}`, params)
        .then((response) => {
          let message = "削除に失敗しました。しばらくしてから再度行ってください";
          if(response.data.success) message = "削除が完了しました"

          callback(response.data, message)
        })
        .catch((data) => {
          console.error(data);
        });
    }

  }
}

export function getContactList(shutterId){
  store.dispatch( {type: 'CLEAR_CONTACT_DATA'} );

  http.post(`contacts/list/${shutterId}`)
    .then((response) => {
      store.dispatch( {
        type: 'CONTACT_LIST',
        shutterId: shutterId,
        list: response.data
      });
    })
    .catch((data) => {
      console.error(data);
    });
}
