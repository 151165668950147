import { store } from 'modules/store.js';
import { instance as http } from 'modules/httpConnection'
import { clearShutterPopover } from 'modules/actions/mainMapAction';

export function mapStateToProps(state) {
  return state;
}

export function mapDispatchToProps(dispatch) {
  return {
    getReportData: (shutterId, year, month, day) => {
      store.dispatch( {
        type: 'LOAD_REPORT_DATA',
        shutterId: shutterId,
        year: year,
        month: month,
        day: day
      });

      const params = new URLSearchParams();
      params.append('year', year);
      params.append('month', month);
      params.append('day', day);

      let path = "yearly_data";

      if(month > 0 && day > 0){
        path = "daily_data";
      }else if(month > 0 && day === 0){
        path = "monthly_data";
      }

      http.post(`reports/${path}/${shutterId}`, params)
        .then((response) => {
          store.dispatch( {
            type: 'REPORT_DATA',
            drData: response.data.dr_data,
            aiData: response.data.ai_data,
            piData: response.data.pi_data
          });
        })
        .catch((data) => {
          console.error(data);
        });
    },

    changeDataType: (dataType) => {
      store.dispatch( {type: 'CHANGE_REPORT_DATA_TYPE', dataType: dataType} );
    },

    changeDailyAiTime: (time) => {
      store.dispatch( {type: 'CHANGE_REPORT_AI_TIME', displyTime: time} );
    },

    changeEditMode: (bool) => {
      store.dispatch( {type: 'CHANGE_REPORT_EDIT_MODE', mode: bool} );
    },

    saveYearlyDrData: (shutterId, params, callback) => {
      http.post(`reports/save_yearly_dr_data/${shutterId}`, params)
        .then((response) => {
          if(response.data.success){
            store.dispatch( {
              type: 'RESULT_SAVE_REPORT_DATA',
              drData: response.data.data.dr_data
            });
          }

          callback(response.data.success)
        })
        .catch((data) => {
          console.error(data);
        });
    },

    /*
      平面図上のすべてのバルーンを非表示
    */
    clearShutterPopover: () => {
      clearShutterPopover();
    },

    clearReporCacheData: () => {
      clearReporCacheData();
    }
  }
}

export function clearReporCacheData(){
  store.dispatch( {type: 'CLEAR_REPORT_DATA'} );
}
