const initialState = {
  shutterId: undefined,
  newCreated: false,
  list: [],
  photoData: {}
}

export default function(state = initialState, action) {
  switch(action.type) {
    case 'INSPECTION_LIST':
      return {
        ...state,
        shutterId: action.shutterId,
        list: action.list,
        newCreated: false,
        photoData: {}
      };
    case 'INSPECTION_DETAIL':
      return {
        ...state,
        data: action.data
      };
    case 'INSPECTION_PHOTO_DATA':
      if(action.photoData){
        const photoData = state.photoData;
        photoData[action.fileId] = action.photoData

        return {...state, photoData: photoData};
      }

      return state;
    case 'INSPECTION_SAVE':
      return {
        ...state,
        newCreated: (action.inspectionId === 0)
      };
    case 'CLEAR_INSPECTION_DATA':
      return {
        ...state,
        shutterId: undefined,
        list: [],
        data: {}
      };
    default:
      return state;
  }
}
