import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/authAction';
import { Form } from 'react-bootstrap';
import * as cookies from 'modules/cookies.js';
import { sliceText } from 'modules/utils';

class Header extends Component {
  render() {
    return (
      <div className="fixed-top header">
        { this.rogoForm() }
        { this.headerForm() }
      </div>
    );
  }

  componentDidMount() {
    if(cookies.verifyCredentials()){
      this.props.getCompanyList();
    }
  }

  rogoForm(){
    let content = "happy gate MONBAN";
    if(this.props.auth.logined){
      content = (
        <div className="rogo-link" onClick={(e) => this.onClickMainMenu() }>
          happy gate MONBAN
        </div>
      );
    }

    return (
      <div className="h-text-rogo float-left">
        { content }
      </div>
    );
  }

  headerForm(){
    let form;
    if(this.props.auth.logined){
      form = (
        <div className="h-vertical float-right">
          <div className="row no-gutters">
            <div className="col-auto" style={{marginRight: `5px`}}>
              { this.companiesSelect() }
            </div>
            <div className="col-auto">
              <Link to="/logout" className="h-menu-btn">ログアウト</Link>
            </div>
          </div>
        </div>
      );
    }
    return form;
  }

  companiesSelect(){
    const ary = this.props.common.menuCompanies;
    const forms = []
    const companyId = Number(cookies.get("companyId"));
    let selectedValue = ""

    ary.forEach(item => {
      if(companyId === item.company_id) selectedValue = item.company_id

      forms.push(
        <option value={item.company_id} key={item.company_id}>
          {sliceText(item.name, 16)}
        </option>
      );
    });

    return (
      <Form.Control as="select" size="sm"
        className="select-company"
        value={selectedValue}
        onChange={e => { this.selectCompany(e.target.value) }}>

        { forms }
      </Form.Control>
    );
  }

  selectCompany(value){
    cookies.set("menuKey", "");
    cookies.set("companyId", value);
    cookies.set("factoryId", "");
    cookies.set("shutterId", "");
    cookies.set("buildingId", "");
    window.location.href = `/main_map`;
  }

  /*
  メインの平面図画面を表示
  */
  onClickMainMenu(){
    cookies.set("menuKey", "main_maps");
    this.props.history.push(`/main_map`);
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
