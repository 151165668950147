import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/contactAction';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';

class ContactCreate extends Component {
  constructor(props) {
    super(props);
    this.shutterId = this.props.match.params.shutterId;

    this.state = {
      saving: false,
      resultMsg: "",
      formAlert: ""
    };
  }

  render(){
    const saveButton = this.formSaveButton();

    return(
      <Form className="card-body-common" onSubmit={(e) => {this.handleSubmit(e);}}>
        <Row noGutters={true} className="content-header">
          <Col xs="auto">
            <Link to={`/contact/list/${this.shutterId}`}
                  className="btn btn-common btn-outline-success">
              連絡先一覧
            </Link>
          </Col>
          <Col></Col>
          <Col xs="auto" className="d-flex align-items-end failure-message">{this.state.resultMsg}</Col>
          <Col xs="auto">
            { saveButton }
          </Col>
        </Row>

        <div className="content-body">
          <div className="contact-form">
            <Form.Group as={Row} className="mb-3" controlId="formCompany">
              <Form.Label column sm="2">
                会社名
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text"  className={this.state.formAlert} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formDepartment">
              <Form.Label column sm="2">
                部署名
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text" />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPerson">
              <Form.Label column sm="2">
                担当者名
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text" className={this.state.formAlert} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formTel">
              <Form.Label column sm="2">
                電話番号
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text" />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formFax">
              <Form.Label column sm="2">
                FAX番号
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text" />
              </Col>
            </Form.Group>
          </div>
        </div>
      </Form>
    );
  }

  formSaveButton(){
    let form = (
      <Button variant="success" type="submit" className="btn-common">
        新規登録
      </Button>
    )

    if(this.state.saving){
      form = (
        <div className="loading-text d-flex align-items-center loading">
          <div className="spinner-border text-success" role="status"></div>
          <div className="loading-title">保存しています。しばらくお待ちください...</div>
        </div>
      )
    }
    return form;
  }

  handleSubmit(e) {
    // 再リロード防止
    e.preventDefault();

    const company = e.target.formCompany.value.trim();
    const department = e.target.formDepartment.value.trim();
    const person = e.target.formPerson.value.trim();
    const tel = e.target.formTel.value.trim();
    const fax = e.target.formFax.value.trim();

    if(company === "" && person === ""){
      alert("会社名もしくは担当者名を記入してください");
      this.setState({formAlert: "form-alert"});
      return;
    }

    this.setState({saving: true});

    const params = {
      contact_id: 0,
      shutter_id: this.shutterId,
      company: company,
      department: department,
      person_name: person,
      tel: tel,
      fax: fax,
    };

    const callback = (data) => {
      if(data.success){
        this.props.history.push(`/contact/info/${this.shutterId}/${data.data.contact_id}`);
      }else{
        this.setState({
          saving: false,
          resultMsg: "新規登録に失敗しました。設定内容を確認してください"
        });
      }
    }

    this.props.save(0, params, callback);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactCreate);
