import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/finderAction';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

class FinderList extends Component {

  componentDidMount() {
    const shutterId = this.props.match.params.shutterId;
    this.props.getFinderList(shutterId)
  }

  render(){
    this.shutterId = this.props.match.params.shutterId;

    return(
      <div className="card-text card-body-common">
        <Row noGutters={true} className="content-header">
          <Col xs="auto" className="d-flex align-items-end">
            資料一覧
          </Col>
          <Col></Col>
          <Col xs="auto">
            <Link to={`/finder/create/${this.shutterId}`}
                  className="btn btn-common btn-success">
              新規作成
            </Link>
          </Col>
        </Row>

        {this.formContents()}
      </div>
    );
  }

  formContents(){
    const list = this.props.finder.list;
    if(list.length){
      return(
        <table className="table table-hover table-common finder-list-table">
          <tbody>
            {
              list.map(data => {
                return(
                  <tr key={data.finder_id}
                      onClick={() => this.onClickItem(data.finder_id)}>
                    <td className="finder-list-table-td">
                      <Row noGutters={true} className="finder-list-created-date-row">
                        <Col xs="auto">
                          作成日 {data.date}
                        </Col>
                        <Col xs="auto" className="finder-list-person-col">
                          {data.created_user}
                        </Col>
                      </Row>

                      <Row noGutters={true} className="finder-list-data-row">
                        <Col>
                          {data.title}
                        </Col>
                        <Col xs="auto">
                          ファイル数:{data.files_count}
                        </Col>
                      </Row>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      )
    }else{
      return(
        <div className="content-body">
          データがありません。新規作成を行ってください
        </div>
      )
    }

  }

  onClickItem(finderId){
    console.log("FinderList:onClickItem",finderId)
    //this.props.history.push(`/finder/edit/${this.shutterId}/${finderId}`);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinderList);
