import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { verifyCredentials } from 'modules/cookies.js';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/authAction';

class Auth extends Component {
  constructor(props) {
    super(props);

    if(verifyCredentials()){
       this.props.storeLogined();
    }
  }

  render() {
    if(verifyCredentials()){
      return (<Route children={this.props.children} />);
    }else{
      return (<Redirect to="/login" />);
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
