import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { WEEKS } from 'modules/utils';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/reportAction';

class MonthlyPiData extends Component {
  render() {
    const piData = this.props.report.piData;

    return (
      <div className="content-report-data"
           style={{height:`${this.props.contentH}px`}}>
        <table className="table-sm rp-table">
          <tbody>
            <tr className="rp-header-record">
              <td className="rp-h-item-title">
                <div className="rp-h-cell"></div>
                <div className="rp-h-cell l-b-1"></div>
                <div className="rp-h-cell l-b-1">累計</div>
                <div className="rp-h-cell l-b-1">月計</div>
                <div className="rp-h-cell"></div>
                <div className="rp-h-cell l-b-1"></div>
              </td>

              { this.headerData(piData) }
            </tr>

            { this.dailyData(piData) }
          </tbody>
        </table>
      </div>
    );
  }

  headerData(piData){
    return(
      piData.map(pi => {
        const unit  = pi.unit !== null ? pi.unit : "-";
        const total = pi.total !== null ? pi.total : "-";
        const mVal = pi.value !== null ? pi.value : "-";
        const max  = pi.max !== null ? pi.max : "-";
        const min  = pi.min !== null ? pi.min : "-";
        const ave  = pi.ave !== null ? pi.ave : "-";

        return(
          <td key={pi.item_no} className="rp-h-data">
            <div className="rp-h-cell rp-h-d-w-pi rp-h-d-title">{pi.name}</div>
            <div className="rp-h-cell rp-h-d-item l-b-1">{unit}</div>
            <div className="rp-h-cell rp-h-d-item l-b-1">{total}</div>
            <div className="rp-h-cell rp-h-d-item">{mVal}</div>
            <Row noGutters={true} className="rp-h-cell rp-h-d-category">
              <Col xm={4} >最大</Col>
              <Col xm={4} >最小</Col>
              <Col xm={4} >平均</Col>
            </Row>

            <Row noGutters={true} className="rp-h-cell rp-h-d-val l-b-1">
              <Col xm={4} >{max}</Col>
              <Col xm={4} >{min}</Col>
              <Col xm={4} >{ave}</Col>
            </Row>
          </td>
        )
      })
    );
  }

  dailyData(piData){
    const ddata = [];

    for(let d = 0; d < 31; d++){
      const tdData = [];
      let date = "-"
      let weekColor = ""

      piData.forEach((pi, i) => {
        const dpi = pi.daily_data[d]
        if(dpi === undefined) return;

        if(i === 0) {
          date = `${dpi.day}日(${WEEKS[dpi.wday]})`
          if(dpi.wday === 0){weekColor = "w-sun";}
          else if(dpi.wday === 6){weekColor = "w-sat";}
        }

        const value = dpi.value !== null ? dpi.value : "-";

        tdData.push(
          <td key={`${pi.item_no}-${dpi.day}`} className={weekColor}>{value}</td>
        )
      })

      if(tdData.length === 0) continue;

      ddata.push(
        <tr key={d} className="rp-p-data">
          <td className={weekColor}>{date}</td>
          {tdData}
        </tr>
      );
    }

    return ddata;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyPiData);
