const initialState = {
  menuFlatsheets: [],
  menuCompanies: [],
  loadingMsg: "データを取得しています。しばらくお待ちください...",
  factoryId: "",
  shutterId: "",
  watches: [],
  shuttersMap: {},
  enableConnectShutters: false
}

export default function(state = initialState, action) {
  switch(action.type) {
    case 'SELECTED_ID':
      return {
        ...state,
        factoryId: action.factoryId,
        shutterId: action.shutterId
      };
    case 'WATCHES_SHUTTER':
      return {
        ...state,
        watches: action.watches,
        shuttersMap: action.shuttersMap,
        enableConnectShutters: action.enableConnectShutters
      };
    case 'FLOW_MAP_DATA':
      return {
        ...state,
        shuttersMap: action.shuttersMap,
        enableConnectShutters: action.enableConnectShutters
      };
    case 'MAINMAP_MODAL_CREATE_SHUTTER':
      const shutter = action.data;

      state.shuttersMap[shutter.id] = {
        name: shutter.name, deviceType: shutter.device_type
      };
      return { ...state, shuttersMap: state.shuttersMap };
    case 'HEADER_MENU':
      return {
        ...state,
        menuFlatsheets: action.menuFlatsheets,
        menuCompanies: action.menuCompanies
      };
    case 'LOADING_MESSAGE':
      return {
        ...state,
        loadingMsg: action.loadingMsg
      };
    case 'RECV_SHUTTER_STATUS':
      let watches = state.watches;

      state.watches.forEach(item => {
        item["shutters"].forEach(sItem => {
          if(sItem.imsi === action.imsi){
            sItem.alert_flg = action.alertFlg
          }
        });
      });
      return {
        ...state,
        watches: watches
      };
    default:
      return state;
  }
}
