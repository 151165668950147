import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/mainMapAction';
import Contents from './Contents';
import * as cookies from 'modules/cookies.js';

class MainMap extends Component {
  constructor(props) {
    super(props);

    this.factoryId = cookies.get('factoryId');
  }

  render(){
    return(<Contents factoryId={this.factoryId}/>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMap);
