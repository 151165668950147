import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/authAction';

class Loading extends Component {
  render() {
    if(this.props.loading){
      let loadingMsg = this.props.common.loadingMsg;
      if(this.props.loadingMsg) loadingMsg = this.props.loadingMsg;

      return (
        <div className="d-flex align-items-center justify-content-center loading">
          <div className="spinner-border text-success" role="status"></div>
          <strong className="loading-title">{loadingMsg}</strong>
        </div>
      );
    }else{
      return null;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
