import React, { Component } from 'react';
import ModalErrorContents from './ModalErrorContents';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/shutterAction';
import OpenBtn from 'images/control_open.png'
import CloseBtn from 'images/control_close.png'

class ContentStatus extends Component {
  constructor(props) {
    super(props);
    this.isControll = false;
    this.state = {fontSize: 16, testFlg: false};
    window.addEventListener('resize', () => {
      this.onResize();
    });
  }

  componentDidMount() {
    this.onResize();
  }

  render() {
    const item = this.props.shutter;

    return (
      <div className="row no-gutters shutter-info-content-group p-0">
        {/* ステータス */}
        { this.contentItemError(item) }

        {/* 動作モード  */}
        { this.contentItemDriveMode(item) }

        {/* ECOモード  */}
        {/* this.contentItemEco(item) */}

        {/* 開閉状態  */}
        { this.contentItemOpen(item) }

        <ModalErrorContents />
      </div>
    );
  }


  onClickErrorModal(item){
    const errorCD = this.props.shutter.errors[0]
    this.props.getErrorContents(item.shutterId, errorCD);
    this.props.showErrorModal(true);
  }

  contentItemError(item){
    let clickFunction = () => {};
    let status = item.errorCount === -1 ? "-" : "正常";
    let color = "status-color-blue";

    if(item.errors.length){
      clickFunction = () => this.onClickErrorModal(item);
      status = "エラー";
      color = "device-alert";
    }

    return (
      <div className="col text-center">
        {this.contentItemData("ステータス", status, color,
                              item.execAlertStop, clickFunction)}
      </div>
    );
  }

  contentItemDriveMode(item){
    let checkbox = null;
    if(item.remoteControllable && item.autoModeFlg !== null &&
      item.autoModeFlg !== undefined && item.deviceType !== 5){
      checkbox = (
        <div className="toggle-switch pt-1">
          <input id='toggle-switch-id' className="toggle-input" type='checkbox'
                 checked={item.autoModeFlg}
                 onChange={event => this.onChangeDriveMode(event, item)}/>
          <label htmlFor="toggle" className="toggle-label"/>
          <span></span>
        </div>
      );
    }

    return (
      <div className="col text-center">
        {this.contentItemData("動作モード", item.driveMode,
                              "status-color-blue", item.execDriveMode)}
        {checkbox}
      </div>
    );
  }

  contentItemEco(item){
    let checkbox = null;
    if(item.remoteControllable && item.ecoModeFlg !== null &&
      item.ecoModeFlg !== undefined && item.deviceType !== 5){
      checkbox = (
        <div className="toggle-switch pt-1">
          <input id='toggle-switch-id' className="toggle-input" type='checkbox'
                 checked={item.ecoModeFlg}
                 onChange={event => this.onChangeEcoMode(event, item)}/>
          <label htmlFor="toggle" className="toggle-label"/>
          <span></span>
        </div>
      );
    }

    return (
      <div className="col text-center">
        {this.contentItemData("ECOモード", item.ecoMode,
                              "status-color-green", item.execEcoMode)}
        {checkbox}
      </div>
    );
  }

  contentItemOpen(item){
    const color = this.driveStatusColor(item.driveStatusKey);
    let remotes = null;

    if(item.remoteControllable){
      remotes = (
        <div className="row no-gutters justify-content-center">
          <div className="col-auto mr-2 shutter-control-btn-bk">
            <img src={OpenBtn}
                 className="shutter-control-btn" alt="OpenBtn"
                 onClick={event => this.onClickShutterControl(event, item, "open")}/>
          </div>
          <div className="col-auto ml-2 shutter-control-btn-bk">
            <img src={CloseBtn}
                 className="shutter-control-btn" alt="CloseBtn"
                 onClick={event => this.onClickShutterControl(event, item, "close")}/>
          </div>
        </div>
      );
    }
    return (
      <div className="col text-center">
        {this.contentItemData("開閉状態", item.driveStatus,
                              color, item.execShutterControl)}
        {remotes}
      </div>
    );
  }

  contentItemData(title, value, color, execFlg, func = undefined){
    if(execFlg){
      color += " status-opacity";
    }

    return(
      <div className="s-status-group">
        <div className={`status-group-data ${color}`}
             onClick={func}>
          <div className="status-item-title"
               style={{fontSize: `${this.state.fontSize}px`}}>
               { title }
          </div>
          <div className="status-item-data"
               style={{fontSize: `${this.state.fontSize + 11}px`}}>
               { value }
          </div>
        </div>
      </div>
    );
  }

  onChangeEcoMode(event, item){
    //遠隔実行中の場合は実行しない
    if(item.execEcoMode) return;

    this.props.execRemoteControl(item.shutterId, 'EXEC_ECO_MODE',
                                'eco_mode_' + event.target.checked ? 'on' : 'off',
                                event.target.checked);
  }

  onChangeDriveMode(event, item){
    //遠隔実行中の場合は実行しない
    if(item.execDriveMode) return;

    this.props.execRemoteControl(item.shutterId, 'EXEC_DRIVE_MODE',
                                (event.target.checked ? 'auto' : 'manual') + '_mode_on',
                                event.target.checked);
  }

  onClickShutterControl(event, item, controlTag){
    //遠隔実行中の場合は実行しない
    if(item.execShutterControl) return;

    this.props.execRemoteControl(item.shutterId, 'EXEC_SHUTTER_CONTROL', controlTag);
  }

  onResize(){
    const id = `content-status-drive-mode-data-${this.props.shutter.shutterId}`;
    const obj = document.getElementById(id);

    if(obj != null){
      const width = obj.clientWidth;
      const fontSize = width * 0.16 - 0.2;

      this.setState({
        fontSize: fontSize
      });
    }
  }

  driveStatusColor(status){
    let color = ""

    switch(status){
      case "open":
        color = "status-color-open"
        break;
      case "init":
      case "opening":
      case "stop":
      case "closing":
      case "eco":
        color = "status-color-other"
        break;
      case "close":
        color = "status-color-close"
        break;
      default:
        color = "status-color-none"
        break;
    }

    return color
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentStatus);
