import { store } from 'modules/store.js';
import { instance as http } from 'modules/httpConnection'
import * as cookies from 'modules/cookies.js';
import { getDeviceFactory } from 'devices/deviceFactory';
import { reflectDataToMainMap } from 'modules/actions/mainMapAction';
import { reflectDataToTop } from 'modules/actions/topAction';

export function mapStateToProps(state) {
  return state;
}

export function mapDispatchToProps(dispatch) {
  return {
    login: (account, password, callback) => {
      const params = new URLSearchParams();
      params.append('account', account);
      params.append('password', password);

      http.post('auths/sign_in', params)
        .then((response) => {
          const logined = cookies.verifyCredentials();

          cookies.setAuthData(response.data.data);
          store.dispatch( {type: 'LOGIN', logined: logined} );
          callback(response.data.data);
        })
        .catch((data) => {
          console.error(data);
          store.dispatch( {type: 'LOGIN', logined: false} );
        });
    },

    storeLogined: () => {
      store.dispatch( {type: 'LOGIN', logined: true} );
    },

    logout: () => {
      store.dispatch( {type: 'LOGOUT', logined: false} );
    },

    /*
      企業リストを取得
    */
    getCompanyList:() => {
      const companyId = cookies.get("companyId");
      const params = new URLSearchParams();
      params.append('company_id', companyId);

      http.post('auths/company_list', params)
        .then((response) => {
          store.dispatch( {
            type: 'HEADER_MENU',

            menuFlatsheets: response.data.factories,
            menuCompanies: response.data.companies
          });
        })
        .catch((data) => {
          console.error(data);
        });
    },

    /*
      監視対象のシャッターリストを取得
    */
    getShutterWatchList: (callback) => {
      const companyId = cookies.get("companyId");
      const buildingId = cookies.get('buildingId')
      const params = new URLSearchParams();
      params.append('company_id', companyId);
      params.append('building_id', buildingId);

      http.post('shutters/watch_list', params)
        .then((response) => {
          parseWatchListData(response.data, callback);
        })
        .catch((data) => {
          console.error(data);
        });
    },

    /*
      websocketからの情報をstoreに反映させる
    */
    receivedStatus: (rcevData, pathname) => {
      const dv = getDeviceFactory(rcevData.device_type);
      const id = rcevData.id;

      if(rcevData.device_type <= 100){
        const data = dv.parseRawData(rcevData);

        store.dispatch( {
          type: 'RECV_SHUTTER_STATUS',
          shutterId: id,
          imsi: rcevData.imsi,
          alertFlg: (data.errorCount > 0),
          totalDriveCount: data.totalDriveCount,
          driveCount: data.driveCount,
          driveTime: data.driveTime,
          driveMode: data.driveMode,
          driveStatus: data.driveStatus,
          driveStatusKey: data.driveStatusKey,
          sensorInfo: data.sensorInfo,
          ecoMode: data.ecoMode,
          ecoModeFlg: data.ecoModeFlg,
          autoModeFlg: data.autoModeFlg,
          errorCount: data.errors.length,
          errors: data.errors
        });
      }

      if(pathname.includes("top")){
        reflectDataToTop(id);
      }else if(pathname.includes("main_map")){
        if(rcevData.device_type > 100){
          reflectDataToMainMap(id);
        }
      };
    }

  }
}

function parseWatchListData(data, callback) {
  const shuttersMap = {};
  let enableConnectShutters = false;

  data.watches.forEach(fItem => {
    let ary = fItem.shutters.filter(val => {
      return val["device_type"] < 90;
    })

    if(ary.length) enableConnectShutters = true

    fItem.shutters.forEach(sItem => {
      shuttersMap[sItem.id] = {
        name: sItem.name,
        deviceType: sItem.device_type
      };
    });
  });

  store.dispatch( {
    type: 'WATCHES_SHUTTER',
    watches: data.watches,
    shuttersMap: shuttersMap,
    enableConnectShutters: enableConnectShutters
  });

  callback();
}
