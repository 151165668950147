import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/contactAction';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

class ContactInfo extends Component {
  constructor(props) {
    super(props);
    this.shutterId = this.props.match.params.shutterId;
    this.contactId = this.props.match.params.contactId;
  }

  /*
    render後に呼ばれる
  */
  componentDidMount() {
    if(this.props.contact.data.contact_id === undefined){

      const params = {shutter_id: this.shutterId}
      this.props.getContactDetail(this.contactId, params)
    }
  }

  render(){
    const data = this.props.contact.data;

    return(

      <div className="card-text card-body-common">
        <Row noGutters={true} className="content-header">
          <Col xs="auto" className="d-flex align-items-end">
            <Link to={`/contact/list/${this.shutterId}`}
                  className="btn btn-common btn-outline-success">
              連絡先一覧
            </Link>
          </Col>
          <Col></Col>
          <Col xs="auto" className="d-flex align-items-end result-message">
            {this.props.contact.resultMsg}
          </Col>
        </Row>

        <div className="content-body">
          <table className="contact-table">
            <tbody>
              <tr>
                <td className="contact-td-title">会社名</td>
                <td>{data.company}</td>
              </tr>
              <tr>
                <td className="contact-td-title">部署名</td>
                <td>{data.department}</td>
              </tr>
              <tr>
                <td className="contact-td-title">担当者名</td>
                <td>{data.person_name}</td>
              </tr>
              <tr>
                <td className="contact-td-title">電話番号</td>
                <td>{data.tel}</td>
              </tr>
              <tr>
                <td className="contact-td-title">FAX番号</td>
                <td>{data.fax}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
