import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { WEEKS } from 'modules/utils';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/reportAction';

class MonthlyDrData extends Component {
  render() {
    const drData = this.props.report.drData;

    return (
      <div className="content-report-data"
           style={{height:`${this.props.contentH}px`}}>
        <table className="table-sm rp-table">
          <tbody>
            <tr className="rp-header-record">
              <td className="rp-h-item-title">
                <div className="rp-h-cell"></div>
                <div className="rp-h-cell l-b-1"></div>
                <div className="rp-h-cell l-b-1">累計</div>
                <div className="rp-h-cell l-b-1">月計</div>
                <div className="rp-h-cell l-b-1">最大</div>
                <div className="rp-h-cell l-b-1">最小</div>
                <div className="rp-h-cell l-b-1">平均</div>
              </td>

              { this.headerData(drData) }
            </tr>

            { this.dailyData(drData) }
          </tbody>
        </table>
      </div>
    );
  }

  headerData(drData){
    return(
      drData.map(dr => {
        let totalCount = dr.total_count !== null ? dr.total_count : "-";
        let totalTime = dr.total_time !== null ? dr.total_time : "-";
        let monthlyCount = dr.monthly_count !== null ? dr.monthly_count : "-";
        let monthlyTime = dr.monthly_time !== null ? dr.monthly_time : "-";
        const countMax = dr.count_max !== null ? dr.count_max : "-";
        const countMin = dr.count_min !== null ? dr.count_min : "-";
        const countAve = dr.count_ave !== null ? dr.count_ave : "-";
        const timeMax  = dr.time_max !== null ? dr.time_max : "-";
        const timeMin  = dr.time_min !== null ? dr.time_min : "-";
        const timeAve  = dr.time_ave !== null ? dr.time_ave : "-";
        const editedTotalCount = dr.ch_total_count !== null ? "rp-edit" : "";
        const editedTotalTime  = dr.ch_total_time !== null ? "rp-edit" : "";
        const editedMonthlyCount = dr.ch_monthly_count !== null ? "rp-edit" : "";
        const editedMonthlyTime  = dr.ch_monthly_time !== null ? "rp-edit" : "";

        if(dr.ch_total_count) totalCount = dr.ch_total_count;
        if(dr.ch_total_time)  totalTime  = dr.ch_total_time;
        if(dr.ch_monthly_count) monthlyCount = dr.ch_monthly_count;
        if(dr.ch_monthly_time)  monthlyTime  = dr.ch_monthly_time;

        return(
          <td key={dr.item_no} className="rp-h-data">
            <div className="rp-h-cell rp-h-d-w-dr rp-h-d-title">{dr.name}</div>
            <Row noGutters={true} className="rp-h-cell rp-h-d-category">
              <Col xm={6} >回数</Col>
              <Col xm={6} >時間(時:分)</Col>
            </Row>
            <Row noGutters={true} className="rp-h-cell rp-h-d-val l-b-1">
              <Col xm={6} className={editedTotalCount}>{totalCount}</Col>
              <Col xm={6} className={editedTotalTime}>{totalTime}</Col>
            </Row>
            <Row noGutters={true} className="rp-h-cell rp-h-d-val l-b-1">
              <Col xm={6} className={editedMonthlyCount}>{monthlyCount}</Col>
              <Col xm={6} className={editedMonthlyTime}>{monthlyTime}</Col>
            </Row>
            <Row noGutters={true} className="rp-h-cell rp-h-d-val l-b-1">
              <Col xm={6} >{countMax}</Col>
              <Col xm={6} >{timeMax}</Col>
            </Row>
            <Row noGutters={true} className="rp-h-cell rp-h-d-val l-b-1">
              <Col xm={6} >{countMin}</Col>
              <Col xm={6} >{timeMin}</Col>
            </Row>
            <Row noGutters={true} className="rp-h-cell rp-h-d-val l-b-1">
              <Col xm={6} >{countAve}</Col>
              <Col xm={6} >{timeAve}</Col>
            </Row>
          </td>
        )
      })
    );
  }

  dailyData(drData){
    const ddata = [];

    for(let d = 0; d < 31; d++){
      const tdData = [];
      let date = "-"
      let weekColor = ""

      drData.forEach((dr, i) => {
        const ddr = dr.daily_data[d]
        if(ddr === undefined) return;

        if(i === 0) {
          date = `${ddr.day}日(${WEEKS[ddr.wday]})`
          if(ddr.wday === 0){weekColor = "w-sun";}
          else if(ddr.wday === 6){weekColor = "w-sat";}
        }

        let driveCount = ddr.dr_count !== null ? ddr.dr_count : "-";
        let driveTime  = ddr.dr_time !== null ? ddr.dr_time : "-";
        let editedCount = ddr.ch_count !== null ? "rp-edit" : "";
        let editedTime  = ddr.ch_time !== null ? "rp-edit" : "";

        if(ddr.ch_count) driveCount = ddr.ch_count;
        if(ddr.ch_time)  driveTime  = ddr.ch_time;

        tdData.push(
          <td key={`${dr.item_no}-${ddr.day}`} className={weekColor}>
            <Row noGutters={true}>
              <Col xm={6} className={editedCount}>{driveCount}</Col>
              <Col xm={6} className={editedTime}>{driveTime}</Col>
            </Row>
          </td>
        )
      })
      if(tdData.length === 0) continue;

      ddata.push(
        <tr key={d} className="rp-p-data">
          <td className={weekColor}>{date}</td>
          {tdData}
        </tr>
      );
    }

    return ddata;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyDrData);
