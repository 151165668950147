import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/inspectionAction';
import { Col, Button, Modal } from 'react-bootstrap';

class ModalShowingPhoto extends Component {
  render() {
    return (
      <Modal show={this.props.showModal} size="lg"
             onHide={() => this.props.cancel()}
             aria-labelledby="contained-modal-title-vcenter">
        {this.formHeader()}
        {this.formBody()}
        {this.formFooter()}
      </Modal>
    );
  }

  formHeader(){
    if(this.props.title === undefined) return null;

    return(
      <Modal.Header closeButton onClick={() => this.props.cancel()}
                     className="main-map-modal-header">
        <Modal.Title className="main-map-modal-title">{this.props.title}</Modal.Title>
      </Modal.Header>
    );
  }

  formBody(){
    const root = document.getElementById('root');
    let height = "auto"
    if(root) height = `${root.clientHeight - 200}px`;

    return(
      <Modal.Body>
        <div className="modal-body-show-photo-data" style={{height: height}}>
          <img src={this.props.photoData} alt="PhotoData"
               style={{maxHeight: height}}/>
        </div>
      </Modal.Body>
    )
  }

  formFooter(){
    return(
      <Modal.Footer className="inspection-modal-footer">
        <Col xs="auto" className="p-0">
          <Button variant="danger" className="btn-common"
                  onClick={() => this.onDelete(this.props.state,
                                               this.props.itemNo,
                                               this.props.fileNo,
                                               this.props.fileId)}>
            削除
          </Button>
        </Col>
        <Col></Col>
        <Col xs="auto" className="p-0">
          <label className="mb-0">
            <div className="btn-common btn btn-success">
              ファイル選択
            </div>
            <input className="select-photo-file" type="file"
                   accept="image/*,.png,.jpg,.jpeg,.gif"
                   onChange={(e) => this.selectPhotoData(e, this.props.state,
                                                         this.props.itemNo,
                                                         this.props.fileNo)}/>
          </label>

        </Col>
        <Col xs="auto" className="p-0">
          <Button variant="secondary" onClick={() => this.props.cancel()}>
            閉じる
          </Button>
        </Col>
      </Modal.Footer>
    )
  }

  onDelete(state, itemNo, fileNo, fileId){
    const photoData = state.photoData;
    const deleteFlg = state.deleteFlg;

    if(photoData[itemNo] && photoData[itemNo][fileNo]){
      delete photoData[itemNo][fileNo]
    }
    if(this.props.inspection.photoData[fileId]){
      delete this.props.inspection.photoData[fileId]
    }

    deleteFlg[`${fileNo}_${itemNo}`] = 1

    this.props.updateState({ photoData: photoData, deleteFlg: deleteFlg});
    this.props.cancel();
  }

  selectPhotoData(e, state, itemNo, fileNo){
    if(e.target.files.length <= 0) return;

    const file = e.target.files[0]
    const reader = new FileReader()
    const photoData = state.photoData;
    const deleteFlg = state.deleteFlg;

    reader.onload = (e) => {
      if(photoData[itemNo] === undefined || photoData[itemNo] === null){
        photoData[itemNo] = {}
      }

      photoData[itemNo][fileNo] = {file: file, data: e.target.result}
      deleteFlg[`${fileNo}_${itemNo}`] = 0

      this.props.updateState({ photoData: photoData, deleteFlg: deleteFlg})
      this.props.cancel();
    };
    reader.readAsDataURL(file)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalShowingPhoto);
