import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/reportAction';

class DailyAiData extends Component {
  render() {
    const aiData = this.props.report.aiData;

    return (
      <div className="content-report-data"
           style={{height:`${this.props.contentH}px`}}>
        <table className="table-sm rp-table">
          <tbody>
            <tr className="rp-header-record">
              <td className="rp-h-item-title">
                <div className="rp-h-cell-sm"></div>
                <div className="rp-h-cell-sm"></div>
                <div className="rp-h-cell-sm"></div>
                <div className="rp-h-cell-sm l-b-1"></div>
              </td>

              { this.headerData(aiData) }
            </tr>

            { this.hourlyData(aiData) }
          </tbody>
        </table>
      </div>
    );
  }

  headerData(aiData){
    return(
      aiData.map(ai => {
        const unit = ai.unit !== null ? ai.unit : "-";
        const max  = ai.max !== null ? ai.max : "-";
        const min  = ai.min !== null ? ai.min : "-";
        const ave  = ai.ave !== null ? ai.ave : "-";

        return(
          <td key={ai.item_no} className="rp-h-data">
            <div className="rp-h-cell-sm rp-h-d-w-ai rp-h-d-title">{ai.name}</div>
            <div className="rp-h-cell-sm rp-h-d-item">{unit}</div>

            <Row noGutters={true} className="rp-h-cell-sm rp-h-d-category">
              <Col xm={4} >最大</Col>
              <Col xm={4} >最小</Col>
              <Col xm={4} >平均</Col>
            </Row>

            <Row noGutters={true} className="rp-h-cell-sm rp-h-d-val l-b-1">
              <Col xm={4} >{max}</Col>
              <Col xm={4} >{min}</Col>
              <Col xm={4} >{ave}</Col>
            </Row>
          </td>
        )
      })
    );
  }

  hourlyData(aiData){
    const hdata = [];
    const displyTime = Number(this.props.report.displyTime);
    const loopTime = (1440 / displyTime) - 1;
    let backColor = "";
    let separateCnt = 5;

    if([60, 30, 5].includes(displyTime)){
      separateCnt = 4;
    }else if(displyTime === 10){
      separateCnt = 6;
    }

    for(let t = 0; t <= loopTime; t++){
      const tdData = [];
      const tIndex = t * displyTime;
      let time = "-"

      aiData.forEach((ai, i) => {
        const tai = ai.trend_data[tIndex]
        if(tai === undefined) return;
        if(i === 0) time = `${tai.time}`

        const data  = tai.value !== null ? tai.value : "-";

        tdData.push(
          <td key={`${ai.item_no}-${tIndex}`} className="py-sm">{data}</td>
        )
      })
      if(tdData.length === 0) continue;
      if(t % separateCnt === 0){
        backColor = backColor === "" ? "change-bk-color" : "";
      }

      hdata.push(
        <tr key={tIndex} className={`rp-p-data ${backColor}`}>
          <td className="py-sm">{time}</td>
          {tdData}
        </tr>
      );
    }

    return hdata;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DailyAiData);
