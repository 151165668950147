import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/settingAction';

class ModalResult extends Component {
  render() {
    const props = this.props.setting;
    const data = props.dataList.find(
      item => item.addr === props.targetAddress
    )

    if(data === undefined){ return null; }
    let msg = "";
    if(props.result){
      msg = "設定が完了しました。"
    }else{
      msg = "設定に失敗しました。"
    }
    return (
      <Modal show={props.showModalResult}
             onHide={() => this.onClickHideModal()}
             animation={true}
             aria-labelledby="contained-modal-title-vcenter"
             centered>

        <Modal.Body>
          <div>{msg}</div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={() => this.onClickHideModal()}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  onClickHideModal(){
    this.props.hideModalResult();
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalResult);
