import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/mainMapAction';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { sliceText } from 'modules/utils';
import * as cookies from 'modules/cookies.js';
import * as module from 'modules/moduleMainMap.js';

class HeaderFactory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showShutterMenu: false
    };
  }

  render(){
    this.factoryId = module.findFactoryId(this.props);
    const factories = this.props.common.menuFlatsheets;
    let selectFactoryItem = factories.find(item => item.factory_id === this.factoryId);
    if(selectFactoryItem === undefined){selectFactoryItem = factories[0]}

    return(
      <Row noGutters={true} className="main-header">
        <Col sm={"col-auto"} className="d-flex flex-row">
          <div className="select-title">工場名：</div>
          <Form.Control as="select" size="sm" value={this.factoryId}
            onChange={e => { this.selectFactory(e.target.value) }}>
            {
              factories.map(data => {
                return(
                  <option key={data.factory_id} value={data.factory_id}>
                    {sliceText(data.name,14)}
                  </option>
                )
              })
            }
          </Form.Control>
        </Col>
        <Col sm={"col-auto"} className="d-flex flex-row">
          {this.formBuildings(selectFactoryItem)}
        </Col>


        <Col sm={"col-auto"} className="d-flex flex-row">
          {this.formSelectedShutter()}
        </Col>
      </Row>
    );
  }

  formCreateShutterButton(){
    const location = this.props.location.pathname;
    if(!location.includes("main_map")) return null;

    return(
      <Col sm={"col-auto"} className="menu-item pr-2">
        <Button variant="outline-navy" className="btn-common"
                title="新規に設備を登録します"
                onClick={() => this.onClickShowNewShutterModal()}>
          新規作成
        </Button>
      </Col>
    )
  }

  formBuildings(selectFactoryItem){
    const options = []
    const buildingId = cookies.get('buildingId');

    if(selectFactoryItem !== undefined){
      selectFactoryItem.buildings.forEach(data => {
        options.push(
          <option key={data.building_id} value={data.building_id}>
            {sliceText(data.name, 14)}
          </option>
        )
      })
    }

    return(
      <>
        <div className="select-title">棟名：</div>
        <Form.Control as="select" size="sm" value={buildingId}
          onChange={e => { this.selectBuilding(e.target.value) }}>
          {options}
        </Form.Control>
      </>
    )
  }

  formSelectedShutter(){
    const shutterId = cookies.getShutterId();
    const sMap = this.props.common.shuttersMap[shutterId];

    let name = "設備リストから選択してください";
    if(shutterId > 0 && sMap !== undefined) name = sMap.name;

    return(
      <>
        <div className="target-shutter">
          対象設備：
          <span className="selected-shutter">{sliceText(name, 21)}</span>
        </div>
      </>
    )
  }

  onClickShowNewShutterModal(){
    module.cancelMapPosiotion(-1, this.props);
    this.props.showNewShutterModal();
  }

  selectFactory(value){
    cookies.set("menuKey", "");
    cookies.set("shutterId", "");
    cookies.set("factoryId", value);
    cookies.remove('buildingId');

    window.location.href = `/main_map/${value}`;
  }

  selectBuilding(value){
    cookies.set("menuKey", "");
    cookies.set("shutterId", "");
    cookies.set("buildingId", value);

    window.location.href = `/main_map/${this.factoryId}`;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderFactory));
