const initialState = {
  loading: true,
  shutterId: undefined,
  list: [],
  saveResult: false,
  resultMsg: "",
  data: {}
}

export default function(state = initialState, action) {
  switch(action.type) {
    case 'FINDER_LIST':
      return {
        ...state,
        loading: false,
        shutterId: action.shutterId,
        list: action.list
      };
    case 'FINDER_DETAIL':
      return {
        ...state,
        data: action.data
      };
    case 'FINDER_SAVE':
      return {
        ...state,
        loading: false,
        saveResult: action.saveResult,
        resultMsg: action.resultMsg,
        data: action.data
      };
    default:
      return state;
  }
}
