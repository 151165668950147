import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/inspectionAction';
import { Switch, Route, Redirect } from 'react-router-dom';
import InspectionList from './InspectionList';
import InspectionCreate from './InspectionCreate';
import InspectionEdit from './InspectionEdit';
import InspectionDownloads from './InspectionDownloads';

class Inspection extends Component {

  componentDidMount() {
    const location = this.props.location.pathname;
    const regexp = /download|create|edit/g;
    if(regexp.test(location)) {
      this.props.clearMainMapInfo();
    }
  }

  render() {
    const location = this.props.location.pathname
    let funcName = ""
    let css = ""

    if(location.indexOf("download") !== -1) {
      funcName = "複数ダウンロード"
    }else if(location.indexOf("create") !== -1) {
      funcName = "新規作成"
      css = " inspection-card-header"
    }else if(location.indexOf("edit") !== -1) {
      funcName = "編集"
      css = " inspection-card-header"
    }

    return (
      <div className="card main-contents-layout">
        <div className={`card-header card-header-common ${css}`}>
          設備点検　{funcName}
        </div>

        <Switch>
          <Route exact path="/inspection/list/:shutterId" component={ InspectionList }/>
          <Route exact path="/inspection/create/:shutterId" component={ InspectionCreate }/>
          <Route exact path="/inspection/edit/:shutterId/:inspectionId" component={ InspectionEdit }/>
          <Route exact path="/inspection/download/:shutterId" component={ InspectionDownloads }/>
          <Redirect to="/main_map"/>
        </Switch>
      </div>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Inspection);
