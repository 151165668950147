import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

class ModalNotice extends Component {
  render(){
    return(
      <Modal show={this.props.showModal} size={this.props.size}
             onHide={() => this.props.cancel()}
             aria-labelledby="contained-modal-title-vcenter">
        {this.formHeader()}

        <Modal.Body className="notice-modal-body">
          {this.props.bodyData }
        </Modal.Body>

        {this.formFooter()}
      </Modal>
    )
  }

  formHeader(){
    if(this.props.title === undefined) return null;

    return(
      <Modal.Header closeButton onClick={() => this.props.cancel()}
                     className="main-map-modal-header">
        <Modal.Title className="main-map-modal-title">{this.props.title}</Modal.Title>
      </Modal.Header>
    );
  }

  formFooter(){
    const btnDoneName = this.props.btnDoneName ? this.props.btnDoneName : "実行";
    const btnDoneColor = this.props.btnDoneColor ? this.props.btnDoneColor : "success";
    let btnDone = (
      <Button variant={btnDoneColor} className="btn-common" onClick={() => this.props.done()}>
        {btnDoneName}
      </Button>
    );

    if(this.props.disableBtnDone) btnDone = null;

    return(
      <Modal.Footer className="notice-modal-footer">
        {btnDone}
        <Button variant="secondary" onClick={() => this.props.cancel()}>
          閉じる
        </Button>
      </Modal.Footer>
    )
  }
}

export default ModalNotice;
