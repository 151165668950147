const initialState = {
  loading: true,
  shutterId: undefined,
  list: [],
  saveResult: false,
  resultMsg: "",
  data: {}
}

export default function(state = initialState, action) {
  switch(action.type) {
    case 'CONTACT_LIST':
      return {
        ...state,
        loading: false,
        shutterId: action.shutterId,
        list: action.list
      };
    case 'CONTACT_DETAIL':
      return {
        ...state,
        data: action.data
      };
    case 'CONTACT_SAVE':
      return {
        ...state,
        loading: false,
        saveResult: action.saveResult,
        resultMsg: action.resultMsg,
        data: action.data
      };
    case 'CLEAR_CONTACT_DATA':
      return {
        ...state,
        loading: false,
        shutterId: undefined,
        list: [],
        saveResult: false,
        resultMsg: "",
        data: {}
      };
    default:
      return state;
  }
}
