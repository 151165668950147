import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/contactAction';
import { Switch, Route, Redirect } from 'react-router-dom';
import ContactList from './ContactList';
import ContactCreate from './ContactCreate';
import ContactEdit from './ContactEdit';
import ContactInfo from './ContactInfo';

class Contact extends Component {
  render() {
    return (
      <div className="card main-contents-layout">
        <div className="card-header card-header-common">
          連絡先
        </div>

        <Switch>
          <Route exact path="/contact/list/:shutterId" component={ ContactList }/>
          <Route exact path="/contact/create/:shutterId" component={ ContactCreate }/>
          <Route exact path="/contact/edit/:shutterId/:contactId" component={ ContactEdit }/>
          <Route exact path="/contact/info/:shutterId/:contactId" component={ ContactInfo }/>
          <Redirect to="/main_map"/>
        </Switch>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
