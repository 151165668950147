import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Popover, Overlay, Row, Col, Button } from 'react-bootstrap';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/mainMapAction';
import NoImage from 'images/noimage.png'
import ContentFacilityList from './ContentFacilityList';
import ContentSensorIcon from './ContentSensorIcon';
import Shutter from 'components/shutters/Shutter';
import Setting from 'components/settings/Setting';
import Report from 'components/reports/Report';
import Maintenance from 'components/maintenances/Maintenance';
import Inspection from 'components/inspections/Inspection';
import Finder from 'components/finders/Finder';
import Contact from 'components/contacts/Contact';
import * as module from 'modules/moduleMainMap.js';
import * as cookies from 'modules/cookies.js';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

class Contents extends Component {
  constructor(props) {
    super(props);

    this.initWidth = 600;
    this.initHeight = 700;
    this.imageRatio = this.initWidth / this.initHeight;
    this.spaceW = 8;
    this.spaceBottom = 23;
    this.spaceHTab = 80
    this.initCircle = 15;
    this.offsetImageTop   = 94; //PC画面
    this.offsetImageTop2  = 195; //スマホ画面
    this.offsetImageLeft  = 72; //PC画面
    this.offsetImageLeft2 = 5;  //スマホ画面
    this.marginLeft = 8;
    this.offsetBalloon = 3;
    this.factoryId = Number(this.props.factoryId);
    this.target = {};
    this.touchFlg = false;
    this.touchId = -1;

    this.state = {
      bai: 1,
      layoutW: window.innerWidth,
      imageW: this.initWidth,
      imageH: this.initHeight,
      circleSize: 15,
      halfCircleSize: 7,
      fontSize: 8,
      show: {},
      photoData: undefined,
      tabKey: "main_data"
    };

    window.addEventListener('resize', () => {
      this.onResize();
    });
  }

  componentDidMount() {
    this.getFactoryMapData();
    this.getFactoryMapImage();
  }

  // コンポーネントの更新時に呼び出される
  componentDidUpdate(){
    const newShutters = this.props.main_map.newShutters;

    if(newShutters.length){
      newShutters.splice(0);
    }
  }

  render(){
    let html = ""
    const sw = (
      <Switch>
        {/* 設備情報 */}
        <Route path="/shutter/:shutterId" component={ Shutter }/>
        {/* 設備情報 */}
        <Route path="/setting/:shutterId" component={ Setting }/>
        {/* データ台帳 */}
        <Route path="/report/info/:shutterId" component={ Report }/>
        {/* メンテナンス */}
        <Route path="/maintenance/(list|create|edit|info)/:shutterId" component={ Maintenance }/>
        {/* 資料 */}
        <Route path="/finder/(list|create|edit|info)/:shutterId" component={ Finder }/>
        {/* 連絡先 */}
        <Route path="/contact/(list|create|edit|info)/:shutterId" component={ Contact }/>
        {/* 設備点検 */}
        <Route path="/inspection/(list|create|edit|download)/:shutterId" component={ Inspection }/>

        <Route path="/main_map" component={ ContentFacilityList } />
        <Redirect to="/main_map"/>
      </Switch>
    );

    if(this.state.layoutW >= 576){
      html = (
        <Row noGutters={true} className="main-map-content">
          <Col sm={5} className="main-map-content-image" id={`main-map-image-area-${this.factoryId}`}>
            {this.formMapImage()}
            {this.formPrecedent()}
            {this.formLoading()}
            {this.plotDeviceIcon()}
          </Col>

          <Col sm={7} className="main-map-data-group"
               id="main-map-data-group">{sw}</Col>
        </Row>
      )
    }else{
      var menuTitle = this.getMainMenuTitle()

      html = (
        <Tabs defaultActiveKey="main_data" 
              onSelect={(tabKey) => this.selectTabs(tabKey)}>
          <Tab eventKey="main_data" title={menuTitle}
               className="main-map-data-group" id="main-map-data-group">
            {sw}
          </Tab>
          <Tab eventKey="main_map" title="マップ"
               className="main-map-content" id="main-map-content">
            <div className="main-map-content-image" 
               id={`main-map-image-area-${this.factoryId}`}>
              {this.formMapImage()}
              {this.formPrecedent()}
              {this.formLoading()}
              {this.plotDeviceIcon()}
            </div>
          </Tab>
        </Tabs>
      )
    }

    return html;
  }

  getFactoryMapData(){
    // 図面データ取得ずみの場合、ここではMapDataを取得しない
    if(this.props.main_map.flowMapImage) return

    const callback = (data) => {
      this.onResize();
    }

    this.props.getFactoryMapData(this.factoryId, callback);
  }

  getFactoryMapImage(){
    const callback = () => {
      const obj = this
      setTimeout(
        function () {
          obj.onResize();
        }, 10
      );
    }

    this.props.getFactoryMapImage(this.factoryId, callback);
  }

  formMapImage(){
    let img;
    const mapImage = this.props.main_map.flowMapImage;

    if(mapImage !== undefined){
      const url = (window.URL || window.webkitURL).createObjectURL(mapImage)
      img = (
        <>
          <img src={url} id="main-map-file-img" alt="MapData"
               style={{maxWidth:`${this.state.imageW}px`, maxHeight:`${this.state.imageH}px`}}
               />
          <div className="dummy-event-map"
               style={{width:`${this.state.imageW}px`, height:`${this.state.imageH}px`}}
               onClick={(e) => this.onClickClearPopover()}
               onPointerDown={(e) => this.onTouchMarker(this.touchId)}
               onPointerUp={(e) => this.onTouchUpMarker(e)}
               onPointerMove={(e) => this.onTouchMoveMarker(e)}>
          </div>
        </>
      );
    }

    return img
  }

  formPrecedent(){
    return(
      <div className="row no-gutters precedent-group">
        <div className="col-auto precedent-break">
          凡例：
        </div>

        {/* 開 */}
        <div className="col-auto">
          <div className="flowmap-shutter-icon flowmap-st-open precedent-icon"></div>
        </div>
        <div className="col-auto precedent-break">
          開
        </div>

        {/* 閉 */}
        <div className="col-auto">
          <div className="flowmap-shutter-icon flowmap-st-close precedent-icon"></div>
        </div>
        <div className="col-auto precedent-break">
          閉
        </div>

        {/* 閉 */}
        <div className="col-auto">
          <div className="flowmap-shutter-icon flowmap-st-other precedent-icon"></div>
        </div>
        <div className="col-auto precedent-break">
          動作中
        </div>

        {/* データ無 */}
        <div className="col-auto">
          <div className="flowmap-shutter-icon flowmap-st-none precedent-icon"></div>
        </div>
        <div className="col-auto precedent-break">
          データ無し
        </div>

        <div className="col-auto">
          A:自動 M:手動
        </div>
      </div>
    );
  }

  formLoading(){
    let form;
    if(this.props.main_map.loading){
      form=(
        <div className="main-map-loading">
          <div className="loading-back"></div>
          <div className="loading-text d-flex align-items-center loading">
            <div className="spinner-border text-success" role="status"></div>
            <strong className="loading-title">{this.props.common.loadingMsg}</strong>
          </div>
        </div>
      );
    }

    return form;
  }

  plotDeviceIcon(){
    const forms = [];
    this.addPlotShutterIcon(forms);
    this.addPlotSensorIcon(forms);

    return forms;
  }

  addPlotShutterIcon(forms){
    const shutters = this.props.main_map.shutters;

    shutters.forEach(data => {
      if(data.figx === -1 || data.figy === -1){ return; }

      const mainKey = `flowmap-shutter-${data.id}`;
      const driveMode = module.shutterMarkDriveMode(data);
      const driveStatus = module.shutterMarkDriveStatus(data);
      const positionStyle = this.positionStyle(data);
      const circleStyle = this.circleStyle(data);

      forms.push(
        <div className="flowmap-shutter-link" key={mainKey} id={mainKey}
             data-placement="top" title={data.name}
             tabIndex="0" data-toggle="popover" data-trigger="focus"
             style={positionStyle}
             onClick={(e) => this.onClickShowPopover(data.id)}
             onPointerDown={(e) => this.onTouchMarker(data.id)}
             onPointerUp={(e) => this.onTouchUpMarker(e)}
             onPointerMove={(e) => this.onTouchMoveMarker(e)}
             ref={(target) => { this.target[data.id] = target; }}>
          <div className={`flowmap-shutter-icon ${driveStatus}`}
               key={`${mainKey}-icon`} id={`${mainKey}-icon`}
               style={circleStyle}>
               {driveMode}</div>

          <input type="hidden" id={`${mainKey}-left`} key={`${mainKey}-left`}
                 value={data.figx}/>
          <input type="hidden" id={`${mainKey}-top`} key={`${mainKey}-top`}
                 value={data.figy}/>
        </div>
      );

      forms.push(this.formPopoverInfo(data));
    })
  }

  addPlotSensorIcon(forms){
    const analogs = this.props.main_map.analogs;

    analogs.forEach(data => {
      if(data.figx === -1 || data.figy === -1){ return; }

      const key = `${data.sensor_id}-${data.data_no}`
      forms.push(
        <ContentSensorIcon key={key} mainKey={key}
                           sensorId={data.sensor_id}
                           imsi={data.imsi}
                           dataNo={data.data_no}
                           dataName={data.data_name}
                           value={data.value}
                           unit={data.unit}
                           figx={data.figx}
                           figy={data.figy}
                           bai={this.state.bai}
                           marginLeft={this.marginLeft}/>
      );
    });
  }

  formPopoverInfo(data){
    let contents = null;
    let hClass = null;
    if(this.props.main_map.positionMode){
      hClass = "h-pos-mode";
      contents = this.popoverPositionMode(data);
    }else{
      contents = this.popoverNomal(data);
    }

    let placement = "top";
    if(data.figy < 200) {placement = "bottom"}

    return(
      <Overlay placement={placement} key={`popover-key-${data.id}`}
        show={this.props.main_map.showPopover[data.id]}
        target={ this.target[data.id] }>
        <Popover id={`popover-${data.id}`}>
          <Popover.Title as="h3" className={hClass}>{data.name}</Popover.Title>
          {contents}
        </Popover>

      </Overlay>
    );
  }

  popoverPositionMode(data){
    if(this.props.main_map.mapPosLoading){
      return(
        <div className="popover-pos-mode-content">
          <div className="loading-text d-flex align-items-center">
            <div className="spinner-border text-success" role="status"></div>
            <div className="loading-title pl-1">MAP位置を変更しています...</div>
          </div>
        </div>
      );
    }else{
      return(
        <div className="popover-pos-mode-content">
          MAP上をクリックしてください

          <Row noGutters={true} className="mt-2">
            <Col xs={"auto"} className="pr-1">
              <Button variant="success" className="btn-common"
                      onClick={() => {this.saveMapPosition(data)}}>
                ここに登録
              </Button>
            </Col>
            <Col xs={"auto"}>
              <Button variant="secondary" className="btn-common"
                      onClick={() => module.cancelMapPosiotion(data.id, this.props, true)}>
                キャンセル
              </Button>
            </Col>
          </Row>
        </div>
      );
    }
  }

  popoverNomal(data){
    let driveCount = "-";

    if(data.device_type >= 90){
      driveCount = data.total_drive_count
    }else{
      driveCount = data.drive_count
    }

    let size = "-";
    if(data.width !== "-" || data.height !== "-"){
      size = `${data.width} x ${data.height}`
    }

    // 発表用
    let productType = data.product_type;
    if(productType === "RW01")  productType = "-";

    return(
      <div className="popover-content">
        <div className="row no-gutters">
          <div className="col-auto menu-link"
               onClick={(e) => this.onClickMenu(data.id, "report") }>
            データ帳票
          </div>

          <div className="col-auto menu-link ml-2"
               onClick={(e) => this.onClickMenu(data.id, "maintenance") }>
            メンテナンス
          </div>

          <div className="col-auto menu-link ml-2"
               onClick={(e) => this.onClickMenu(data.id, "contact") }>
            連絡先
          </div>
        </div>

        <div className="row no-gutters">
          <div className="col-5">
            <div className="popover-content-photo">
               { this.formPhotoData() }
            </div>
            <div className="popover-detail-link menu-link"
                 onClick={(e) => this.onClickMenu(data.id, "shutter") }>
              設備情報
            </div>
          </div>
          <div className="popover-content-data col-7">
            <div>
              型式：{productType}
            </div>
            <div>
              サイズ：{ size }
            </div>
            <div>
              製造番号：{ data.product_no }
            </div>
            <div>
              開閉回数：{driveCount}
            </div>
          </div>
        </div>
      </div>
    )
  }

  formPhotoData(){
    let img;
    const photoData = this.props.main_map.photoData;

    if(photoData !== undefined){
      const url = (window.URL || window.webkitURL).createObjectURL(photoData);
      img = (<img src={url} alt="ShutterPhoto"/>);
    }else{
      img = (<img src={NoImage} alt="NoImage"/>);
    }

    return img;
  }

  /*
    アイコン位置設定モードの場合、平面図上の施設情報バルーンを非表示にする
  */
  onTouchMarker(shutterId){
    this.touchFlg = true;

    if(this.props.main_map.positionMode){
      this.touchId = cookies.getShutterId();
      this.props.showShutterPopover(this.touchId, false);
    }
  }

  onTouchMoveMarker(e){
    if(!this.props.main_map.positionMode || !this.touchFlg) {
      return;
    }

    const id = this.touchId;
    let x = e.pageX + ((this.initCircle - this.state.circleSize) / 2);
    let y = e.pageY - (this.state.circleSize - this.offsetBalloon);

    if(this.state.layoutW < 576){
      x -= this.offsetImageLeft2;
      y -= this.offsetImageTop2;
    }else{
      x -= this.offsetImageLeft;
      y -= this.offsetImageTop;
    }

    const pos = this.props.main_map.ePos[id]

    // 同じ座標の場合はstateの更新をスキップ
    if(pos && pos.x === x && pos.y === y) return;

    this.props.moveMapPosition(id, x, y);
  }

  onTouchUpMarker(e){
    this.touchFlg = false;
    if(!this.props.main_map.positionMode) {
      this.touchId = -1
      return;
    }

    let x = e.pageX + ((this.initCircle - this.state.circleSize) / 2);
    let y = e.pageY - (this.state.circleSize - this.offsetBalloon);

    if(this.state.layoutW < 576){
      x -= this.offsetImageLeft2;
      y -= this.offsetImageTop2;
    }else{
      x -= this.offsetImageLeft;
      y -= this.offsetImageTop;
    }

    this.props.moveMapPosition(this.touchId, x, y);

    const obj = this;
    setTimeout(
      () => {obj.props.showShutterPopover(obj.touchId, true)}, 160
    );
  }

  onResize(){
    const areaObj = document.getElementById(`main-map-image-area-${this.factoryId}`);
    const mapObj = document.getElementById('main-map-file-img');

    if(mapObj != null && areaObj != null){
      let areaW = areaObj.clientWidth;
      let areaH = areaObj.clientHeight;

      if(window.innerWidth < 576){
        // スマホ画面の場合
        const mObj = document.getElementById('main-content');
        //w方向で左右 5pxの空白になるように調整
        areaW = mObj.clientWidth - this.spaceW + 6;
        areaH = mObj.clientHeight - this.spaceHTab;
      }

      let imageW = areaW - this.spaceW;
      let imageH = imageW / this.imageRatio;

      if(imageH > areaH - this.spaceBottom) {
        imageH = areaH - this.spaceBottom;
        imageW = imageH * this.imageRatio;
      }

      const bai = imageW / this.initWidth;
      const circleSize = this.initCircle * bai;
      const halfCircleSize = circleSize / 2;
      const fontSize = circleSize * 0.65;

      this.setState({
        bai: bai,
        circleSize: circleSize,
        halfCircleSize: halfCircleSize,
        fontSize: fontSize,
        layoutW: window.innerWidth,
        imageW: imageW,
        imageH: imageH
      });
    }else{
      this.setState({ layoutW: window.innerWidth });
    }
  }

  onClickShowPopover(shutterId){
    if(this.props.main_map.positionMode) return;

    const showFlg = !this.props.main_map.showPopover[shutterId];

    if(showFlg){
      this.props.getShutterPhoto(shutterId);
    }

    this.props.showShutterPopover(shutterId, showFlg);

    module.changedShutter(shutterId, this.props);
    module.scrollShutterList(shutterId);
  }

  onClickClearPopover(){
    this.props.clearShutterPopover();
  }

  onClickMenu(shutterId, menu){
    module.showMenu(shutterId, menu, this.props)
  }

  selectTabs(tabKey){
    if(tabKey === "main_data"){
      this.onClickClearPopover()
    }else if(tabKey === "main_map"){
      const shutterId = cookies.getShutterId();
      if(shutterId > 0){
        this.onClickClearPopover()
        if(!this.props.main_map.positionMode){
          this.props.getShutterPhoto(shutterId);
        }

        const obj = this;
        setTimeout(
          () => { obj.props.showShutterPopover(shutterId, true)}, 200
        );
      }
    }

    this.setState({ tabKey: tabKey });
  }

  /*
    MAP座標位置登録
  */
  saveMapPosition(data){
    const shutterId = data.id;
    const pos = this.props.main_map.ePos[shutterId]
    if(pos === undefined) {
      // 位置の変更がない場合、編集モードを抜ける
      this.props.changeMapPositionMode(false);
      return;
    }

    let figx = (pos.x - this.marginLeft + this.state.halfCircleSize) / this.state.bai;
    let figy = (pos.y + this.state.halfCircleSize + this.offsetBalloon) / this.state.bai;

    if(this.state.layoutW < 576){figx += 3;}
    
    this.props.saveMapPosition(shutterId, Math.round(figx), Math.round(figy));
  }

  factoryLinkStyle(data){
    const left = data.start_x * this.state.bai + this.marginLeft;
    const top = data.start_y * this.state.bai;

    return { left: `${left}px`, top: `${top}px` };
  }

  factoryAreaStyle(data){
    const width = (data.end_x - data.start_x) * this.state.bai;
    const height = (data.end_y - data.start_y) * this.state.bai;;

    return { width: `${width}px`, height: `${height}px` };
  }

  positionStyle(item){
    let left = 0;
    let top = 0;
    let zIndex = 3;
    const pos = this.props.main_map.ePos[item.id]
    if(pos){
      left = pos.x;
      top = pos.y;
      zIndex = 5;
    }else {
      left = item.figx * this.state.bai +
             this.marginLeft - this.state.halfCircleSize;
      top = item.figy * this.state.bai -
             this.state.halfCircleSize - this.offsetBalloon;

      if(this.state.layoutW < 576){left -= 3;}
    }

    return {
      width: `${this.state.circleSize}px`,
      height: `${this.state.circleSize + this.offsetBalloon * 2}px`,
      left: `${left}px`, top: `${top}px`, zIndex: zIndex
    };
  }

  circleStyle(item){
    return {
      width: `${this.state.circleSize}px`,
      height: `${this.state.circleSize}px`,
      fontSize: `${this.state.fontSize}px`
    };
  }
  
  getMainMenuTitle(){
    const menuKey = cookies.get("menuKey")

    switch (menuKey){
      case 'main_maps':
        return "リスト"
      case 'data_maps':
        return "データ一覧"
      case 'shutter':
        return "状態表示"
      case 'setting':
        return "遠隔設定"
      case 'report':
        return "日報"
      case 'maintenance':
        return "ﾒﾝﾃﾅﾝｽ"
      case 'inspection':
        return "設備点検"
      case 'contact':
        return "連絡先"
      default:
        return "リスト"
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contents));
