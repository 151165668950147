import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/maintenanceAction';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { formatDateTime } from 'modules/utils';

class MaintenanceList extends Component {

  componentDidMount() {
    const shutterId = this.props.match.params.shutterId;
    this.props.getMaintenanceList(shutterId)
  }

  render(){
    this.shutterId = this.props.match.params.shutterId;

    return(
      <div className="card-text card-body-common">
        <Row noGutters={true} className="content-header">
          <Col xs="auto" className="d-flex align-items-end">
            メンテナンス一覧
          </Col>
          <Col></Col>
          <Col xs="auto">
            <Link to={`/maintenance/create/${this.shutterId}`}
                  className="btn btn-common btn-success">
              新規作成
            </Link>
          </Col>
        </Row>

        {this.formContents()}
      </div>
    );
  }

  formContents(){
    const list = this.props.maintenance.list;
    if(list.length){
      return(
        <table className="table table-hover table-common maintenance-list-table">
          <tbody>
            {
              list.map(data => {
                return(
                  <tr key={data.maintenance_id}
                      onClick={() => this.onClickItem(data.maintenance_id)}>
                    <td>
                      <Row noGutters={true} className="created-date-row">
                        <Col xs="auto">
                          作成日 {formatDateTime(data.date, "yyyy年mm月dd日")}
                        </Col>
                        <Col xs="auto" className="person-col">
                          {data.created_user}
                        </Col>
                      </Row>

                      <Row noGutters={true} className="data-group-row">
                        <Col>
                          {data.title}
                        </Col>
                        <Col sm="auto" className="list-item-file-count">
                          ファイル数: {data.files_count}
                        </Col>
                      </Row>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      )
    }else{
      return(
        <div className="content-body">
          データがありません。新規作成を行ってください
        </div>
      )
    }
  }

  onClickItem(maintenanceId){
    this.props.history.push(`/maintenance/edit/${this.shutterId}/${maintenanceId}`);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceList);
