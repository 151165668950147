import * as cookies from 'modules/cookies.js';

export function findFactoryId(props){
  const factoryId = Number(props.factoryId);

  if(factoryId > 0){
    return factoryId;
  }else{
    const factories = props.common.menuFlatsheets;

    if(factories.length){
      return factories[0].factory_id
    }else{
      return 0
    }
  }
}

export function factoryAreaClassName(data){
  if(data.alert_flg){
    return "flowmap-factory-alert";
  }else{
    return "flowmap-factory-mark";
  }
}

export function shutterMarkDriveMode(item){
  if(item.device_type >= 90 && item.device_type <= 99){
    return "";
  }

  if(item["auto_mode"]){
    return "A";
  }else{
    return "M";
  }
}

export function shutterMarkDriveStatus(item){
  if(item.device_type >= 90 && item.device_type <= 99){
    return "flowmap-st-none";
  }

  if(item.invalid_data) return "flowmap-st-none";
  if(item.alert_flg) return "flowmap-st-alert";

  let status = ""

  switch(item["drive_status"]){
    case "open":
      status = "flowmap-st-open"
      break;
    case "init":
    case "opening":
    case "stop":
    case "closing":
    case "eco":
      status = "flowmap-st-other"
      break;
    case "close":
      status = "flowmap-st-close"
      break;
    default:
      status = "flowmap-st-none"
      break;
  }

  return status
}

export function scrollShutterList(selectedId){
  setTimeout(() => {
    const list = document.getElementById("main-map-data-group");
    const row = document.getElementById(`shutter-id-${selectedId}`);

    if(row !== null){
      list.scrollTop = row.offsetTop - 85;
    }
  }, 10)
}

/*
メニュー切り替え
*/
export function showMenu(id, menu, props){
  let path = null;
  const location = props.location.pathname;

  switch(menu) {
    case 'main_maps':
      path = `/main_map`;
      break;
    case 'data_maps':
      path = `/top`;
      break;
    case 'shutter':
      path = `/shutter/${id}`;
      break;
    case 'setting':
      path = `/setting/${id}`;
      break;
    case 'report':
      path = `/report/info/${id}`;
      break;
    case 'maintenance':
      path = `/maintenance/list/`;
      if(location.includes(path)) props.getMaintenanceList(id);
      path += id;
      break;
    case 'inspection':
      path = `/inspection/list/`;
      if(location.includes(path)) props.getInspectionList(id);
      path += id;
      break;
    case 'contact':
      path = `/contact/list/`;
      if(location.includes(path)) props.getContactList(id);
      path += id;
      break;
    default: break;
  }

  if(path === null){ return }

  cookies.set("menuKey", menu);
  cancelMapPosiotion(-1, props);
  props.history.push(path);
}

export function changedShutter(id, props){
  let path = null;
  const location = props.location.pathname;

  if(location.includes("/shutter/")) {
    path = `/shutter/${id}`;
    props.getShutterStatus(id);
  }else if(location.includes("/setting/")) {
    path = `/setting/${id}`;
    props.getRemoteSettingData(id);
  }else if(location.includes("/report/")) {
    path = `/report/info/${id}`;
    props.clearReporCacheData(id);
  }else if(location.includes("/maintenance/")) {
    path = `/maintenance/list/${id}`;
    props.getMaintenanceList(id);
  }else if(location.includes("/inspection/")) {
    path = `/inspection/list/${id}`;
    props.getInspectionList(id);
  }else if(location.includes("/contact/")) {
    path = `/contact/list/${id}`;
    props.getContactList(id);
  }

  if(path === null){ return }

  props.history.push(path);
}

export function cancelMapPosiotion(id, props, reView = false){
  if(props.main_map.positionMode){
    props.clearShutterPopover();

    setTimeout(
      () => {
        props.changeMapPositionMode(false);
        if(reView) props.showShutterPopover(id, true);
      }, 200
    );
  }
}
