import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/shutterAction';
import { Button, Modal, Toast } from 'react-bootstrap'
import { getShutterId } from 'modules/cookies.js';
import ModalBodyPartsList from './ModalBodyPartsList';
import NoImage from 'images/noimage.png'
import CameraImage from 'images/camera.png'

class ContentProductInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoData: null,
      showModal: false,
      showToast: false
    };
  }

  /*
    render後に呼ばれる
  */
  componentDidMount() {
    if(this.props.shutter.hasPicture){
      this.getPhotoData(false);
    }
  }

  /*
  シャッター写真データの取得
  */
  getPhotoData(showToastFlg){
    const callback = (blob) => {
      const blob2 = new Blob([ blob ], { type: "image/jpeg" });
      this.setState({photoData: blob2});

      if(showToastFlg){
        this.setState({ showToast: true })
      }
    }

    const shutterId = getShutterId();
    this.props.getShutterPhoto(shutterId, callback);
  }

  formPhoto(){
    let img;
    if(this.state.photoData !== null){
      const url = (window.URL || window.webkitURL).createObjectURL(this.state.photoData)
      img = (
        <div className="shutter-picture">
          <img className="shutter-picture-img" src={url} alt="ShutterPhoto"/>

          <form className="save-shutter-photo-form" >
            <label>
              <div className="save-photo-icon" title="シャッター写真を更新します">
                <img src={CameraImage} className="save-camera-image"
                     width="60%" alt="CameraImage"/>
              </div>
              <input className="select-photo-file" type="file"
                     accept="image/*,.png,.jpg,.jpeg,.gif" onChange={(e) => this.saveShutterPhoto(e)}/>
            </label>
          </form>
        </div>
      );
    }else{
      img = (
        <form className="save-new-shutter-photo-form" >
          <label>
            <img src={NoImage} className="save-camera-image"
                 width="100%" alt="NoImage" title="シャッター写真を登録します"/>

            <input className="select-photo-file" type="file"
                   accept="image/*,.png,.jpg,.jpeg,.gif" onChange={(e) => this.saveShutterPhoto(e)}/>
          </label>
        </form>
      );
    }

    return img;
  }

  showModal(){
    this.setState({ showModal: true });
  }

  hideModal(){
    this.setState({ showModal: false });
  }

  saveShutterPhoto(e){
    if(!e.target.files) return;

    const fileData: File = e.target.files[0]
    const callback = (data) => { this.getPhotoData(true); }
    const shutterId = getShutterId();
    this.props.saveShutterPhoto(shutterId, fileData, callback);
  }

  render() {
    const props = this.props.shutter;
    const formImg = this.formPhoto();
    const size = this.shutterSize(props);

    return (
      <div className="row no-gutters shutter-info-content-group">
        <div className="col-md-5" style={{textAlign: "center"}}>
          {formImg}
          {this.formToast()}
        </div>

        <div className="col-md-7">
          <div className="row no-gutters shutter-product-info">
            <div className="col-4 text-navy">
              <div>製造番号</div>
              <div>サイズ</div>
              <div>型式</div>
              <div>動作回数</div>
              <div>設置時期</div>
            </div>

            <div className="col-8">
              <div>{ props.productNo }</div>
              <div>{ size }</div>
              <div>{ props.monbanType }</div>
              <div>{ props.totalDriveCount } 回</div>
              <div>{ props.constructionDate }</div>
            </div>
          </div>

          {this.partsList(props)}
        </div>
      </div>
    );
  }

  shutterSize(props){
    let size = "";
    if(props.width !== null) size += `W${props.width}`
    if(props.height !== null) {
      if(size !== "") size += " x "
      size += `H${props.height}`
    }
    if(size === "") size = "-";
    return size;
  }

  formToast(){
    return (
      <Toast onClose={() => this.setState({ showToast: false })}
             style={{marginTop: "4px"}}
             show={this.state.showToast} delay={3000} autohide>
        <Toast.Body className="update-picture-toast">写真を更新しました</Toast.Body>
      </Toast>
    );
  }

  partsList(props){
    if(props.parts.length === 0) return null;

    return(
      <div className="shutter-product-info s-parts-list-btn">
        <Button variant="success" size="sm"
                onClick={() => this.showModal()}>
          パーツリスト
        </Button>
        <Modal show={this.state.showModal}
               onHide={() => this.hideModal()}
               aria-labelledby="contained-modal-title-vcenter"
               centered>
          <Modal.Header closeButton onClick={() => this.hideModal()}>
            <Modal.Title>パーツリスト</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <ModalBodyPartsList />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.hideModal()}>
              閉じる
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentProductInfo);
