import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/finderAction';
import { Switch, Route, Redirect } from 'react-router-dom';
import FinderList from './FinderList';
//import FinderCreate from './FinderCreate';
//import FinderInfo from './FinderInfo';
//import FinderEdit from './FinderEdit';


class Finder extends Component {
  render() {
    let name = "";
    const shutterId = Number(this.props.match.params.shutterId);
    const shutters = this.props.main_map.shutters;

    if(shutters.length){
      const shutter = shutters.find(item => item.id === shutterId);
      if(shutter) name = shutter.name
    }

    return (
      <div className="card main-contents-layout">
        <div className="card-header card-header-common">
          資料　{name}
        </div>

        <Switch>
          <Route exact path="/finder/list/:shutterId" component={ FinderList }/>
          <Route exact path="/finder/create/:shutterId" component={ FinderList }/>
          <Route exact path="/finder/edit/:shutterId/:finderId" component={ FinderList }/>
          <Route exact path="/finder/info/:shutterId/:finderId" component={ FinderList }/>
          <Redirect to="/main_map"/>
        </Switch>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Finder);
