import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/inspectionAction';
import { Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import * as utils from 'modules/utils';
import * as cookies from 'modules/cookies.js';
import CameraImage from 'images/noimage.png'
import ModalNotice from 'components/common/ModalNotice';
import ModalShowingPhoto from './ModalShowingPhoto';

class Contents extends Component {
  constructor(props) {
    super(props);
    this.shutterId = this.props.shutterId;
    this.today = utils.getTodayString("yyyy-mm-dd");
    this.userName = cookies.get("userName");

    this.state = {
      showModal: false,
      showPhotoModal: false,
      modalData: {}
    };

    this.sectionName = {
      1: "【外観チェック】",
      2: "【動作チェック】",
      3: "【制御盤関連】",
      4: "【センサー・スイッチ類】",
      8: "【その他】"
    }
    this.otherSections = [8, 9]
    this.isSmartWidth = utils.isSmartWidth(850);
    this.isToucheMobile = utils.isToucheMobile();
  }

  render() {
    const state = this.props.state
    if(state.items.length === 0){
      return(
        <div className="loading-text d-flex align-items-center loading">
          <div className="spinner-border text-success" role="status"></div>
          <div className="loading-title">データを取得しています。しばらくお待ちください...</div>
        </div>
      );
    }

    const date = state.info.inspectionDate || this.today;
    const mainCss = this.isSmartWidth ? "inspection-body-sm" : "inspection-body";

    return(
      <div className={mainCss}>
        <div className="inspection-header">
          <Row noGutters={true} className="base-info">
            <Col sm="12" md="auto">
              <Form.Group as={Row} noGutters={true} controlId="date">
                <Form.Label column xs="auto">点検日</Form.Label>
                <Col xs="auto" className="pr-3">
                  <Form.Control type="date" className={state.formDateAlert}
                                defaultValue={date}/>
                </Col>
              </Form.Group>
            </Col>
            <Col sm="12" md="auto">
              <Row noGutters={true} className="form-group title-cheker">
                <Col sm="auto" md="auto" className="title-label">点検者</Col>
                <Col sm="auto" md="auto">{utils.sliceText(this.userName, 15)}</Col>
              </Row>
            </Col>

            <Col md="12">
              <Row noGutters={true} className="form-group">
                <Col xs="auto" className="form-label">設備情報</Col>
                <Col>
                  <Row noGutters={true} >
                    <Col xs="auto" className="ml-3">
                      <span className="subtitle-label">製造番号</span>
                      {state.info.productNo}</Col>
                    <Col xs="auto" className="ml-3">
                      <span className="subtitle-label">型式</span>
                      {state.info.productType}</Col>
                    <Col xs="auto" className="ml-3">
                      <span className="subtitle-label">寸法</span>
                      {state.info.size}</Col>
                    <Col xs="auto" className="ml-3">
                      <span className="subtitle-label">製造年月</span>
                      {state.info.productionDate}</Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col md="12">
              <Form.Group as={Row} noGutters={true} controlId="company">
                <Form.Label column xs="auto">お客様名</Form.Label>
                <Col xs="9" sm="8" md="6" lg="5" xl="4">
                  <InputGroup>
                    <Form.Control type="text" defaultValue={state.info.company}/>
                    <InputGroup.Text className="input-unit">様</InputGroup.Text>
                  </InputGroup>
                </Col>
              </Form.Group>
            </Col>
            <Col md="12">
              <Form.Group as={Row} noGutters={true} controlId="customerName">
                <Form.Label column xs="auto">担当者名</Form.Label>
                <Col xs="9" sm="8" md="6" lg="5" xl="4">
                  <InputGroup>
                    <Form.Control type="text" defaultValue={state.info.customerName}/>
                    <InputGroup.Text className="input-unit">様</InputGroup.Text>
                  </InputGroup>
                </Col>
              </Form.Group>
            </Col>

            <Col md="10" lg="4" xl="3">
              <Form.Group as={Row} noGutters={true} controlId="controllerVer">
                <Form.Label column xs="auto">制御盤Ver.</Form.Label>
                <Col xs="7" >
                  <Form.Control type="text" defaultValue={state.info.controllerVer}/>
                </Col>
              </Form.Group>
            </Col>
            <Col md="10" lg="4" xl="3">
              <Form.Group as={Row} noGutters={true} controlId="count">
                <Form.Label column xs="auto">カウンタ数</Form.Label>
                <Col xs="7" >
                  <InputGroup>
                    <Form.Control type="number" min="0" defaultValue={state.info.count}/>
                    <InputGroup.Text className="input-unit">回</InputGroup.Text>
                  </InputGroup>
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </div>

        { this.formInspectionItems(state) }
        { this.formInspectionDetail(state) }
        { this.formModalNotice() }
        { this.formModalPhoto() }
      </div>
    );
  }

  formInspectionItems(state){
    let sectionNo = 0;
    const touchCss = this.isToucheMobile ? "touch-mobile" : "";

    return(
      <div className="inspection-contents">
        <Row noGutters={true} className="contents-header">
          <Col xs="2">点検部分及び内容</Col>
          <Col xs="3">点検ポイント</Col>
          <Col xs="3">判定</Col>
          <Col>詳細メモ</Col>
        </Row>

        {
          state.items.map(data => {
            let category = null;
            if(sectionNo !== data.section_no){
              sectionNo = data.section_no;
              category = (
                <Row noGutters={true} className="contents-category">
                  <Col className="">{this.sectionName[sectionNo]}</Col>
                </Row>
              )
            }

            const status = state.status[data.item_no];
            const statusBtn1 = status === 1 ? "success" : "outline-success";
            const statusBtn2 = status === 2 ? "warning" : "outline-warning";
            const statusBtn3 = status === 3 ? "danger" : "outline-danger";

            const color = state.charColor[data.item_no];
            const colorBtn1 = color === "red" ? "danger" : "outline-danger";
            const colorBtn2 = color === "blue" ? "primary" : "outline-primary";

            const ritem = (this.otherSections.indexOf(sectionNo) === -1) ? (
              <Row noGutters={true} className="contents-item">
                <Col xs="2" className="check-title">{data.title}</Col>
                <Col xs="3" className="">{data.check_point}</Col>
                <Col xs="3" className={`check-status ${touchCss}`}>
                  <Button variant={statusBtn1} className="btn-common"
                          onClick={() => this.onClickStatus(state, data.item_no, 1)}>
                    良　好
                  </Button>
                  <Button variant={statusBtn2} className="btn-common"
                          onClick={() => this.onClickStatus(state, data.item_no, 2)}>
                    要観察
                  </Button>
                  <Button variant={statusBtn3} className="btn-common"
                          onClick={() => this.onClickStatus(state, data.item_no, 3)}>
                    要注意
                  </Button>
                </Col>
                <Col className={touchCss}>
                  <Form.Group className="mb-0" controlId={`memo_${data.item_no}`}>
                    <Form.Control as="textarea" rows={1} type="text"
                                  style={{color:`${color}`}}
                                  defaultValue={data.note}/>
                  </Form.Group>
                  <div className="text-right pt-1">
                    <Button variant={colorBtn1} className="btn-common"
                            onClick={() => this.onClickCharColor(state, data.item_no, "red")}>
                      赤字
                    </Button>
                    <Button variant={colorBtn2} className="btn-common"
                            onClick={() => this.onClickCharColor(state, data.item_no, "blue")}>
                      青字
                    </Button>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row noGutters={true} className="contents-item">
                <Col xs="5" className="">{data.title}</Col>
                <Col xs="7" className={touchCss}>
                  <Form.Group className="mb-0" controlId={`memo_${data.item_no}`}>
                    <Form.Control as="textarea" rows={1} type="text"
                                  style={{color:`${color}`}}
                                  defaultValue={data.note}/>
                  </Form.Group>
                  <div className="text-right pt-1">
                    <Button variant={colorBtn1} className="btn-common"
                            onClick={() => this.onClickCharColor(state, data.item_no, "red")}>
                      赤字
                    </Button>
                    <Button variant={colorBtn2} className="btn-common"
                            onClick={() => this.onClickCharColor(state, data.item_no, "blue")}>
                      青字
                    </Button>
                  </div>
                </Col>
              </Row>
            )

            return(
              <div key={`item_${data.item_no}`}>
                {category}
                {ritem}
              </div>
            )
          })
        }
      </div>
    );
  }

  formInspectionDetail(state){
    const touchCss = this.isToucheMobile ? "touch-mobile" : "";
    const details = [];
    let detailData = {};
    if(state.details && state.details.length) detailData = state.details

    for (let i = 1; i <= state.detailCount; i++) {
      const index = i - 1;
      const itemCharNo = 1000 + i
      const dData = detailData[index]
      const color = state.charColor[itemCharNo];
      const colorBtn1 = color === "red" ? "danger" : "outline-danger";
      const colorBtn2 = color === "blue" ? "primary" : "outline-primary";
      const title = dData ? dData.title : "";
      const note  = dData ? dData.note : "";

      const photoForms = [];
      for (let fileNo = 1; fileNo <= this.props.fileCount; fileNo++) {
        let imgTitle = "";
        let fileId = -1;
        let savedPhotoData = null;

        if(dData && dData.files.length){
          const fData = dData.files.find(d => d.file_no === fileNo);
          if(fData){
            imgTitle = fData.title;
            fileId = fData.file_id;
            savedPhotoData = this.props.inspection.photoData[fileId];
          }
        }

        photoForms.push(
          <Col xs="6" key={`file_${fileNo}`} className={touchCss}>
            { this.formPhoto(state, i, fileNo, fileId, savedPhotoData) }
            <Form.Group className="mb-0 detail-imgtitle"
                        controlId={`detail_imgtitle${fileNo}_${i}`}>
              <Form.Control as="textarea" rows={1} type="text"
                            style={{color:`${color}`}}
                            defaultValue={imgTitle}/>
            </Form.Group>
          </Col>
        )
      }

      details.push(
        <div key={`detail_${i}`}>
          <Row noGutters={true} className="detail-category">
            <Col xs="6" className={touchCss}>
              <Form.Group className="mb-0 detail-title"
                          controlId={`detail_title_${i}`}>
                <Form.Control as="textarea" rows={1} type="text"
                              style={{color:`${color}`}}
                              defaultValue={title}/>
              </Form.Group>
            </Col>
          </Row>

          <Row noGutters={true} className="detail-item">
            <Col xs="6">
              <Row noGutters={true}>
                {photoForms}
              </Row>
            </Col>
            <Col xs="6" className={touchCss}>
              <Form.Group className="mb-0" controlId={`detail_memo_${i}`}>
                <Form.Control as="textarea" rows={5} type="text"
                              style={{color:`${color}`}}
                              defaultValue={note}/>
              </Form.Group>
              <div className="text-right pt-1">
                <Button variant={colorBtn1} className="btn-common"
                        onClick={() => this.onClickCharColor(state, itemCharNo, "red")}>
                  赤字
                </Button>
                <Button variant={colorBtn2} className="btn-common"
                        onClick={() => this.onClickCharColor(state, itemCharNo, "blue")}>
                  青字
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      );
    }

    return(
      <div className="inspection-contents">
        <Row noGutters={true} className="detail-header">
          <Col>点検詳細</Col>
        </Row>

        {details}

        <Row noGutters={true} className="add-detail-area">
          <Col className={touchCss}>
            <Button variant="outline-secondary" className="btn-common"
                    onClick={() => this.onClickAddDetail(state)}>
              詳細欄追加
            </Button>
          </Col>
        </Row>
      </div>
    )
  }

  formPhoto(state, itemNo, fileNo, fileId, savedPhotoData){
    let photoData = null;
    let img;

    // 先にWEB画面で選択した画像があるかチェックして
    // なければ、サーバーから取得した画像を表示するようにする
    if(state.photoData[itemNo] && state.photoData[itemNo][fileNo]){
      const photoInfo = state.photoData[itemNo][fileNo]
      if(photoInfo) photoData = photoInfo["data"]
    }else if(savedPhotoData){
      photoData = (window.URL || window.webkitURL).createObjectURL(savedPhotoData);
    }

    if(photoData){
      img = (<img src={photoData} className="select-photo-image"
                  alt="photoData" title="点検写真を添付できます"
                  onClick={() => this.onClickShowPhotoModal(state, itemNo, fileNo, fileId, photoData)}/>)
    }else{
      img = (
        <label className="mb-0">
          <img src={CameraImage} className="select-photo-image"
               alt="photoData" title="点検写真を添付できます"/>
          <input className="select-photo-file" type="file"
                 accept="image/*,.png,.jpg,.jpeg,.gif"
                 onChange={(e) => this.selectPhotoData(e, state, itemNo, fileNo)}/>
        </label>

      )
    }

    return(
      <div className="select-photo-area">
        {img}
      </div>
    )
  }

  formModalNotice(){
    const data = this.state.modalData;

    return(
      <ModalNotice showModal={this.state.showModal}
                   title={data.title}
                   bodyData={data.bodyData}
                   disableBtnDone={data.disableBtnDone}
                   btnDoneName={data.btnDoneName}
                   btnDoneColor={data.btnDoneColor}
                   btnDoneName2={data.btnDoneName2}
                   btnDoneColor2={data.btnDoneColor2}
                   size={data.size}
                   done={data.doneAction}
                   done2={data.doneAction2}
                   cancel={() => {this.onClickHideModal()}}/>
    )
  }

  formModalPhoto(){
    const data = this.state.modalData;

    return(
      <ModalShowingPhoto showModal={this.state.showPhotoModal}
                         title={data.title}
                         state={data.state}
                         itemNo={data.itemNo}
                         fileNo={data.fileNo}
                         fileId={data.fileId}
                         photoData={data.photoData}
                         updateState={this.props.updateState.bind(this)}
                         cancel={() => {this.onClickHideModal()}}/>
    )
  }

  onClickStatus(state, itemNo, status){
    if(state.status[itemNo] === status){
      state.status[itemNo] = null;
    }else{
      state.status[itemNo] = status;
    }

    this.props.updateState({status: state.status})
  }

  onClickCharColor(state, itemNo, color){
    if(state.charColor[itemNo] === color){
      state.charColor[itemNo] = "";
    }else{
      state.charColor[itemNo] = color;
    }

    this.props.updateState({charColor: state.charColor})
  }

  onClickAddDetail(state){
    this.props.updateState({detailCount: state.detailCount += 1})
  }

  selectPhotoData(e, state, itemNo, fileNo){
    if(e.target.files.length <= 0) return;

    const file = e.target.files[0]
    const reader = new FileReader()
    const photoData = state.photoData;
    const deleteFlg = state.deleteFlg;

    reader.onload = (e) => {
      if(photoData[itemNo] === undefined || photoData[itemNo] === null){
        photoData[itemNo] = {}
      }

      photoData[itemNo][fileNo] = {file: file, data: e.target.result}
      deleteFlg[`${fileNo}_${itemNo}`] = 0

      this.props.updateState({ photoData: photoData, deleteFlg: deleteFlg})
    };
    reader.readAsDataURL(file)
  }

  onClickShowPhotoModal(state, itemNo, fileNo, fileId, photoData){
    const titleTag = document.getElementById(`detail_imgtitle${fileNo}_${itemNo}`);
    const title = titleTag ? titleTag.value.trim() : ""
    const data = {
      title: title,
      state: state,
      itemNo: itemNo,
      fileNo: fileNo,
      fileId: fileId,
      photoData: photoData
    }

    this.setState({ showPhotoModal: true, modalData: data});
  }

  onClickHideModal(){
    this.setState({ showModal: false, showPhotoModal: false });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contents);
