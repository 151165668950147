import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/reportAction';
import { Row, Col, Form, Button, Modal, InputGroup} from 'react-bootstrap';


class YearlyDrDataEdit extends Component {
  constructor(props) {
    super(props);

    const drAry = this.props.report.drData;
    const index = drAry.findIndex(dr => dr.item_no === this.props.itemNo);
    this.drData = drAry[index];
    this.minOptions = [];
    this.minOptions.push(<option key={-1} value={null}></option>);
    for(let m = 0; m <= 59; m++){
      this.minOptions.push(
        <option key={m} value={m}>
          {m}
        </option>
      );
    }

    this.state = {
      chType:["total","total","total","total","total","total",
              "total","total","total","total","total","total"],
      loading: false,
      resultMsg: "",
      resultCss: "text-danger",
    };
  }

  render() {
    return (
      <Modal show={this.props.showModal} onHide={() => this.props.hideModal()}
             aria-labelledby="contained-modal-title-vcenter"
             centered >
        <Modal.Header closeButton onClick={() => this.props.hideModal()}
                       className="main-map-modal-header">
          <Modal.Title className="main-map-modal-title">{`編集：${this.props.name}`}</Modal.Title>
        </Modal.Header>

        <Form onSubmit={(e) => {this.handleSubmit(e);}}>
          {this.formBody()}
          {this.formFooter()}
        </Form>
      </Modal>
    );
  }

  formBody(){
    if(this.drData === undefined){
      return (
        <Modal.Body>
          編集画面を開くことができません。<br />
          お手数をおかけしますが、もう一度帳票画面を開き直してください。
        </Modal.Body>
      );
    }

    const tdData = []
    this.drData.monthly_data.forEach((md, i) => {
      let date = `${md.month}月`;
      if(i === 0 || md.month === 1){
        date = `${md.year}年${md.month}月`;
      }
      const rpType = this.state.chType[i] === "total" ? "累計" : "月計"
      const tCount = md.total_count ? md.total_count : "-";
      const mCount = md.dr_count ? md.dr_count : "-";
      const mTime = md.dr_time ? md.dr_time : "-";
      const chTime  = md.ch_time;
      const chHour = chTime !== null ? chTime.substring(0, chTime.length - 3) : null;
      const cdMin = chTime !== null ? Number(chTime.substring(chTime.length - 2)) : null;

      tdData.push(
        <tr className="rp-p-data" key={i}>
          <td className="">{date}</td>
          <td className="">
            <Row noGutters={true} className="rp-h-d-val">
              <Col>
                <InputGroup className="">
                  <Button className={`edit-rp-ch-type-btn ch-dr-count-${this.state.chType[i]}`}
                          id={`dr-count-button-${md.month}`}
                          size="sm" variant="outline-navy"
                          onClick={() => this.onChangeReportType(i, this.state.chType[i])}>
                    {rpType}
                  </Button>
                  <Form.Control className="edit-input-form edit-rp-dr-count"
                                id={`drCount${md.month}`}
                                size="sm" type="number" min="0"
                                defaultValue={md.ch_total_count}/>
                </InputGroup>

                <div className="getting-value">
                  取得値 累計：{tCount} 月計：{mCount}
                </div>
              </Col>
            </Row>
          </td>

          <td>
            <Row noGutters={true} className="rp-h-d-val">
              <Col style={{maxWidth: "115px"}}>
                <Form.Control size="sm" type="number" defaultValue={chHour}
                              className="edit-input-form" id={`drHour${md.month}`} min="0"/>
              </Col>
              <Col sm={"auto"} className="d-flex align-items-center ml-1 mr-1">:</Col>
              <Col sm={"auto"}>
                <Form.Control as="select" size="sm" className="form-control edit-select-form"
                              id={`drMinute${md.month}`} defaultValue={cdMin}>
                  { this.minOptions }
                </Form.Control>
              </Col>
            </Row>
            <div className="getting-value">取得値 {mTime}</div>
          </td>
        </tr>
      );
    });

    return(
      <Modal.Body className="rp-modal-body" style={{height:`${this.props.contentH}px`}}>
        <table className="table-sm rp-table">
          <tbody>
            <tr className="rp-h-data">
              <td className="rp-h-item-title"></td>
              <td className="rp-h-d-title">
                <div className="font-title">運転回数</div>
                <div className="font-title-comment">※累計または月計のどちらか一方の値で反映</div>
              </td>
              <td className="rp-h-d-title font-title">運転時間</td>
            </tr>

            {tdData}
          </tbody>
        </table>
      </Modal.Body>
    );
  }

  formFooter(){
    const saveBtn = this.drData === undefined ? null : (
      <Button variant="success" type="submit" className="btn-common">
        保存
      </Button>
    );

    let form = (
      <>
        <div className={this.state.resultCss}>{this.state.resultMsg}</div>
        {saveBtn}
        <Button variant="secondary" onClick={() => this.props.hideModal()}>
          閉じる
        </Button>
      </>
    )

    if(this.state.loading){
      form = (
        <div className="loading-text d-flex align-items-center loading">
          <div className="spinner-border text-success" role="status"></div>
          <div className="loading-title">更新しています。しばらくお待ちください...</div>
        </div>
      )
    }

    return(
      <Modal.Footer className="main-map-modal-footer">
        {form}
      </Modal.Footer>
    );
  }

  onChangeReportType(index, rpType) {
    let chTypeAry = this.state.chType;
    chTypeAry[index] = rpType === "total" ? "monthly" : "total"
    this.setState({ chType: this.state.chType });
  }

  handleSubmit(e) {
    // 再リロード防止
    e.preventDefault();

    this.setState({loading: true, resultMsg: ""});

    const params = new FormData()
    params.append('year', this.props.report.year);
    params.append('data_no', this.props.itemNo);

    this.drData.monthly_data.forEach((md, i) => {
      const rpType = this.state.chType[i] === "total" ? "total_" : ""
      const chCount = e.target[`drCount${md.month}`].value.trim();
      const chHour = e.target[`drHour${md.month}`].value.trim();
      const chMinute = e.target[`drMinute${md.month}`].value.trim();
      let chTime = "";

      if(chHour !== "" && !isNaN(Number(chHour))) {
        chTime = Number(chHour) * 3600;
      }
      if(chMinute !== "" && Number(chMinute) >= 0)  {
        if(chTime !== ""){
          chTime += Number(chMinute) * 60;
        }else{
          chTime = Number(chMinute) * 60;
        }
      }

      params.append('monthly_data[][year]', md.year);
      params.append('monthly_data[][month]', md.month);
      params.append('monthly_data[][drive_time]', chTime);
      params.append(`monthly_data[][${rpType}drive_count]`, chCount);

      console.log(`Check val: month=${md.month} rpType=${rpType} chCount=${chCount} chTime=${chTime} chHour=${chHour} chMinute=${chMinute}`)
    });

    const callback = (success) => {
      let message = "編集に失敗しました。内容を確認してください";
      let css = "text-danger";

      console.log(`DEBUG:callback: success=${success}`)
      if(success){
        message = "編集内容を保存しました。";
        css = "text-success";
      }

      this.setState({
        loading: false, resultCss: css, resultMsg: message
      });
    }

    this.props.saveYearlyDrData(this.props.shutterId, params, callback);
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(YearlyDrDataEdit);
