import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/topAction';
import { isPassTimeTrendData } from 'modules/utils';
import 'promise-polyfill/src/polyfill';
import 'classlist-polyfill'
import AiTrend from 'components/common/AiTrend';

const STEP_MIN = 5;
const GRAPH_HEIGHT = 140;

class ItemAiTrend extends Component {
  constructor(props) {
    super(props);
    this.targetDate = this.props.startDate;
    this.drawedAiTrend = false;
  }

  render(){
    const aiData = this.props.top.aiData[this.props.shutterId];
    const aiTime = this.props.top.aiDateTime[this.props.shutterId];
    let enableDraw = true;

    if(aiTime !== undefined && aiTime !== null){
      if(this.drawedAiTrend){
        enableDraw = isPassTimeTrendData(aiTime, STEP_MIN);
      }else{
        this.drawedAiTrend = true;
      }
    }

    return(
      <AiTrend shutterId={this.props.shutterId} dataNo={this.props.dataNo}
               name={this.props.name} dataName={this.props.dataName}
               targetDate={this.targetDate} stepMin={STEP_MIN}
               aiData={aiData} enableReload={enableDraw} height={GRAPH_HEIGHT}/>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemAiTrend);
