import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/inspectionAction';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
//import * as module from 'modules/utils';
//import * as cookies from 'modules/cookies.js';
import { formatDateTime } from 'modules/utils';
import DLIcon from 'images/icon_download.png'

class InspectionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  componentDidMount() {
    this.shutterId = this.props.match.params.shutterId;
    this.props.getInspectionList(this.shutterId)
  }

  render() {
    this.shutterId = this.props.match.params.shutterId;

    const content = this.state.loading ? (
      <div className="loading-text d-flex align-items-center loading">
        <div className="spinner-border text-success" role="status"></div>
        <div className="loading-title">ダウンロードしています...</div>
      </div>
    ) : (
      <Link to={`/inspection/create/${this.shutterId}`}
          className="btn btn-common btn-success">
          新規作成
      </Link>
    )

    return(
      <div className="card-text card-body-common">
        <Row noGutters={true} className="content-header">
          <Col xs="auto" className="d-flex align-items-end">
            点検一覧
          </Col>
          <Col></Col>
          <Col xs="auto">
            {content}
          </Col>
        </Row>

        {this.formContents()}
      </div>
    );
  }

  formContents(){
    const list = this.props.inspection.list;
    if(list.length){
      return(
        <table className="table table-hover table-common inspection-list-table">
          <tbody>
            {
              list.map(data => {
                return(
                  <tr key={data.inspection_id}>
                    <td >
                      <Row noGutters={true}>
                        <Col className="item-col"
                             onClick={() => this.onClickItem(data.inspection_id)}>
                          <div>
                            <span className="title">点検日</span>
                            {formatDateTime(data.date, "yyyy年mm月dd日")}
                            <span className="title pl-3">点検者</span>
                            {data.created_user}
                          </div>
                        </Col>
                        <Col xs="auto" className="download-icon">
                          <img src={DLIcon} alt="download"
                               onClick={() => this.onClickDownload(data)}/>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      )
    }else{
      return(
        <div className="content-body">
          データがありません。新規作成を行ってください
        </div>
      )
    }
  }

  onClickItem(inspectionId){
    this.props.history.push(`/inspection/edit/${this.shutterId}/${inspectionId}`);
  }

  onClickDownload(data){
    const date    = formatDateTime(data.date,"_yyyy年mm月dd日")
    const sMap = this.props.common.shuttersMap[this.shutterId];
    let shutterName = ""
    if(sMap !== undefined) shutterName = `_${sMap.name}`;

    const fileName = `シートシャッター点検報告書${shutterName}${date}.xlsx`

    this.setState({ loading: true });

    const callback = (success) => {
      this.setState({ loading: false });
    }

    this.props.download(data.inspection_id, this.shutterId, fileName, callback);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionList);
