const initialState = {
  loading: true,
  newCreated: false,
  shutterId: undefined,
  list: [],
  saveResult: false,
  resultMsg: "",
  data: {}
}

export default function(state = initialState, action) {
  let files = [];

  switch(action.type) {
    case 'MAINTENANCE_LIST':
      return {
        ...state,
        loading: false,
        shutterId: action.shutterId,
        list: action.list,
        data: {},
        resultMsg: ""
      };
    case 'MAINTENANCE_DETAIL':
      return {
        ...state,
        data: action.data
      };
    case 'MAINTENANCE_PHOTO_LIST':
      const list = state.list;
      if(list.length <= 0) return state;

      const listIndex = list.findIndex(data => data.maintenance_id === action.maintenanceId);
      if(listIndex < 0) return state;

      files = list[listIndex].files;
      if(files.length > 0){
        const fileIndex = files.findIndex(data => data.file_id === action.fileId);
        if(fileIndex >= 0){
          files[fileIndex].photoData = action.photoData

          return {...state, list: list};
        }
      }
      return state;
    case 'MAINTENANCE_PHOTO_DATA':
      files = state.data.files;
      if(files){
        const index = files.findIndex(data => data.file_id === action.fileId);
        if(index >= 0){
          files[index].photoData = action.photoData
          return {...state,data: state.data};
        }
      }

      return state;
    case 'MAINTENANCE_SAVE':
      const newCreated = (action.maintenanceId === 0)

      return {
        ...state,
        loading: false,
        newCreated: newCreated,
        saveResult: action.saveResult,
        resultMsg: action.resultMsg,
        data: action.data
      };
    case 'CLEAR_MAINTENANCE_DATA':
      return {
        ...state,
        loading: false,
        newCreated: false,
        shutterId: undefined,
        list: [],
        saveResult: false,
        resultMsg: "",
        data: {}
      };
    default:
      return state;
  }
}
