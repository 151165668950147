import Cookies from 'js-cookie';

//有効期限（日数）
const expiresDay = 1;
//サーバ接続がセキュアである時のみ、Cookie情報有効にするかどうか
const secure = false;

/*
  データ取得
*/
export function get(key) {
  return Cookies.get(key);
}

/*
  Jsonデータ取得
*/
export function getJson(key) {
  return Cookies.getJSON(key);
}

/*
  データ保存（Jsonデータも可能）
*/
export function set(key, data, expires = expiresDay) {
  Cookies.set(key, data, { expires: expires, secure: secure});
}

/*
  データ削除
*/
export function remove(key) {
  Cookies.remove(key);
}

export function getShutterId(){
  let shutterId = get('shutterId');
  if(shutterId !== undefined && shutterId !== ""){
    return Number(shutterId);
  }

  shutterId = get('firstShutterId')
  if(shutterId){
    return Number(shutterId);
  }else{
    return -1;
  }
}

/*
  資格情報（headers-token）の保存
*/
export function setAuthData(data) {
  if(data === undefined) return;

  set("userId",      data['id']);
  set("userName",    data['user_name']);
  set("userType",    data['user_type']);
  set('account',     data['account']);
  set('accessToken', data['token']);
  set("companyId",    data['first_company_id']);
  set('factoryId',    data['first_factory_id']);
  remove('buildingId');
  remove('menuKey');
}

export function clearAuthData() {
  remove("userId");
  remove("userName");
  remove("userType");
  remove('account');
  remove('accessToken');
  remove("companyId");
  remove('factoryId');
  remove('buildingId');
  remove('shutterId');
  remove('firstShutterId');
  remove('menuKey');
}

/*
  資格情報確認
*/
export function verifyCredentials(){
  if(get('accessToken') && get('account')){
    return true;
  }else{
    return false;
  }
}
