import { store } from 'modules/store.js';
import { instance as http, instanceImage as http2 } from 'modules/httpConnection'
import { convDriveStatus } from 'devices/baseDevice';
import { getAiTrend } from 'modules/actions/trendAction';

export function mapStateToProps(state) {
  return state;
}

export function mapDispatchToProps(dispatch) {
  return {
    /*
      シャッター情報を取得
    */
    getShutterStatus: (shutterId, callback) => {
      getShutterStatus(shutterId, callback);
    },

    /*
      シャッター写真を取得
    */
    getShutterPhoto: (shutterId, callback) => {
      http2.post(`shutters/photo/${shutterId}?resize=medium`)
        .then(response => {
          callback(response.data);
        })
        .catch(data => {
          console.error(data);
        });
    },

    /*
      シャッター写真を保存します
    */
    saveShutterPhoto: (shutterId, photo, callback) => {
      if(photo == null){
        console.log("saveShutterPhoto: No Save!");
        return;
      }

      const data = new FormData()
      data.append('file', photo)

      http.post(`shutters/save_photo/${shutterId}`, data)
        .then(response => {
          if(response.data.result){
            callback(response.data.result);
          }
        })
        .catch(data => {
          console.error(data);
        });
    },

    /*
      シャッター設備情報リストを取得
    */
    getShutterInfoList: (factoryId, callback) => {
      http.post('shutters/info_list/' + factoryId)
        .then((response) => {
          callback(response.data);
        })
        .catch((data) => {
          console.error(data);
        });
    },

    getAiTrend: (shutterId, imsi, startDate, endDate, stepMin) => {
      getAiTrend(shutterId, imsi, startDate, endDate, stepMin, "SHUTTER", ()=>{});
    },

    getErrorContents: (shutterId,errorNo) => {
      http.post('shutters/error_contents/'+ shutterId + '/' + errorNo)
        .then((response) => {
          const data = response.data;
          const contents = {
            errorNo: data.error_no,
            cd: data.cd,
            name: data.name,
            cause: data.cause,
            solution: data.solution
          }

          store.dispatch( {
            type: 'ERROR_CONTENTS',
            errorContents: contents
          });
        })
        .catch((data) => {
          console.error(data);
        });
    },

    showErrorModal:(showModal) => {
      store.dispatch( {
        type: 'ERROR_MODAL',
        showErrorModal: showModal
      });
    },

    countupDriveTime:(driveTime) => {
      store.dispatch( {
        type: 'COUNTUP_DRIVETIME',
        driveTime: driveTime
      });
    },

    execRemoteControl:(shutterId, controlType, controlTag, checked) => {
      store.dispatch( {
        type: controlType,
        value: true,
        checked: checked
      });

      const params = new URLSearchParams();
      params.append('control_tag', controlTag);

      http.post(`shutters/remote_control/${shutterId}`, params)
        .then((response) => {
          const data = response.data;
          const result = data.result;
          const resultMessage = data.message

          store.dispatch( {
            type: 'REMOTE_CONTROLE_RESULT',
            result: result,
            resultMessage: resultMessage
          });
        })
        .catch((data) => {
          console.error(data);
        });
    }

  }
}

export function getShutterStatus(shutterId, callback){
  store.dispatch({type: 'LOAD_SHUTTER_DATA'});

  http.post('shutters/info_data/' + shutterId)
    .then((response) => {
      parseShutterStatusData(response.data);
      callback(response.data);
    })
    .catch((data) => {
      console.error(data);
    });
}


function parseShutterStatusData(data) {
  let ecoMode = "-";
  if(data.eco_mode === true){
    ecoMode = "ON";
  }else if(data.eco_mode === false){
    ecoMode = "OFF";
  }

  let driveTime = "-";
  let driveCount = "-";

  if(data.daily_drive_time !== undefined && data.daily_drive_time !== null &&
     data.daily_drive_time.constructor === Number){
    driveTime = Math.floor(data.daily_drive_time / 60);
  }
  if(data.daily_drive_count !== undefined && data.daily_drive_count !== null){
    driveCount = data.daily_drive_count;
  }

  const errors = [];
  data.errors.forEach(item => {
    errors.push(item.error_no);
  });

  store.dispatch( {
    type: 'INIT_IO_STATUS',
    shutterId: data.shutter_id,
    name: data.name,
    productNo: (data.product_no !== "") ? data.product_no : "-",
    imsi: data.imsi,
    monbanType: (data.monban_type !== "") ? data.monban_type : "-",
    deviceType: data.device_type,
    width: data.width,
    height: data.height,
    constructionDate: data.construction_date,
    aiItems: data.analogs,
    parts: data.parts,
    totalDriveCount: (data.drive_count !== null) ? data.drive_count : "-",
    driveCount: driveCount,
    driveTime: driveTime,
    driveMode: (data.drive_mode !== "") ? data.drive_mode : "-",
    driveStatus: convDriveStatus(data.drive_status),
    driveStatusKey: data.drive_status,
    errorCount: data.errors.length,
    errors: errors,
    inputStatus: data.input_status,
    ecoMode: ecoMode,
    ecoModeFlg: data.eco_mode,
    autoModeFlg: data.auto_mode,
    hasPicture: data.has_picture,
    remoteControllable: isRemoteControllable(data)
  });
}

function isRemoteControllable(data) {
  if(!data.remote_controllable) return false
  if(data.authority_level <= 0) return false;

  return true;
}
