const GET_LOADING_MSG = "データを取得しています。しばらくお待ちください...";
const SET_LOADING_MSG = "データを設定しています。しばらくお待ちください...";
const FUNC_NO_MODEL_TYPE = "F04"

const initialState = {
  loading: true,
  loadingMsg: GET_LOADING_MSG,
  result: undefined,
  modelType: "",
  dataList: [],
  targetAddress: 0,
  showModal: false,
  showModalResult: false,
  selectItem: undefined,
}

export default function(state = initialState, action) {
  switch(action.type) {
    case 'CLEAR_SETTING_DATA':
      return {
        ...state,
        loading: true,
        loadingMsg: GET_LOADING_MSG,
        result: undefined,
        dataList: [],
        showModal: false
      };
    case 'SETTING_DATA':
      return {
        ...state,
        loading: action.loading,
        loadingMsg: GET_LOADING_MSG,
        modelType: findModelType(action.dataList),
        dataList: action.dataList
      };
    case 'EXEC_SETTING_DATA':
      return {
        ...state,
        loading: action.loading,
        loadingMsg: SET_LOADING_MSG,
        targetAddress: action.address,
        showModal: action.showModal
      };
    case 'EXEC_SETTING_DATA_RESULT':
      return {
        ...state,
        result: action.result,
        modelType: findModelType(action.dataList),
        dataList: action.dataList,
        loading: action.loading,
        showModalResult: true
      };
    case 'SHOW_SETTING_MODAL':
      return {
        ...state,
        targetAddress: action.address,
        showModal: action.showModal,
        selectItem: undefined
      };
    case 'SETTING_MODAL_SELECT_ITEM':
      return {
        ...state,
        selectItem: action.selectItem
      };
    case 'SETTING_MODAL_RESULT':
      return {
        ...state,
        showModalResult: action.showModalResult
      };
    default:
      return state;
  }
}

function findModelType(dataList){
  const listIndex = dataList.findIndex(data => data.cd === FUNC_NO_MODEL_TYPE);

  if(listIndex) {
    const modelList = dataList[listIndex]["select_list"]
    const value = dataList[listIndex]["value"]
    return modelList[value];
  }else{
    return "";
  }
}
