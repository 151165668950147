import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/shutterAction';
import { getTodayString } from 'modules/utils';
import DiTrend from 'components/common/DiTrend';

class ContentDiTrend extends Component {
  constructor(props) {
    super(props);

    this.dateStr = getTodayString();
  }

  render() {
    const props = this.props.shutter;

    if(props.deviceType < 90){
      return (
        <div className="card main-contents-layout mb-2">
          <div className="card-body-common shutter-info-content-group no-padding">
            <DiTrend shutterId={props.shutterId} imsi={props.imsi}
                     dataNo={0}
                     startDate={this.dateStr} endDate={this.dateStr}
                     enableDiReload={props.enableDiReload}/>
          </div>
        </div>
      );
    }else{
      return null;
    }

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ContentDiTrend);
