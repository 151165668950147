import { store } from 'modules/store.js';
import { instance as http } from 'modules/httpConnection';
import { getDiTrend, getAiTrend } from 'modules/actions/trendAction';
import { getTodayString, isPassTimeTrendData } from 'modules/utils';
import * as cookies from 'modules/cookies.js';

const STEP_MIN = 5;

export function mapStateToProps(state) {
  return state;
}

export function mapDispatchToProps(dispatch) {
  return {
    getDisplayInfoList: () => {
      const companyId = cookies.get("companyId");
      const buildingId = cookies.get('buildingId')
      const params = new URLSearchParams();
      params.append('company_id', companyId);
      params.append('building_id', buildingId);

      http.post(`display_informations/list`, params)
        .then((response) => {
          let enables = {};
          const aiIds = [];
          const diIds = {};
          const sensorIds = [];
          response.data.forEach(data => {
            if(data.display_type === 1) {
               if(diIds[data.id] === undefined){
                 diIds[data.id] = []
               }

               diIds[data.id].push(data.data_no)
            }

            if(data.display_type === 2) {
              const aIndex = aiIds.findIndex(id => id === data.id);

              if(aIndex < 0){ aiIds.push(data.id) }
            }
            if(data.display_type === 3) {
              const sIndex = sensorIds.findIndex(id => id === data.id);

              if(sIndex < 0){ sensorIds.push(data.id) }
            }
          });

          store.dispatch( {
            type: 'DISPLAY_INFO_LIST',
            list: response.data,
            enableReload: enables
          });

          getDiTrendData(diIds);
          getAiTrendData(aiIds);
          getMultiSensorData(sensorIds);
        })
        .catch((data) => {
          console.error(data);
          store.dispatch( {type: 'LOGIN', logined: false} );
        });
    }

  }
}

function getDiTrendData(items){
  const targetDate = getTodayString();

  for(let id in items){
    items[id].forEach(dino => {
      console.log(`DEBUG:TOP:getDiTrendData:id(${id}) dino:${dino} start...`);
      getDiTrend(id, null, dino, targetDate, targetDate, "TOP", ()=>{});
    });
  }
}

function getAiTrendData(items){

  const targetDate = getTodayString();
  items.forEach(id => {
    console.log(`DEBUG:TOP:getAiTrendData:id(${id}) start...`);
    getAiTrend(id, null, targetDate, targetDate, STEP_MIN, "TOP", ()=>{});
  });
}

function getMultiSensorData(items){
  items.forEach(id => {
    http.post(`shutters/sensor_multi_data/${id}`)
      .then((response) => {
        console.log(`DEBUG:getSensorData:id(${id}) ok`);
        const hash = {}
        response.data.forEach(d => {
          hash[d.data_no] = d.value
        });

        store.dispatch( { type: 'TOP_GET_SENSOR_DATA', id: id, data: hash});
      })
      .catch((data) => {
        console.error(data);
      });
  });
}

export function reflectDataToTop(id){
  const diData = store.getState().top.diData
  const aiData = store.getState().top.aiData
  const sensorData = store.getState().top.sensorData

  if(sensorData[id]){
    getMultiSensorData([id]);
  }

  if(aiData[id]){
    const aiTime = store.getState().top.aiDateTime[id];

    if(aiTime){
      if(isPassTimeTrendData(aiTime, STEP_MIN)){
        getAiTrendData([id]);
      }
    }
  }

  if(diData[id]){
    const diTime = store.getState().top.diDateTime[id];

    if(diTime){
      if(isPassTimeTrendData(diTime, 0.5)){
        const items = {}
        items[id] = []
        for(let dino in diData[id]){
          items[id].push(dino)
        }

        getDiTrendData(items);
      }
    }
  }
}
