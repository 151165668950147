import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/inspectionAction';
//import { Link } from 'react-router-dom';
//import { Row, Col, Form, Button } from 'react-bootstrap';
//import * as module from 'modules/utils';
//import * as cookies from 'modules/cookies.js';

class InspectionDownload extends Component {
  render() {
    return (
      <div className="">
      点検
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionDownload);
