import KvController from 'devices/kvController'
import GaController from 'devices/gaController'
import XaController from 'devices/xaController'
import RwController from 'devices/rwController'
import BbController from 'devices/bbController'
import Sensor from 'devices/sensor'

export const DEVICE_TYPE_KV = 1;
export const DEVICE_TYPE_GA = 2;
export const DEVICE_TYPE_XA = 3;
export const DEVICE_TYPE_RW = 4;
export const DEVICE_TYPE_BB = 5;
export const DEVICE_TYPE_SENSOR = 101;
export function getDeviceFactory(deviceType) {
  switch(deviceType) {
    case DEVICE_TYPE_KV:
      return new KvController();
    case DEVICE_TYPE_GA:
      return new GaController();
    case DEVICE_TYPE_XA:
      return new XaController();
    case DEVICE_TYPE_RW:
      return new RwController();
    case DEVICE_TYPE_BB:
      return new BbController();
    case DEVICE_TYPE_SENSOR:
      return new Sensor();
    default:
      console.log('INFO::getDeviceFactory::No such device_type=' + deviceType);
      return null;
  }
}
