import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/inspectionAction';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import * as utils from 'modules/utils';
import Contents from './Contents';

class InspectionCreate extends Component {
  constructor(props) {
    super(props);
    this.shutterId = this.props.match.params.shutterId;
    this.maxFileCount = 2
    this.state = {
      info: {},
      items: [],
      status: {},
      charColor: {}, // 点検詳細のItemNoは1000番以降とする
      detailCount: 1,
      photoData: {},
      deleteFlg: {},
      loading: false,
      resultMsg: "",
      formDateAlert: "",
    };
  }

  componentDidMount() {
    const callback = (data) => {
      this.setState({
        info: {
          productNo: data.product_no,
          productType: data.product_type,
          size: utils.shutterSize(data),
          productionDate: utils.formatDateTime(data.production_date,"yyyy年mm月")
        },
        items: data.items
      })
    };
    this.props.getReportItems(this.shutterId, callback)
  }

  render() {
    return(
      <Form className="card-body-common" onSubmit={(e) => {this.handleSubmit(e);}}>
        <Row noGutters={true} className="content-header inspection-content-header">
          <Col xs="auto">
            <Link to={`/inspection/list/${this.shutterId}`}
                  className="btn btn-common btn-outline-success">
              点検一覧
            </Link>
          </Col>
          <Col></Col>
          <Col xs="auto" className={`d-flex align-items-end failure-message`}>
            { this.state.resultMsg }
          </Col>

          { this.formButtons() }
        </Row>

        <Contents shutterId={this.shutterId}
                  state={this.state}
                  fileCount={this.maxFileCount}
                  updateState={this.updateState.bind(this)}/>
      </Form>
    );
  }

  formButtons(){
    if(this.state.items.length === 0) return null;

    let form = (
      <Col xs="auto">
        <Button variant="success" type="submit" className="btn-common">
          登録
        </Button>
      </Col>
    )

    if(this.state.loading){
      form = (
        <div className="loading-text d-flex align-items-center loading">
          <div className="spinner-border text-success" role="status"></div>
          <div className="loading-title">新規作成しています。しばらくお待ちください...</div>
        </div>
      )
    }
    return form;
  }

  handleSubmit(e) {
    // 再リロード防止
    e.preventDefault();

    const date     = e.target.date.value.trim().replace(/-/g, '');
    const company  = e.target.company.value.trim();
    const customerName  = e.target.customerName.value.trim();
    const version  = e.target.controllerVer.value.trim();
    const count    = e.target.count.value.trim();
    const photoData = this.state.photoData;

    if(date.length < 8){
      alert("日付を記入してください");
      return;
    }

    this.setState({loading: true, resultMsg: ""});

    const params = new FormData();
    params.append('shutter_id', this.shutterId);
    params.append('date', date);
    params.append('company', company);
    params.append('customer_name', customerName);
    params.append('version', version);
    params.append('count', count);

    this.state.items.forEach(data =>{
      const itemNo = data.item_no
      const status = this.state.status[itemNo] || ""
      const color = this.state.charColor[itemNo] || ""
      const memo  = e.target[`memo_${data.item_no}`].value.trim()
      params.append(`items[][item_no]`, itemNo);
      params.append(`items[][status]`, status);
      params.append(`items[][color]`, color);
      params.append(`items[][memo]`, memo);
    });

    for (let i = 1; i <= this.state.detailCount; i++) {
      const itemCharNo = 1000 + i
      const color = this.state.charColor[itemCharNo] || "";
      const title = e.target[`detail_title_${i}`].value.trim()
      const memo  = e.target[`detail_memo_${i}`].value.trim()
      const photoInfo = photoData[i]
      params.append(`details[][item_no]`, i);
      params.append(`details[][title]`, title);
      params.append(`details[][memo]`, memo);
      params.append(`details[][color]`, color);

      for (let n = 1; n <= this.maxFileCount; n++) {
        const imgTitle = e.target[`detail_imgtitle${n}_${i}`].value.trim()
        let fileData = null;
        if(photoInfo && photoInfo[n]){
          fileData = photoInfo[n]["file"]
        }

        if(imgTitle === "" && fileData === null) continue;

        params.append(`details[][files[][file_no]]`, n);
        params.append(`details[][files[][title]]`, imgTitle);
        if(fileData){
          params.append(`details[][files[][file]]`, fileData);
        }
      }
    }

    const callback = (data) => {
      if(data.success){
        this.props.history.push(`/inspection/edit/${this.shutterId}/${data.data.inspection_id}`);
      }else{
        this.setState({
          loading: false,
          resultMsg: "新規作成に失敗しました。設定内容を確認してください"
        });
      }
    }

    this.props.save(0, params, callback);
  }

  updateState(state){
    this.setState(state);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionCreate);
