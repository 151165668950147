import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/maintenanceAction';
import { Switch, Route, Redirect } from 'react-router-dom';
import MaintenanceList from './MaintenanceList';
import MaintenanceCreate from './MaintenanceCreate';
import MaintenanceEdit from './MaintenanceEdit';

class Maintenance extends Component {
  render() {
    return (
      <div className="card main-contents-layout">
        <div className="card-header card-header-common">
          メンテナンス
        </div>

        <Switch>
          <Route exact path="/maintenance/list/:shutterId" component={ MaintenanceList }/>
          <Route exact path="/maintenance/create/:shutterId" component={ MaintenanceCreate }/>
          <Route exact path="/maintenance/edit/:shutterId/:maintenanceId" component={ MaintenanceEdit }/>
          <Route exact path="/maintenance/info/:shutterId/:maintenanceId" component={ MaintenanceList }/>
          <Redirect to="/main_map"/>
        </Switch>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);
