import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as Localize from 'react-native-localize';

// 言語jsonファイルのimport
import translationEN from "./en.json";
import translationJA from "./ja.json";

const resources = {
  ja: {
    translation: translationJA
  },
  en: {
    translation: translationEN
  }
};

// defaultは日本語にします
let locale = "en";
// デバイスのローカライズ情報を取得
const locales = Localize.getLocales();

if (locales) {
  if (Array.isArray(locales)) {
    locale = locales[0].languageCode;
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: locale,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
