import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { WEEKS } from 'modules/utils';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/reportAction';

class MonthlyAiData extends Component {
  render() {
    const aiData = this.props.report.aiData;

    return (
      <div className="content-report-data"
           style={{height:`${this.props.contentH}px`}}>
        <table className="table-sm rp-table">
          <tbody>
            <tr className="rp-header-record">
              <td className="rp-h-item-title">
                <div className="rp-h-cell"></div>
                <div className="rp-h-cell"></div>
                <div className="rp-h-cell"></div>
                <div className="rp-h-cell l-b-1"></div>
              </td>

              { this.headerData(aiData) }
            </tr>

            { this.dailyData(aiData) }
          </tbody>
        </table>
      </div>
    );
  }

  headerData(aiData){
    return(
      aiData.map(ai => {
        const unit = ai.unit !== null ? ai.unit : "-";
        const max  = ai.max !== null ? ai.max : "-";
        const min  = ai.min !== null ? ai.min : "-";
        const ave  = ai.ave !== null ? ai.ave : "-";

        return(
          <td key={ai.item_no} className="rp-h-data">
            <div className="rp-h-cell rp-h-d-w-ai rp-h-d-title">{ai.name}</div>
            <div className="rp-h-cell rp-h-d-item">{unit}</div>

            <Row noGutters={true} className="rp-h-cell rp-h-d-category">
              <Col xm={4} >最大</Col>
              <Col xm={4} >最小</Col>
              <Col xm={4} >平均</Col>
            </Row>

            <Row noGutters={true} className="rp-h-cell rp-h-d-val l-b-1">
              <Col xm={4} >{max}</Col>
              <Col xm={4} >{min}</Col>
              <Col xm={4} >{ave}</Col>
            </Row>
          </td>
        )
      })
    );
  }

  dailyData(aiData){
    const ddata = [];

    for(let d = 0; d < 31; d++){
      const tdData = [];
      let date = "-"
      let weekColor = ""

      aiData.forEach((ai, i) => {
        const dai = ai.daily_data[d]
        if(dai === undefined) return;

        if(i === 0) {
          date = `${dai.day}日(${WEEKS[dai.wday]})`
          if(dai.wday === 0){weekColor = "w-sun";}
          else if(dai.wday === 6){weekColor = "w-sat";}
        }

        const max  = dai.max !== null ? dai.max : "-";
        const min  = dai.min !== null ? dai.min : "-";
        const ave  = dai.ave !== null ? dai.ave : "-";

        tdData.push(
          <td key={`${ai.item_no}-${dai.day}`} className={weekColor}>
            <Row noGutters={true}>
              <Col xm={4} >{max}</Col>
              <Col xm={4} >{min}</Col>
              <Col xm={4} >{ave}</Col>
            </Row>
          </td>
        )
      })
      if(tdData.length === 0) continue;

      ddata.push(
        <tr key={d} className="rp-p-data">
          <td className={weekColor}>{date}</td>
          {tdData}
        </tr>
      );
    }

    return ddata;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyAiData);
