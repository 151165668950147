import BaseDevice from 'devices/baseDevice'

const IO_MAP = {
  position: [5, 6, 7, 8, 9],
  driveMode: 5,
  ecoMode: 5,
  sensor: [0, 1, 2, 3]
};

const MODE_MAP = {
  manual: 1,
  auto: 2
}

const DRIVE_STATUS = {
  5: {5: "close", 6: "open", 7: "eco"},
  6: {0: "stop", 1: "opening", 2: "opening", 3: "opening", 5: "eco", 6: "closing"},
  7: {1: "stop"},
  8: {0: "stop", 1: "opening", 2: "opening", 5: "closing"},
  9: {0: "stop", 1: "opening", 2: "eco", 4: "closing"}
}

const DRIVE_SHUTTER_DINO = 47;
const RESOLUTION = 8;

class KvController extends BaseDevice{
  getDriveShutterDiNo() {
    return DRIVE_SHUTTER_DINO;
  }

  parseRawData(rData) {
    return super.parseRawData(rData, IO_MAP, MODE_MAP, DRIVE_STATUS, RESOLUTION);
  }
}

export default KvController;
