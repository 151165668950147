import { combineReducers } from 'redux';
import common from './common';
import auth from './auth';
import top from './top';
import main_map from './main_map';
import shutter from './shutter';
import setting from './setting';
import maintenance from './maintenance';
import finder from './finder';
import contact from './contact';
import report from './report';
import inspection from './inspection';

export default combineReducers({
  common,
  top,
  auth,
  main_map,
  shutter,
  setting,
  maintenance,
  finder,
  contact,
  report,
  inspection
});
