import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/topAction';


class ItemSensorData extends Component {
  constructor(props) {
    super(props);
    this.isConnect = true;

    this.state = {
      name: "-",
      value: "-",
      unit: "",
      fontSize: 30
    }

    window.addEventListener('resize', () => {
      this.onResize();
    });
  }

  componentDidMount() {
    this.onResize();
  }

  render(){
    const id = this.props.shutterId;
    const data = this.props.top.sensorData[id];
    const dataNo = this.props.dataNo
    const color = `sensor-color`;
    let value = "-";

    if(data !== undefined && data[dataNo] !== undefined) {
      value = data[dataNo];
    }

    return(
      <div className="col-sm-3">
        <div className={`card item-sensor ${color}`}>
          <div className="card-header item-sensor-title">
            {`${this.props.name} : ${this.props.dataName}`}
          </div>
          <div className="card-text item-sensor-data card-body-common"
               id={`sensor-data-val-${this.props.shutterId}-${dataNo}`}
               style={{fontSize: `${this.state.fontSize}px`}}>

              {`${value} ${this.props.unit}` }
          </div>
        </div>
      </div>
    );
  }

  onResize(){
    const id = `sensor-data-val-${this.props.shutterId}-${this.props.dataNo}`;
    const obj = document.getElementById(id);

    if(obj != null){
      const width = obj.clientWidth;
      const fontSize = width * 0.192 - 6.54;

      this.setState({
        fontSize: fontSize
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemSensorData);
