import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/trendAction';
import { getTodayString } from 'modules/utils';
import AiTrend from 'components/common/AiTrend';

const STEP_MIN = 5;

class ContentAiTrend extends Component {
  constructor(props) {
    super(props);
    this.targetDate = getTodayString();
  }

  render() {
    const props = this.props.shutter;

    return (
      <div className="row no-gutters shutter-info-analog-group">
        {
          props.aiItems.map((ai, i) => {
            return(
              <AiTrend shutterId={props.shutterId} key={i}
                       dataNo={ai.aino} dataName={ai.name}
                       targetDate={this.targetDate} stepMin={STEP_MIN}
                       aiData={props.aiData}/>
            )
          })
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentAiTrend);
