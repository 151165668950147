import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/settingAction';
import { Row, Col } from 'react-bootstrap';
import Loading from 'components/common/Loading';
import ModalSetting from './ModalSetting';
import ModalResult from './ModalResult';

class Setting extends Component {
  constructor(props) {
    super(props);
    this.shutterId = this.props.match.params.shutterId;
  }

  /*
    render後に呼ばれる
  */
  componentDidMount() {
    this.getRemoteSettingData();
  }

  render(){
    const props = this.props.setting;

    if(props.loading){
      return (<Loading loading={props.loading} loadingMsg={props.loadingMsg}/>);
    }

    return(
      <div className="card main-contents-layout" id={`setting-content-${this.shutterId}`}>
        <div className="card-header card-header-common2">
          遠隔設定
        </div>

        { this.formDataList() }

        <ModalSetting shutterId={this.shutterId}/>
        <ModalResult />
      </div>
    );
  }

  getRemoteSettingData(){
    if(this.shutterId !== undefined && this.shutterId !== ""){
      this.props.getRemoteSettingData(this.shutterId);
    }
  }

  formDataList(){
    const props = this.props.setting;
    const harfCount = props.dataList.length / 2
    const rows = {col0: [], col1: []};

    props.dataList.forEach((data, i) => {
      let valueStr = "";
      if(data.select_list.length){
        const index = data.value - data.min;
        valueStr = data.select_list[index];
      }else{
        const value = data.value / Math.pow(10, data.decimal_point);
        valueStr = value.toFixed(data.decimal_point);
      }

      const colKey = harfCount > i ? "col0" : "col1";

      rows[colKey].push(
        <Row noGutters={true} className="setting-func-item" key={data.cd}
             onClick={() => this.onClickShowModalSetting(data.addr) }>
          <Col xs={"auto"} className="setting-func-no">{data.cd}</Col>
          <Col className="setting-func-name">
            <div>{data.name}</div>
            <div className="text-success">
              {valueStr} {data.unit}
            </div>
          </Col>
        </Row>
      );
    });

    return(
      <Row noGutters={true} className="setting-content-group">
        <Col lg={6} className="setting-item-col" key="col0">
          {rows["col0"]}
        </Col>
        <Col lg={6} className="setting-item-col" key="col1">
          {rows["col1"]}
        </Col>
      </Row>
    );
  }

  onClickShowModalSetting(address){
    this.props.showModalSetting(address, !this.props.setting.showModal);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
