import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/reportAction';

class YearlyAiData extends Component {
  render() {
    const aiData = this.props.report.aiData;

    return (
      <div className="content-report-data"
           style={{height:`${this.props.contentH}px`}}>
        <table className="table-sm rp-table">
          <tbody>
            <tr className="rp-header-record">
              <td className="rp-h-item-title">
                <div className="rp-h-cell"></div>
                <div className="rp-h-cell"></div>
                <div className="rp-h-cell"></div>
                <div className="rp-h-cell l-b-1"></div>
              </td>

              { this.headerData(aiData) }
            </tr>

            { this.monthlyData(aiData) }
          </tbody>
        </table>
      </div>
    );
  }

  headerData(aiData){
    return(
      aiData.map(ai => {
        const unit = ai.unit !== null ? ai.unit : "-";
        const max  = ai.max !== null ? ai.max : "-";
        const min  = ai.min !== null ? ai.min : "-";
        const ave  = ai.ave !== null ? ai.ave : "-";

        return(
          <td key={ai.item_no} className="rp-h-data">
            <div className="rp-h-cell rp-h-d-w-ai rp-h-d-title">{ai.name}</div>
            <div className="rp-h-cell rp-h-d-item">{unit}</div>

            <Row noGutters={true} className="rp-h-cell rp-h-d-category">
              <Col xm={4} >最大</Col>
              <Col xm={4} >最小</Col>
              <Col xm={4} >平均</Col>
            </Row>

            <Row noGutters={true} className="rp-h-cell rp-h-d-val l-b-1">
              <Col xm={4} >{max}</Col>
              <Col xm={4} >{min}</Col>
              <Col xm={4} >{ave}</Col>
            </Row>
          </td>
        )
      })
    );
  }


  monthlyData(aiData){
    const mdata = [];
    let backColor = "";

    for(let m = 0; m < 12; m++){
      const tdData = [];
      let date = "-"

      aiData.forEach((ai, i) => {
        const mai = ai.monthly_data[m]
        if(mai === undefined) return;

        if(i === 0) {
          if(m === 0 || mai.month === 1){
            date = `${mai.year}年${mai.month}月`
          }else{
            date = `${mai.month}月`
          }
        }

        const max  = mai.max !== null ? mai.max : "-";
        const min  = mai.min !== null ? mai.min : "-";
        const ave  = mai.ave !== null ? mai.ave : "-";

        tdData.push(
          <td key={`${ai.item_no}-${mai.month}`}>
            <Row noGutters={true}>
              <Col xm={4} >{max}</Col>
              <Col xm={4} >{min}</Col>
              <Col xm={4} >{ave}</Col>
            </Row>
          </td>
        )
      })
      if(tdData.length === 0) continue;
      if(m % 4 === 0){
        backColor = backColor === "" ? "change-bk-color" : "";
      }

      mdata.push(
        <tr key={m} className={`rp-p-data ${backColor}`}>
          <td>{date}</td>
          {tdData}
        </tr>
      );
    }

    return mdata;
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(YearlyAiData);
