import { store } from 'modules/store.js';
import { instance as http } from 'modules/httpConnection'

export function mapStateToProps(state) {
  return state;
}

export function mapDispatchToProps(dispatch) {
  return {
    getAiTrend: (shutterId, imsi, startDate, endDate, stepMin, type, callback) => {
      getAiTrend(shutterId, imsi, startDate, endDate, stepMin, type, callback);
    },

    getDiTrend: (shutterId, imsi, dataNo, startDate, endDate, callback) => {
      getDiTrend(shutterId, imsi, dataNo, startDate, endDate, "TREND", callback)
    }
  }
}

export function getAiTrend(shutterId, imsi, startDate, endDate, stepMin, type, callback) {
  const params = new URLSearchParams();
  params.append('start_date', startDate);
  params.append('end_date', endDate);
  params.append('step_min', stepMin);

  http.post(`trend_graphs/ai_trend/${shutterId}`, params)
    .then((response) => {
      console.log(`DEBUG:trendAction getAiTrend:id(${shutterId}) ok`);

      store.dispatch( {
        type: `${type}_GET_AI_TREND`, id: shutterId,
        imsi: imsi, aiData: response.data
      });

      callback(response.data);
    })
    .catch((data) => {
      console.error(data);
    });
}

export function getDiTrend(shutterId, imsi, dataNo, startDate, endDate, type, callback) {
  const params = new URLSearchParams();
  params.append('di_no', dataNo);
  params.append('start_date', startDate);
  params.append('end_date', endDate);

  const path = dataNo > 0 ? "di_trend" : "now_operation_data";

  http.post(`trend_graphs/${path}/${shutterId}`, params)
    .then((response) => {
      console.log(`DEBUG:trendAction getDiTrend:id(${shutterId}) ok`);
      const diData =  parseDiTrendData(response.data, startDate, endDate)

      if(type === "TREND"){
        store.dispatch( { type: 'DISABLE_DI_RELOAD', imsi: imsi });
      }else{
        store.dispatch( {
          type: `${type}_GET_DI_TREND`, id: shutterId, dino: dataNo, diData: diData
        });
      }

      callback(diData);
    })
    .catch((data) => {
      console.error(data);
    });
}

function parseDiTrendData(data, startDate, endDate) {
  const statusAry = data.status;
  const initialStatus = data.initial_status;

  let driveCount = data.drive_count;
  let driveTime  = data.drive_time;
  let maxDriveTime = data.max_drive_time;

  if(initialStatus === null){
    return {
      diData: statusAry, driveCount: driveCount,
      driveTime: Math.floor(driveTime / 60),
      maxDriveTime: Math.floor(maxDriveTime / 60)
    }
  }

  if(initialStatus.status){
    let duration = 0
    let status = { seconds: 0, status: true};

    if(statusAry.length === 0 && startDate.length === 8 && endDate.length === 8){
      duration = calDateDistance(startDate, endDate);

      if(data.dino !== 0) {
        maxDriveTime = duration;
        driveCount += 1;
        driveTime += duration;
      }
    }else{
      duration = statusAry[0].seconds

      if(data.dino !== 0) {
        let tmpDriveTime = duration;

        if(statusAry[0].status){
          //ON状態が継続
          tmpDriveTime += statusAry[0].duration;
        }else{
          //状態が切り替わっている
          if(data.dino !== 0) driveCount += 1;
        }

        if(maxDriveTime < tmpDriveTime){
          maxDriveTime = tmpDriveTime;
        }
        driveTime += duration;
      }
    }

    status.duration = duration
    statusAry.push(status);
  }

  return {
    diData: statusAry, driveCount: driveCount,
    driveTime: Math.floor(driveTime / 60),
    maxDriveTime: Math.floor(maxDriveTime / 60)
  }
}

function calDateDistance(startDate, endDate) {
  const sDate = new Date(startDate.slice(0,4),
                         Number(startDate.slice(4,6)) - 1,
                         startDate.slice(6,8));
  let eDate   = new Date(endDate.slice(0,4),
                         Number(endDate.slice(4,6)) - 1,
                         endDate.slice(6,8), 23, 59, 59);
  const today = new Date();
  const y = today.getFullYear();
  const m = ('00' + (today.getMonth()+1)).slice(-2);
  const d = ('00' + today.getDate()).slice(-2);

  if(`${y}${m}${d}` === endDate){
    eDate = today;
  }

  return (eDate - sDate) / 1000;
}
