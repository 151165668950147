import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/shutterAction';
import { getTodayString } from 'modules/utils';
import Loading from 'components/common/Loading';
import ContentProductInfo from './ContentProductInfo';
import ContentNowInfo from './ContentNowInfo';
import ContentStatus from './ContentStatus';
import ContentDiTrend from './ContentDiTrend';
import ContentAiTrend from './ContentAiTrend';
import ContentSensorInfo from './ContentSensorInfo';

const STEP_MIN = 5;

class Shutter extends Component {
  constructor(props) {
    super(props);
    this.targetDate = getTodayString();
    this.shutterId = this.props.match.params.shutterId;
  }

  /*
    render後に呼ばれる
  */
  componentDidMount() {
    if(this.shutterId !== undefined && this.shutterId !== ""){
      this.loadApiServer();
    }
  }

  /*
    Component終了時に呼ばれる
  */
  componentWillUnmount(){
    this.stopDriveTimer();
  }

  render() {
    const props = this.props.shutter;

    if(props.loading){
      return (<Loading loading={props.loading}/>);
    }

    return (
      <div className="shutter-info-content"
           id={`shutter-content-${props.shutterId}`}>

        <div className="card main-contents-layout mb-2">
          <div className="card-header card-header-common2">
            設備情報
          </div>
          <div className="card-body-common">
            {/* 製品情報 */}
            <ContentProductInfo />
          </div>
        </div>

        {
          // 稼働状況,ステータス
          this.contentStatus()
        }

        {/* シャッター開 動作 */}
        <ContentDiTrend />
        {/* シャッター開 動作 */}
        <ContentAiTrend />
        {/* 入出力項目 */}
        <ContentSensorInfo />

      </div>
    );

  }

  loadApiServer(){
    const callback = (data) => {
      this.startDriveTimer();
      this.getAiTrend(this.props.shutter);
    }
    this.props.getShutterStatus(this.shutterId, callback);
  }

  getAiTrend(data){
    if(data.aiItems.length){
      const gettingTime = data.gettingAiDateTime;
      const nowTime = new Date();
      const passTime = nowTime - gettingTime;
      let plotMin = 0;
      if(gettingTime !== null){
        plotMin = parseInt(gettingTime.getMinutes() / STEP_MIN) * STEP_MIN;
      }

      // 以下の条件の場合にアナログトレンドデータの取得を行う
      // ・データ取得してから、STEP_MIN分前のデータが取得できていない場合
      // ・データ取得してから、STEP_MIN分以上経過していた場合
      if(plotMin < nowTime.getMinutes() - STEP_MIN ||
         passTime >= STEP_MIN * 60 * 1000){
        this.props.getAiTrend(this.shutterId, data.imsi,
                              this.targetDate, this.targetDate,
                              STEP_MIN)
      }
    }
  }

  contentStatus(){
    if(this.props.shutter.deviceType >= 90) return null;


    return(
      <div className="card main-contents-layout mb-2 pt-1 pb-1">
        <div className="card-body-common">
          {/* 稼働状況 */}
          <ContentNowInfo />
          {/* ステータス */}
          <ContentStatus />
        </div>
      </div>
    );
  }

  startDriveTimer() {
    this.intervalTimer = setInterval(() => this.countupDriveTime(), 60000)
  }

  countupDriveTime() {
    if(this.props.shutter.driveStatusKey === "open"){
      let driveTime = this.props.shutter.driveTime;

      if(driveTime.constructor === Number){
        this.props.countupDriveTime(driveTime + 1);
      }
    }else{
      this.stopDriveTimer();
    }
  }

  stopDriveTimer() {
    clearInterval(this.intervalTimer)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Shutter);
