import React, { Component } from 'react';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import MainRoute from 'components/common/MainRoute';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'styles/App.scss';

class App extends Component {
  render() {
    return (
      <div className="app-header" id="App">
        <Header />
        <MainRoute />
        <Footer />
      </div>
    );
  }
}

export default App;
