import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/maintenanceAction';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import MimeTypeNobody from 'images/mime_type_nobody.png'
import * as module from 'modules/utils';
import ModalNotice from 'components/common/ModalNotice';

class MaintenanceEdit extends Component {
  constructor(props) {
    super(props);
    this.shutterId = this.props.match.params.shutterId;
    this.maintenanceId = this.props.match.params.maintenanceId;
    this.maxFileCount = 6
    this.state = {
      existFiles:[],
      newFiles:[],
      deleteFileIds: [],
      loading: false,
      delete: false,
      loadingMsg: "",
      resultMsg: "",
      resultCss: "failure-message",
      formDateAlert: "",
      formTitleAlert: "",
      enableAddFile: true,
      showModal: false,
      modalData: {}
    };
  }

  /*
    render後に呼ばれる
  */
  componentDidMount() {
    const callback = (data) => {
      const existFiles = data.files;
      const newFiles  = this.state.newFiles;
      const fileCount = existFiles.length + newFiles.length;
      const enableAddFile = fileCount < this.maxFileCount;

      this.setState({ existFiles: existFiles, enableAddFile: enableAddFile})
    };

    const data = this.props.maintenance.data;
    if(data.maintenance_id === undefined){
      this.props.getMaintenanceDetail(this.maintenanceId, this.shutterId, callback)
    }else{
      if(this.props.maintenance.newCreated){
        this.setState({ resultCss: "result-message", resultMsg:"新規に作成しました。"})
      }

      callback(data)
    }
  }

  render(){
    const data = this.props.maintenance.data;
    const date = module.formatDateTime(data.date, "yyyy-mm-dd");
    const addFileBtn = this.state.enableAddFile ? "btn-success" : "btn-secondary";

    return(
      <Form className="card-body-common" onSubmit={(e) => {this.handleSubmit(e);}}>
        <Row noGutters={true} className="content-header">
          <Col xs="auto">
            <Link to={`/maintenance/list/${this.shutterId}`}
                  className="btn btn-common btn-outline-success">
              メンテナンス一覧
            </Link>
          </Col>
          <Col></Col>
          <Col xs="auto" className={`d-flex align-items-end ${this.state.resultCss}`}>
            {this.state.resultMsg}
          </Col>
          {this.formButtons()}
        </Row>

        <div className="content-body">
          <div className="maintenance-form">
            <Row noGutters={true} className="justify-content-end mb-2">
              <Col sm="auto" className="title-label">登録者</Col>
              <Col sm="auto">{module.sliceText(data.created_user, 15)}</Col>
            </Row>

            <Form.Group as={Row} noGutters={true} controlId="formDate">
              <Form.Label column sm="2">
                日付
              </Form.Label>
              <Col sm="6" md="5" lg="4" xl="3">
                <Form.Control type="date" className={this.state.formDateAlert}
                              defaultValue={date}/>
              </Col>
            </Form.Group>

            <Form.Group as={Row} noGutters={true} controlId="formTitle">
              <Form.Label column sm="2">
                タイトル
              </Form.Label>
              <Col sm="9">
                <Form.Control type="text" className={this.state.formTitleAlert}
                              defaultValue={data.title}/>
              </Col>
            </Form.Group>

            <Form.Group as={Row} noGutters={true} className="mb-3" controlId="formCost">
              <Form.Label column sm="2">
                費用
              </Form.Label>
              <Col sm="6" md="5" lg="4" xl="3">
                <Form.Control type="number" defaultValue={data.cost}/>
              </Col>
              <Col className="form-cost-unit">
                円
              </Col>
            </Form.Group>

            <Row noGutters={true} className="mb-2">
              <Col>
                <Form.Group controlId="formNote" style={{marginRight: "10px"}}>
                  <Form.Label className="title-note-label">メンテナンス内容</Form.Label>
                  <Form.Control as="textarea"  className="form-data-note" rows={10}
                                defaultValue={data.note}/>
                </Form.Group>
              </Col>

              <Col>
                <Row noGutters={true} >
                  <Col className="title-label">添付資料（6ファイルまで）</Col>
                  <Col sm="auto">
                    <Form.Group className="form-add-files" controlId="formFile">
                      <Form.Label style={{marginBottom:"0"}}>
                        <div className={`btn-add-file btn ${addFileBtn}`}>
                          ファイル追加
                        </div>
                        <Form.Control type="file" className="select-file-data"
                                      disabled={!this.state.enableAddFile}
                                      onChange={(e) => this.selectPhotoData(e)}/>

                      </Form.Label>
                    </Form.Group>

                  </Col>
                </Row>
                {this.formFileList()}
              </Col>
            </Row>
          </div>
        </div>

        {this.formModalNotice()}
      </Form>
    );
  }

  formButtons(){
    const data = this.props.maintenance.data.maintenance_id;

    if(data === undefined){ return null; }
    if(this.state.delete){ return null; }

    let form = (
      <>
        <Col xs="auto">
          <Button variant="success" type="submit" className="btn-common">
            保存
          </Button>
        </Col>
        <Col xs="auto" style={{marginLeft: "10px"}}>
          <Button variant="danger" className="btn-common"
                  onClick={() => this.onClickShowDeleteModal()}>
            削除
          </Button>
        </Col>
      </>
    )

    if(this.state.loading){
      form = (
        <div className="loading-text d-flex align-items-center loading">
          <div className="spinner-border text-success" role="status"></div>
          <div className="loading-title">{this.state.loadingMsg}</div>
        </div>
      )
    }

    return form;
  }

  formFileList(){
    const data = this.props.maintenance.data
    const list = []

    if(data.files) {
      data.files.forEach((item, i) => {
        let fileIcon = module.fileTypeIcon(item)
        if(item.file_type === "image" && item.photoData !== undefined){
          fileIcon = (window.URL || window.webkitURL).createObjectURL(item.photoData);
        }

        list.push(
          <tr key={item.file_id}>
            <td>
              <Row noGutters={true} className="exist-file-data-row">
                <Col sm="auto" className="mime-type-file-icon-col"
                     onClick={() => this.onClickFileIcon(item)}>
                  <img className="mime-type-file-icon" src={fileIcon} alt="FileIcon"/>
                </Col>
                <Col className="file-name" sm="auto"
                     onClick={() => this.onClickFileName(item)}>
                  {item.file_name}
                </Col>
                <Col></Col>
                <Col sm="auto" className="delete-icon-col"
                     onClick={() => this.removeExistPhotoData(i, item.file_id) }>
                  ×
                </Col>
              </Row>
            </td>
          </tr>
        );
      })
    }

    this.state.newFiles.forEach((file, i) => {
      let fileIcon = MimeTypeNobody
      if(file.file.type.includes("image")){
        fileIcon = file["data"]
      }

      list.push(
        <tr key={i}>
          <td>
            <Row noGutters={true} className="new-file-data-row">
              <Col sm="auto" className="mime-type-file-icon-col">
                <img className="mime-type-file-icon" src={fileIcon} alt="FileIcon"/>
              </Col>
              <Col className="file-name" sm="auto">
                {file.file.name}
              </Col>
              <Col></Col>
              <Col sm="auto" className="delete-icon-col"
                   onClick={() => this.removeNewPhotoData(i) }>
                ×
              </Col>
            </Row>
          </td>
        </tr>
      );
    });

    return(
      <table className="table table-hover table-common maintenance-file-list-table">
        <tbody>{list}</tbody>
      </table>
    )
  }

  selectPhotoData(e){
    if(!this.state.enableAddFile) return;
    if(e.target.files.length <= 0) return;

    const file = e.target.files[0]
    const reader = new FileReader()
    const existFiles = this.props.maintenance.data.files
    const newFiles = this.state.newFiles;

    reader.onload = (e) => {
      newFiles.push({file: file, data: e.target.result})
      const fileCount = existFiles.length + newFiles.length;
      const enableAddFile = fileCount < this.maxFileCount;

      this.setState({ newFiles: newFiles, enableAddFile: enableAddFile })
    };
    reader.readAsDataURL(file)
  }

  onClickFileIcon(item){
    if(item.file_type === "image"){
      this.onClickShowPhotoModal(item);
    }else{
      this.onClickFileName(item);
    }
  }

  onClickFileName(item){
    const maintenanceId = this.props.maintenance.data.maintenance_id;
    this.props.download(maintenanceId, item.file_id, item.file_name);
  }

  removeNewPhotoData(i){
    const existFiles = this.props.maintenance.data.files
    const newFiles  = this.state.newFiles;

    newFiles.splice(i, 1);

    const fileCount = existFiles.length + newFiles.length;
    const enableAddFile = fileCount < this.maxFileCount;
    this.setState({ newFiles: newFiles, enableAddFile: enableAddFile});
  }

  removeExistPhotoData(i, fileId){
    const existFiles = this.state.existFiles;
    const newFiles  = this.state.newFiles;
    const fileIds = this.state.deleteFileIds;

    existFiles.splice(i, 1);
    fileIds.push(fileId)

    const fileCount = existFiles.length + newFiles.length;
    const enableAddFile = fileCount < this.maxFileCount;

    this.setState({ existFiles: existFiles, deleteFileIds: fileIds,
                    enableAddFile: enableAddFile });
  }

  handleSubmit(e) {
    // 再リロード防止
    e.preventDefault();

    const maintenanceId = this.props.maintenance.data.maintenance_id;
    const date  = e.target.formDate.value.trim().replace(/-/g, '');
    const title = e.target.formTitle.value.trim();
    const cost  = e.target.formCost.value.trim();
    const note  = e.target.formNote.value.trim();
    const newFiles = this.state.newFiles;
    const deleteFileIds = this.state.deleteFileIds;

    if(date.length < 8){
      alert("日付を記入してください");
      this.setState({formDateAlert: "form-alert"});
      return;
    }

    if(title === ""){
      alert("タイトルを記入してください");
      this.setState({formTitleAlert: "form-alert"});
      return;
    }

    this.setState({loading: true, resultMsg: "",
                   loadingMsg: "更新しています。しばらくお待ちください..."});

    const params = new FormData()
    params.append('shutter_id', this.shutterId);
    params.append('date', date);
    params.append('title', title);
    params.append('cost', cost);
    params.append('note', note);
    newFiles.forEach(data => {
      params.append('files[]', data["file"]);
    });
    deleteFileIds.forEach(fileId => {
      params.append('delete_file_ids[]', fileId);
    });

    const callback = (data) => {
      let message = "保存に失敗しました。設定内容を確認してください";
      let css = "failure-message";
      if(data.success){
        message = "内容を更新しました。";
        css = "result-message";
      }

      this.setState({
        loading: false, resultCss: css, resultMsg: message,
        existFiles: data.files, newFiles: [], deleteFileIds: []
      });
    }

    this.props.save(maintenanceId, params, callback);
  }

  onClickDelete(){
    this.setState({
      showModal: false, loading: true,
      loadingMsg: "削除しています。しばらくお待ちください..."
    });

    const callback = (data, message) => {
      this.setState({
        loading: false, delete: data.success,
        resultCss: "failure-message", resultMsg: message
      });
    }

    this.props.delete(this.maintenanceId, this.shutterId, callback);
  }

  formModalNotice(){
    const data = this.state.modalData;

    return(
      <ModalNotice showModal={this.state.showModal}
                   title={data.title}
                   bodyData={data.bodyData}
                   disableBtnDone={data.disableBtnDone}
                   btnDoneName={data.btnDoneName}
                   btnDoneColor={data.btnDoneColor}
                   size={data.size}
                   done={data.doneAction}
                   cancel={() => {this.onClickHideModal()}}/>
    )
  }

  onClickShowPhotoModal(item){
    let photoData = null;
    if(item.file_type === "image" && item.photoData !== undefined){
      photoData = (window.URL || window.webkitURL).createObjectURL(item.photoData);
    }

    if(photoData === null) return;

    const root = document.getElementById('root');
    let height = "auto"
    if(root != null){
      height = `${root.clientHeight - 200}px`;
    }

    const bodyData = (
      <div className="modal-body-show-photo-data" style={{height: height}}>
        <img src={photoData} alt="PhotoData" style={{maxHeight: height}}/>
      </div>
    )

    const data = {
      title: item.file_name, bodyData: bodyData, disableBtnDone: true, size:"lg"
    }

    this.props.clearShutterPopover();
    this.setState({ showModal: true, modalData: data});
  }

  onClickShowDeleteModal(modalType){
    const bodyData = (
      <>
        <div>添付資料も合わせてデータを削除します。</div>
        <div>削除してもよろしいですか？</div>
      </>
    )
    const callback = () => {
      this.onClickDelete()
    }
    const data = {
      bodyData: bodyData, btnDoneName: "削除",
      btnDoneColor: "danger", doneAction: callback
    }

    this.setState({ showModal: true, modalData: data});
  }

  onClickHideModal(){
    this.setState({ showModal: false });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceEdit);
