import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/contactAction';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import ModalNotice from 'components/common/ModalNotice';

class ContactEdit extends Component {
  constructor(props) {
    super(props);
    this.shutterId = this.props.match.params.shutterId;
    this.contactId = this.props.match.params.contactId;

    this.state = {
      loading: false,
      loadingMsg: "",
      delete: false,
      formAlert: "",
      resultMsg: "",
      showModal: false
    };
  }

  /*
    render後に呼ばれる
  */
  componentDidMount() {
    this.props.getContactDetail(this.contactId, this.shutterId)
  }

  render(){
    const data = this.props.contact.data;

    return(
      <Form className="card-body-common" onSubmit={(e) => {this.handleSubmit(e);}}>
        <Row noGutters={true} className="content-header">
          <Col xs="auto" className="d-flex align-items-end">
            <Link to={`/contact/list/${this.shutterId}`}
                  className="btn btn-common btn-outline-success">
              連絡先一覧
            </Link>
          </Col>
          <Col></Col>
          <Col xs="auto" className="d-flex align-items-end failure-message">{this.state.resultMsg}</Col>
          {this.formButtons()}
        </Row>

        <div className="content-body">
          <div className="contact-form">
            <Form.Group as={Row} className="mb-3" controlId="formCompany">
              <Form.Label column sm="2">
                会社名
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text" className={this.state.formAlert}
                              defaultValue={data.company}/>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formDepartment">
              <Form.Label column sm="2">
                部署名
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text" defaultValue={data.department}/>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPerson">
              <Form.Label column sm="2">
                担当者名
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text" className={this.state.formAlert}
                              defaultValue={data.person_name}/>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formTel">
              <Form.Label column sm="2">
                電話番号
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text" defaultValue={data.tel}/>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formFax">
              <Form.Label column sm="2">
                FAX番号
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text" defaultValue={data.fax}/>
              </Col>
            </Form.Group>
          </div>
        </div>

        <ModalNotice showModal={this.state.showModal}
                     bodyData="連絡先を削除します。よろしいですか？"
                     btnDoneName="削除"
                     btnDoneColor="danger"
                     done={() => {this.onClickDelete()}}
                     cancel={() => {this.onClickHideModal()}}/>
      </Form>
    );
  }

  formButtons(){
    const data = this.props.contact.data.contact_id;

    if(data === undefined){ return null; }
    if(this.state.delete){ return null; }

    let form = (
      <>
        <Col xs="auto">
          <Button variant="success" type="submit" className="btn-common">
            保存
          </Button>
        </Col>
        <Col xs="auto" style={{marginLeft: "10px"}}>
          <Button variant="danger" className="btn-common"
                  onClick={() => this.onClickShowModal()}>
            削除
          </Button>
        </Col>
      </>
    )

    if(this.state.loading){
      form = (
        <div className="loading-text d-flex align-items-center loading">
          <div className="spinner-border text-success" role="status"></div>
          <div className="loading-title">{this.state.loadingMsg}</div>
        </div>
      )
    }

    return form;
  }

  handleSubmit(e) {
    // 再リロード防止
    e.preventDefault();

    const company = e.target.formCompany.value.trim();
    const department = e.target.formDepartment.value.trim();
    const person = e.target.formPerson.value.trim();
    const tel = e.target.formTel.value.trim();
    const fax = e.target.formFax.value.trim();

    if(company === "" && person === ""){
      alert("会社名もしくは担当者名を記入してください");
      this.setState({formAlert: "form-alert"});
      return;
    }

    this.setState({
      loading: true, loadingMsg: "更新しています。しばらくお待ちください..."
    });

    const params = new URLSearchParams();
    params.append('contact_id', this.contactId);
    params.append('shutter_id', this.shutterId);
    params.append('company', company);
    params.append('department', department);
    params.append('person_name', person);
    params.append('tel', tel);
    params.append('fax', fax);
    
    const callback = (data) => {
      if(data.success){
        this.props.history.push(`/contact/info/${this.shutterId}/${data.data.contact_id}`);
      }else{
        this.setState({
          loading: false,
          resultMsg: "保存に失敗しました。設定内容を確認してください"
        });
      }
    }

    this.props.save(Number(this.contactId), params, callback);
  }

  onClickDelete(){
    this.setState({
      showModal: false, loading: true,
      loadingMsg: "削除しています。しばらくお待ちください..."
    });

    const callback = (data, message) => {
      this.setState({
        loading: false, delete: data.success, resultMsg: message
      });
    }

    this.props.delete(this.contactId, this.shutterId, callback);
  }

  onClickShowModal(data){
    this.setState({ showModal: true });
  }

  onClickHideModal(){
    this.setState({ showModal: false });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactEdit);
