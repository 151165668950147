import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/reportAction';

class YearlyPiData extends Component {
  render() {
    const piData = this.props.report.piData;

    return (
      <div className="content-report-data"
           style={{height:`${this.props.contentH}px`}}>
        <table className="table-sm rp-table">
          <tbody>
            <tr className="rp-header-record">
              <td className="rp-h-item-title">
                <div className="rp-h-cell"></div>
                <div className="rp-h-cell l-b-1"></div>
                <div className="rp-h-cell l-b-1">累計</div>
                <div className="rp-h-cell l-b-1">年計</div>
                <div className="rp-h-cell"></div>
                <div className="rp-h-cell l-b-1"></div>
              </td>

              { this.headerData(piData) }
            </tr>

            { this.monthlyData(piData) }
          </tbody>
        </table>
      </div>
    );
  }

  headerData(piData){
    return(
      piData.map(pi => {
        const unit  = pi.unit !== null ? pi.unit : "-";
        const total = pi.total !== null ? pi.total : "-";
        const yVal = pi.value !== null ? pi.value : "-";
        const max  = pi.max !== null ? pi.max : "-";
        const min  = pi.min !== null ? pi.min : "-";
        const ave  = pi.ave !== null ? pi.ave : "-";

        return(
          <td key={pi.item_no} className="rp-h-data">
            <div className="rp-h-cell rp-h-d-w-pi rp-h-d-title">{pi.name}</div>
            <div className="rp-h-cell rp-h-d-item l-b-1">{unit}</div>
            <div className="rp-h-cell rp-h-d-item l-b-1">{total}</div>
            <div className="rp-h-cell rp-h-d-item">{yVal}</div>
            <Row noGutters={true} className="rp-h-cell rp-h-d-category">
              <Col xm={4} >最大</Col>
              <Col xm={4} >最小</Col>
              <Col xm={4} >平均</Col>
            </Row>

            <Row noGutters={true} className="rp-h-cell rp-h-d-val l-b-1">
              <Col xm={4} >{max}</Col>
              <Col xm={4} >{min}</Col>
              <Col xm={4} >{ave}</Col>
            </Row>
          </td>
        )
      })
    );
  }

  monthlyData(piData){
    const mdata = [];
    let backColor = "";

    for(let m = 0; m < 12; m++){
      const tdData = [];
      let date = "-"

      piData.forEach((pi, i) => {
        const mpi = pi.monthly_data[m]
        if(mpi === undefined) return;

        if(i === 0) {
          if(m === 0 || mpi.month === 1){
            date = `${mpi.year}年${mpi.month}月`
          }else{
            date = `${mpi.month}月`
          }
        }

        const value  = mpi.value !== null ? mpi.value : "-";

        tdData.push(
          <td key={`${pi.item_no}-${mpi.month}`}>{value}</td>
        )
      })
      if(tdData.length === 0) continue;
      if(m % 4 === 0){
        backColor = backColor === "" ? "change-bk-color" : "";
      }

      mdata.push(
        <tr key={m} className={`rp-p-data ${backColor}`}>
          <td>{date}</td>
          {tdData}
        </tr>
      );
    }

    return mdata;
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(YearlyPiData);
