import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/topAction';
import { getTodayString } from 'modules/utils';
import ItemAiTrend from './ItemAiTrend';
import ItemDiTrend from './ItemDiTrend';
import ItemSensorData from './ItemSensorData';

const DATA_TYPE_NO_DATA  = 0;
const DATA_TYPE_DI_TREND = 1;
const DATA_TYPE_AI_TREND = 2;
const DATA_TYPE_SENSOR_DATA = 3;

class Top extends Component {
  constructor(props) {
    super(props);

    this.enableWebSocket = false;
    this.dateStr = getTodayString();
  }

  /*
    render終了時に呼ばれる
  */
  componentDidMount() {
    this.props.getDisplayInfoList();
  }

  render(){
    const items = this.props.top.list;

    return(
      <div className="body-content">
          <div className="top-content row no-gutters">
            {
              items.map((data, i) => {
                return( this.topContents(data, i) )
              })
            }
          </div>
      </div>
    );

  }

  topContents(data, i){
    let enableReload = this.props.top.enableReload[data.imsi];

    switch(data.display_type) {
      case DATA_TYPE_AI_TREND:
        return(
          <ItemAiTrend shutterId={data.id} imsi={data.imsi} dataNo={data.data_no} key={i}
                       name={data.name}  enableReload={enableReload}
                       dataName={data.data_name} unit={data.unit}
                       startDate={this.dateStr} endDate={this.dateStr}/>
        )
      case DATA_TYPE_DI_TREND:
        return(
          <ItemDiTrend shutterId={data.id} imsi={data.imsi} dataNo={data.data_no} key={i}
                       dataName={data.data_name} name={data.name} enableReload={enableReload}
                       startDate={this.dateStr} endDate={this.dateStr}/>
        )
      case DATA_TYPE_SENSOR_DATA:
        return(
          <ItemSensorData shutterId={data.id} imsi={data.imsi} dataNo={data.data_no} key={i}
                          name={data.name} unit={data.unit} dataName={data.data_name}/>
        )
      case DATA_TYPE_NO_DATA:
        return(
          <div className="col-sm-3" key={i}></div>
        )
      default:
        return null;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Top);
