import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/reportAction';
import SelectDate from './SelectDate';
import ContentReportData from './ContentReportData';

class Report extends Component {
  render() {
    const shutterId = this.props.match.params.shutterId;

    return (
      <>
        <div className="card main-contents-layout report-header-layout">
          <div className="card-header card-header-common">
            データ帳票
          </div>

          <SelectDate shutterId={shutterId}/>
        </div>

        <div className="card main-contents-layout">
          <div className="card-body-common">
            <ContentReportData shutterId={shutterId}/>
          </div>
        </div>
      </>
    );
  }

  /*
    Component終了時に呼ばれる
  */
  componentWillUnmount(){
    this.props.clearReporCacheData();
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Report);
