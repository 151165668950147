import { store } from 'modules/store.js';
import { instance as http } from 'modules/httpConnection'

export function mapStateToProps(state) {
  return state;
}

export function mapDispatchToProps(dispatch) {
  return {
    /*
      遠隔設定情報を取得
    */
    getRemoteSettingData: (shutterId) => {
      getRemoteSettingData(shutterId);
    },

    /*
      遠隔設定の実行
    */
    execSettingData: (shutterId, address, value) => {
      store.dispatch( {
        type: 'EXEC_SETTING_DATA',
        loading: true,
        address: address,
        showModal: false
      });

      const modelType = store.getState().setting.modelType
      const params = new URLSearchParams();
      params.append('address', address);
      params.append('value', value);
      params.append('model_type', modelType);

      http.post(`settings/exec_setting/${shutterId}`, params)
        .then((response) => {
          const data = response.data;

          store.dispatch( {
            type: 'EXEC_SETTING_DATA_RESULT',
            result: data.result,
            dataList: data.settings,
            loading: false
          });
        })
        .catch((data) => {
          console.error(data);
        });
    },

    /*
      遠隔設定を行うダイアログ表示
    */
    showModalSetting: (address, showModal) => {
      store.dispatch( {
        type: 'SHOW_SETTING_MODAL',
        address: address,
        showModal: showModal
      });
    },

    /*
      遠隔設定後の結果ダイアログ表示の終了
    */
    hideModalResult: () => {
      store.dispatch( {
        type: 'SETTING_MODAL_RESULT',
        showModalResult: false
      });
    },

    selectDataItem: (value) => {
      store.dispatch( {
        type: 'SETTING_MODAL_SELECT_ITEM',
        selectItem: value
      });
    }
  }
}

export function getRemoteSettingData(shutterId){
  store.dispatch({type: 'CLEAR_SETTING_DATA'});

  http.post('settings/remote_data/' + shutterId)
    .then((response) => {
      store.dispatch( {
        type: 'SETTING_DATA',
        loading: false,
        dataList: response.data
      });
    })
    .catch((data) => {
      console.error(data);
    });
}
