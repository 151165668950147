import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/maintenanceAction';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import MimeTypeNobody from 'images/mime_type_nobody.png'
import * as module from 'modules/utils';
import * as cookies from 'modules/cookies.js';

class MaintenanceCreate extends Component {
  constructor(props) {
    super(props);
    this.shutterId = this.props.match.params.shutterId;
    this.today = module.getTodayString("yyyy-mm-dd");
    this.userName = cookies.get("userName");
    this.maxFileCount = 6
    this.state = {
      photoFiles:[],
      loading: false,
      resultMsg: "",
      formDateAlert: "",
      formTitleAlert: "",
      enableAddFile: true
    };
  }

  render(){
    const addFileBtn = this.state.enableAddFile ? "btn-success" : "btn-secondary";
    return(
      <Form className="card-body-common" onSubmit={(e) => {this.handleSubmit(e);}}>
        <Row noGutters={true} className="content-header">
          <Col xs="auto">
            <Link to={`/maintenance/list/${this.shutterId}`}
                  className="btn btn-common btn-outline-success">
              メンテナンス一覧
            </Link>
          </Col>
          <Col></Col>
          <Col xs="auto" className={`d-flex align-items-end failure-message`}>
            {this.state.resultMsg}
          </Col>

          { this.formButtons() }
        </Row>

        <div className="content-body">
          <div className="maintenance-form">
            <Row noGutters={true} className="justify-content-end mb-2">
              <Col sm="auto" className="title-label">登録者</Col>
              <Col sm="auto">{module.sliceText(this.userName, 15)}</Col>
            </Row>

            <Form.Group as={Row} noGutters={true} controlId="formDate">
              <Form.Label column sm="2">
                日付
              </Form.Label>
              <Col sm="6" md="5" lg="4" xl="3">
                <Form.Control type="date" className={this.state.formDateAlert}
                              defaultValue={this.today}/>
              </Col>
            </Form.Group>

            <Form.Group as={Row} noGutters={true} controlId="formTitle">
              <Form.Label column sm="2">
                タイトル
              </Form.Label>
              <Col sm="9">
                <Form.Control type="text" className={this.state.formTitleAlert} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} noGutters={true} className="mb-3" controlId="formCost">
              <Form.Label column sm="2">
                費用
              </Form.Label>
              <Col sm="6" md="5" lg="4" xl="3">
                <Form.Control type="number" />
              </Col>
              <Col className="form-cost-unit">
                円
              </Col>
            </Form.Group>

            <Row noGutters={true} className="mb-2">
              <Col>
                <Form.Group controlId="formNote" style={{marginRight: "10px"}}>
                  <Form.Label className="title-note-label">メンテナンス内容</Form.Label>
                  <Form.Control as="textarea"  className="form-data-note" rows={10}/>
                </Form.Group>
              </Col>

              <Col>
                <Row noGutters={true} >
                  <Col className="title-label">添付資料（6ファイルまで）</Col>
                  <Col sm="auto">
                    <Form.Group className="form-add-files" controlId="formFile">
                      <Form.Label style={{marginBottom:"0"}}>
                        <div className={`btn-add-file btn ${addFileBtn}`}>
                          ファイル追加
                        </div>
                        <Form.Control type="file" className="select-file-data"
                                      disabled={!this.state.enableAddFile}
                                      onChange={(e) => this.selectPhotoData(e)}/>

                      </Form.Label>
                    </Form.Group>

                  </Col>
                </Row>
                {this.formFileList()}
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    );
  }

  formButtons(){
    let form = (
      <Col xs="auto">
        <Button variant="success" type="submit" className="btn-common">
          新規登録
        </Button>
      </Col>
    )

    if(this.state.loading){
      form = (
        <div className="loading-text d-flex align-items-center loading">
          <div className="spinner-border text-success" role="status"></div>
          <div className="loading-title">新規作成しています。しばらくお待ちください...</div>
        </div>
      )
    }
    return form;
  }

  formFileList(){
    const list = []

    this.state.photoFiles.forEach((file, i) => {
      let fileIcon = MimeTypeNobody
      if(file.file.type.includes("image")){
        fileIcon = file["data"]
      }

      list.push(
        <tr key={i}>
          <td>
            <Row noGutters={true} className="new-file-data-row">
              <Col sm="auto" className="mime-type-file-icon-col">
                <img className="mime-type-file-icon" src={fileIcon} alt="FileIcon"/>
              </Col>
              <Col className="file-name" sm="auto">
                {file.file.name}
              </Col>
              <Col></Col>
              <Col sm="auto" className="delete-icon-col"
                   onClick={() => this.removePhotoData(i) }>
                ×
              </Col>
            </Row>
          </td>
        </tr>
      );
    });

    return(
      <table className="table table-hover table-common maintenance-file-list-table">
        <tbody>{list}</tbody>
      </table>
    )
  }

  selectPhotoData(e){
    if(!this.state.enableAddFile) return;
    if(e.target.files.length <= 0) return;

    const file = e.target.files[0]
    const reader = new FileReader()
    const photoFiles = this.state.photoFiles;

    reader.onload = (e) => {
      photoFiles.push({file: file, data: e.target.result})
      const enableAddFile = photoFiles.length < this.maxFileCount;

      this.setState({ photoFiles: photoFiles, enableAddFile: enableAddFile })
    };
    reader.readAsDataURL(file)
  }

  removePhotoData(i){
    const photoFiles = this.state.photoFiles;

    photoFiles.splice(i, 1);
    const enableAddFile = photoFiles.length < this.maxFileCount;

    this.setState({ photoFiles: photoFiles, enableAddFile: enableAddFile});
  }

  handleSubmit(e) {
    // 再リロード防止
    e.preventDefault();

    const date  = e.target.formDate.value.trim().replace(/-/g, '');
    const title = e.target.formTitle.value.trim();
    const cost  = e.target.formCost.value.trim();
    const note  = e.target.formNote.value.trim();
    const files = this.state.photoFiles;

    if(date.length < 8){
      alert("日付を記入してください");
      this.setState({formDateAlert: "form-alert"});
      return;
    }

    if(title === ""){
      alert("タイトルを記入してください");
      this.setState({formTitleAlert: "form-alert"});
      return;
    }

    this.setState({loading: true, resultMsg: ""});

    const params = new FormData()
    files.forEach(data => {
      params.append('files[]', data["file"]);
    });
    params.append('shutter_id', this.shutterId);
    params.append('date', date);
    params.append('title', title);
    params.append('cost', cost);
    params.append('note', note);

    const callback = (data) => {
      if(data.success){
        this.props.history.push(`/maintenance/edit/${this.shutterId}/${data.data.maintenance_id}`);
      }else{
        this.setState({
          loading: false,
          resultMsg: "新規登録に失敗しました。設定内容を確認してください"
        });
      }
    }

    this.props.save(0, params, callback);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceCreate);
