import React, { Component } from 'react';
import { Row, Col, Form, Button} from 'react-bootstrap';
import Logo from 'images/logo.png'
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/authAction';
import { Redirect } from 'react-router-dom';
import { withTranslation, WithTranslation } from "react-i18next";
import * as cookies from 'modules/cookies.js';

class LoginComponent extends Component<WithTranslation> {
  constructor(props) {
    super(props);
    document.getElementById('main-body').style.backgroundColor = "white";
  }

  render() {
    const { t } = this.props;
    const loginFailureForm = this.loginFailureForm(t);

    if(cookies.verifyCredentials()){
      return (<Redirect to="/main_map" />);
    }else{
      return (
        <div className="login-layout" align="center">
          <Row noGutters={true}>
            <Col xs={2} md={3} lg={4}></Col>
            <Col xs={8} md={6} lg={4} className="login-box">
              <img src={Logo} width={"80%"} alt=""/>
              <Row noGutters={true}>
                <Col xs={1}></Col>
                <Col xs={10}>
                  <Form onSubmit={(e) => {this.handleSubmit(e);}}>
                    <Form.Group controlId="formUserId" className="login-field">
                      <Form.Label>{t("login.userId")}</Form.Label>
                      <Form.Control type="text"/>
                    </Form.Group>

                    <Form.Group controlId="formPassword" className="login-field">
                      <Form.Label>{t("login.password")}</Form.Label>
                      <Form.Control type="password" autoComplete="off"/>
                    </Form.Group>
                    <Button variant="success" type="submit">
                      {t("login.submit")}
                    </Button>
                  </Form>

                  {loginFailureForm}

                  <div className="hp-link-text">
                    <a href="https://www.komatsuelec.co.jp/monban/index.html"
                       rel="noreferrer noopener"
                       target="_blank">{t("login.website")}</a>
                  </div>

                </Col>
                <Col xs={1}></Col>
              </Row>
            </Col>
            <Col xs={2} md={3} lg={4}></Col>
          </Row>
        </div>
      );
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const account = e.target.formUserId.value.trim();
    const password = e.target.formPassword.value.trim();

    const callback = (data) => {
      this.props.getCompanyList();
    }
    this.props.login(account, password, callback);
  }

  loginFailureForm(t){
    let form;
    if(this.props.auth.failureLogin){
      form = (
        <div className="login-failure">
          {t("login.failureMsg")}
        </div>
      );
    }

    return form;
  }
}

const Login = withTranslation()(LoginComponent);
export default connect(mapStateToProps, mapDispatchToProps)(Login);
