const MAX_SECONDS = 86400;

class DriveGraph {
  constructor(canvasId) {
    this.canvasId = canvasId;
    this.footerSpace = 1;
  }

  draw(data) {
    const canvas = document.getElementById(this.canvasId);
    if(canvas === null){return}
    if (canvas.getContext) {
      //ここで具体的な描画内容を指定する
      this.width = canvas.clientWidth;
      this.height = canvas.clientHeight;
      this.fontSize = this.height * 0.136;
      this.graphHeight = this.height - this.fontSize;

      const context = canvas.getContext('2d');

      canvas.width = this.width;
      canvas.height = this.height;
      context.fillStyle = "#dbefff";
      context.fillRect(0,0, this.width, this.graphHeight);

      this.drowData(context, data);
      this.drowTimeField(context);
    }
  }

  // private

  /*
    開閉データのグラフ描画
  */
  drowData(context, data){
    if(data === undefined || data === null) return

    context.fillStyle = "#0d47a0";
    
    data.forEach((item, i) => {
      if(item.status){
        const x = (item.seconds / MAX_SECONDS) * this.width;
        const valueWidth = (item.duration / MAX_SECONDS) * this.width;

        context.fillRect(x, 0, valueWidth, this.graphHeight);
      }
    });
  }

  /*
    横軸の時刻項目の描画
  */
  drowTimeField(context){
    const fontTop = this.graphHeight + this.footerSpace;
    const stepW = this.width / 12;

    context.fillStyle = "#0d47a0";
    context.textBaseline = "top";
    context.font = `${this.fontSize}px 'ＭＳ ゴシック'`;

    let fontW = 0;
    for (let i = 0; i <= 12; i++) {
      fontW = i * stepW;
      if(i === 0){
        context.textAlign = "left";
      } else if( i === 12 ){
        context.textAlign = "right";
      } else{
        context.textAlign = "center";
      }
      context.fillText(i * 2, fontW, fontTop);
    }
  }
}

export default DriveGraph;
