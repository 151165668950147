const END_POINT = "wss://monban-iot.komatsuelec.co.jp/cable";
//const END_POINT = "wss://namuchi-dev4.uzyyn647ae.ap-northeast-1.elasticbeanstalk.com/cable";
//const END_POINT = "ws://vega.komatsuelec.co.jp:4553/cable";
let ws;

class MonbanWebSocket extends WebSocket{
  constructor(channel) {
    super(END_POINT);
    this.channel = channel;

    //エラーが発生した場合
    this.onerror = function(error) {
      console.log("WebSocket:onerror!");
      console.log(error);

      this.close();
    };

    //通信が切断された場合
    this.onclose = function() {
      console.log("WebSocket:onclose!");
    };
  }

  subscribe(imsi){
    if(imsi === undefined || imsi === null) return;
    console.log("subscribe:channel:"+this.channel + " imsi:"+imsi);
    let identifier = {channel: this.channel, imsi: imsi}
    let request = {command: "subscribe",
                   identifier: JSON.stringify(identifier) };

    this.send(JSON.stringify(request));
  }

  unSubscribe(imsi){
    if(imsi === undefined) return;
    console.log("unSubscribe:channel:"+this.channel + " imsi:"+imsi);
    let identifier = {channel: this.channel, imsi: imsi}
    let request = {command: "unsubscribe",
                   identifier: JSON.stringify(identifier) };
    this.send(JSON.stringify(request));
  }
}

export function initWebSocket(channel) {
  if(ws !== undefined) { ws.close(); }
  ws = new MonbanWebSocket(channel);

  return ws;
}
