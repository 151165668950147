import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import * as cookies from 'modules/cookies.js';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/authAction';

class Logout extends Component {
  constructor(props) {
    super(props);

    cookies.clearAuthData();
    this.props.logout();
  }

  render() {
    return (<Redirect to="/login" />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
