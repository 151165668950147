import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/trendAction';
import aiTrendGraph from 'modules/aiTrendGraph';

class AiTrend extends Component {
  componentDidMount() {
    this.chart = new aiTrendGraph(this.node, this.props.stepMin, this.props.height);
  }

  render(){
    let name = "";
    let unit = "";
    const aiData = this.props.aiData;
    if(this.props.name){ name = `${this.props.name} : `;}
    if(aiData && aiData.ai_item){
      const aiItems = aiData.ai_item.filter(value => {
        return value["data_no"] === this.props.dataNo;
      })

      if(aiItems.length && aiItems[0].unit !== null && aiItems[0].unit.length){
        unit = `(${aiItems[0].unit})`;
      }

      if(this.chart){
        this.chart.draw(aiData, this.props.targetDate,
                        this.props.dataNo, this.props.enableReload);
      }
    }

    return(
      <div className="col-sm-6">
        <div className="card item-ai-trend">
          <div className="card-header item-ai-trend-title">
            {`${name}${this.props.dataName}${unit}`}
          </div>

          <div className="card-body-common">
            <div className="ai-trend-chart" id="ai-trend-chart"
                 ref={node => (this.node = node)} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AiTrend);
