const initialState = {
  failureLogin: false,
  logined: false,
  uid: "",
  name: ""
}

export default function(state = initialState, action) {
  switch(action.type) {
    case 'LOGIN':
      return {
        ...state,
        failureLogin: !action.logined,
        logined: action.logined,
        uid: ""
      };
    case 'LOGOUT':
      console.log("reducer:LOGOUT");
      return {
        ...state,
        failureLogin: false,
        logined: false,
        uid: ""
      };
    default:
      return state;
  }
}
