import { store } from 'modules/store.js';
import { successDownload, download } from 'modules/utils';
import { instance as http, instanceImage as http2 } from 'modules/httpConnection'
import { clearShutterPopover, clearMainMapInfo } from 'modules/actions/mainMapAction';

export function mapStateToProps(state) {
  return state;
}

export function mapDispatchToProps(dispatch) {
  return {
    /*
      平面図上のすべてのバルーンを非表示
    */
    clearShutterPopover: () => {
      clearShutterPopover();
    },

    /*
      工場面図データをクリア
    */
    clearMainMapInfo: () => {
      clearMainMapInfo();
    },

    /*
      点検情報リストを取得
    */
    getInspectionList: (shutterId) => {
      getInspectionList(shutterId);
    },

    /*
      点検情報リストを取得
    */
    getReportItems: (shutterId, callback) => {
      http.post(`inspections/report_items/${shutterId}`)
        .then((response) => {
          callback(response.data);
        })
        .catch((data) => {
          console.error(data);
        });
    },

    /*
      点検報告書内容を取得
    */
    getInspectionDetail: (inspectionId, callback) => {
      http.post(`inspections/detail/${inspectionId}`)
        .then((response) => {
          response.data.details.forEach(d => {
            d.files.forEach(f => {
              if(f.has_file){
                getInspectionPhoto(f.file_id);
              }
            });
          });

          callback(response.data);
        })
        .catch((data) => {
          console.error(data);
        });
    },

    /*
      設備点検報告書の保存
    */
    save: (inspectionId, params, callback) => {
      http.post(`inspections/save/${inspectionId}`, params)
        .then((response) => {
          store.dispatch( {
            type: 'INSPECTION_SAVE',
            inspectionId: Number(inspectionId)
          });

          callback(response.data)
        })
        .catch(data => {
          console.error(data);
        });
    },

    /*
      点検画像のダウンロード
    */
    download: (inspectionId, shutterId, fileName, callback) => {
      const params = new FormData();
      params.append('shutter_id', shutterId);

      http2.post(`inspections/download/${inspectionId}`, params)
        .then((response) => {
          const success = successDownload(response.data);

          callback(success);
          if(success) download(response.data, fileName);
        })
        .catch((data) => {
          console.error(data);
        });
    },

    /*
      点検報告書の削除
    */
    delete: (inspectionId, shutterId, callback) => {
      const params = new FormData();
      params.append('shutter_id', shutterId);

      http.post(`inspections/delete/${inspectionId}`, params)
        .then((response) => {
          callback(response.data)
        })
        .catch((data) => {
          console.error(data);
        });
    }

  }
}

export function getInspectionList(shutterId){
  store.dispatch( {type: 'CLEAR_INSPECTION_DATA'} );

  http.post(`inspections/list/${shutterId}`)
    .then((response) => {
      store.dispatch( {
        type: 'INSPECTION_LIST',
        shutterId: shutterId,
        list: response.data
      });
    })
    .catch((data) => {
      console.error(data);
    });
}

function getInspectionPhoto(fileId, params={}){
  http2.post(`inspections/photo/${fileId}`, params)
    .then(response => {
      if(response.data !== null){
        const blob = new Blob([ response.data ], { type: "image/*" });
        if(blob.size > 0){
          store.dispatch( {
            type: `INSPECTION_PHOTO_DATA`,
            fileId: Number(fileId),
            photoData: blob
          });
        }
      }
    })
    .catch(data => {
      console.error(data);
    });
}
