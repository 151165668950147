const initialState = {
  loading: true,
  contentsLoading: true,
  factoryId: "",
  shutterId: "",
  newShutters: [],
  shutters: [],
  analogs: [],
  pulses: [],
  flowMapImage: undefined,
  showPopover: {},
  photoData: undefined,
  photoInfo: {},
  showModal: false,
  modalSelectYear: 0,
  modalSelectMonth: 0,
  modalPhotoData: undefined,
  modalData: {},
  positionMode: false,
  ePos: {},
  mapPosLoading: false
}

export default function(state = initialState, action) {
  let shutters = [];
  let index = -1;

  switch(action.type) {
    case 'FLOW_MAP_DATA':
      return {
        ...state,
        loading: false,
        factoryId: action.factoryId,
        shutters: action.shutters,
        analogs: action.analogs,
        pulses: action.pulses,
        modalPhotoData: undefined,
        modalData: {},
        positionMode: false,
        ePos: {},
        mapPosLoading: false
      };
    case 'FLOW_MAP_IMAGE':
      return {
        ...state,
        flowMapImage: action.flowMapImage
      };
    case 'SHOW_POPOVER':
      return {
        ...state,
        shutterId: action.shutterId,
        showPopover: action.showPopover,
        modalPhotoData: undefined,
        modalData: {}
      };
    case 'CLEAR_MAINMAP_INFO':
      return {
        ...state,
        loading: true,
        flowMapImage: undefined
      };
    case 'CLEAR_POPOVER':
    case 'SHOW_SETTING_MODAL':
      return { ...state, showPopover: {} };
    case 'MAINMAP_CHANGE_MAP_MODE':
      return {
        ...state,
        positionMode: action.positionMode,
        ePos: (action.positionMode) ? state.ePos : {},
        mapPosLoading: false
      };
    case 'MAINMAP_MOVE_MAP_POSITION':
      return { ...state, ePos: action.ePos };
    case 'MAINMAP_SAVE_MAP_POSITION_LOADING':
      return { ...state, mapPosLoading: true };
    case 'MAINMAP_SAVE_MAP_POSITION_RESULT':
      if(action.success){
        shutters = state.shutters;
        const shutter = shutters.find(s => s.id === action.shutterId);

        if(shutter){
          shutter.figx = action.x;
          shutter.figy = action.y;
        }
      }
      return {
        ...state,
        shutters: shutters,
        mapPosLoading: false,
        positionMode: false,
        ePos: {}
      };
    case 'MAINMAP_FLOW_SHUTTER_PHOTO':
      return { ...state, photoData: action.photoData };
    case 'MAINMAP_LIST_SHUTTER_PHOTO':
      if(action.photoData){
        const photoInfo = state.photoInfo;
        photoInfo[action.shutterId] = action.photoData

        return {...state, photoInfo: photoInfo};
      }

      return state;
    case 'RECV_SHUTTER_STATUS':
      shutters = state.shutters;
      index = shutters.findIndex(shutter => shutter.id === action.shutterId);

      if(index > -1){
        shutters[index].alert_flg = action.alertFlg;
        shutters[index].auto_mode = action.autoModeFlg;
        shutters[index].total_drive_count = action.totalDriveCount;
        shutters[index].drive_status = action.driveStatusKey;
        shutters[index].drive_count = action.driveCount;
        shutters[index].drive_time = action.driveTime;
        shutters[index].invalid_data = false;
      }

      return {
        ...state,
        shutters: shutters
      };
    case 'UPDATE_SENSOR_DATA_FOR_MAP':
      const analogs = state.analogs;
      const ai_data = action.sensor_data.analogs;

      ai_data.forEach(data =>{
        const index = analogs.findIndex(ai =>
          (ai.sensor_id === data.sensor_id) && (ai.data_no === data.data_no)
        );

        if(index > -1){
          analogs[index].value = data.value
        }
      });

      return { ...state, analogs: analogs };
    case 'MAINMAP_SHOW_MODAL':
      return {...state,
        shutterId: action.shutterId,
        showModal: true,
        modalSelectYear: 0,
        modalSelectMonth: 0,
        modalPhotoData: undefined,
        modalData: {},
        showPopover: {}
      };
    case 'MAINMAP_HIDE_MODAL':
      return {...state, showModal: false};
    case 'MAINMAP_MODAL_SHUTTER_SPEC':
      return {
        ...state,
        shutterId: action.shutterId,
        modalSelectYear: action.data.construction_year,
        modalSelectMonth: action.data.construction_month,
        modalData: action.data,
        showPopover: {}
      };
    case 'MAINMAP_MODAL_SAVE_SHUTTER_SPEC':
      shutters = state.shutters;
      index = shutters.findIndex(shutter => shutter.id === action.data.id);

      if(index > -1){
        shutters[index].name = action.data.name;
        shutters[index].width = action.data.width;
        shutters[index].height = action.data.height;
        shutters[index].product_no = action.data.product_no;
        shutters[index].product_type = action.data.product_type;
        shutters[index].construction_date = action.data.construction_date;
        shutters[index].maker = action.data.maker;
        shutters[index].total_drive_count = action.data.total_drive_count;
      }

      return {...state, shutters: shutters, modalPhotoData: undefined};
      //return {...state, shutters: shutters };
    case 'MAINMAP_MODAL_CREATE_SHUTTER':
      state.shutters.push(action.data);
      state.newShutters.push(action.data.id);

      return {
        ...state,
        shutterId: action.data.id,
        shutters: state.shutters,
        modalSelectYear: action.data.construction_year,
        modalSelectMonth: action.data.construction_month,
        modalData: action.data
      };
    case 'MAINMAP_MODAL_DELETE_SHUTTER':
      return {
        ...state, shutterId: action.selectedId, shutters: action.shutters
      };
    case 'MAINMAP_MODAL_SHUTTER_PHOTO':
      return {...state, modalPhotoData: action.photoData};
    case 'MAINMAP_MODAL_SELECT_YEAR':
      return {...state, modalSelectYear: action.year};
    case 'MAINMAP_MODAL_SELECT_MONTH':
      return {...state, modalSelectMonth: action.month};
    default:
      return state;
  }
}
