const DRIVE_STATUS_TAG = {
  init: "初期値",
  open: "開",
  opening: "上昇中",
  eco: "ECO",
  stop: "停止",
  closing: "下降中",
  close: "閉"
}
const OFFSET_GENERAL_DINO = 200

class BaseDevice{
  parseRawData(rData, ioMap, modeMap, driveStatus, resolution, g_dino = OFFSET_GENERAL_DINO) {
    let positionInfo = {key: "",status: "-"};
    let driveModeInfo = {status: "-", autoModeFlg: undefined};
    let ecoModeInfo = {ecoModeFlg: false, ecoMode: "OFF"};
    let sensorInfo = {};

    if(rData.status !== null){
      rData.status.forEach((val, i) => {
        const binValue = this.toBinData(val);
        if(ioMap['position'].includes(i)){
          positionInfo = this.parseDriveStatus(positionInfo, binValue, driveStatus[i]);
        }

        if(ioMap['driveMode'] === i){
          driveModeInfo = this.parseDriveMode(driveModeInfo, binValue, modeMap);
        }
        if(ioMap['ecoMode'] === i){
          ecoModeInfo = this.parseEcoMode(ecoModeInfo, binValue, modeMap);
        }

        if(ioMap['sensor'].includes(i)){
          binValue.split("").forEach((bVal, j) => {
            if(bVal === "1"){
              let addr = (resolution * i + j) + 1;
              sensorInfo[addr] = true;
            }
          });
        }
      });
    }

    if(rData.digitals !== null){
      rData.digitals.forEach((val, i) => {
        const binValue = this.toBinData(val);
        binValue.split("").forEach((bVal, j) => {
          if(bVal === "1"){
            let addr = j + g_dino;
            sensorInfo[addr] = true;
          }
        });
      });
    }

    let driveTime = "-";
    if(rData.drive_time.constructor === Number){
      driveTime = Math.floor(rData.drive_time / 60);
    }

    const errors = rData.errors !== null ? rData.errors : [];
    const res = {
      totalDriveCount: rData.analogs[0],
      driveCount: rData.drive_count,
      driveTime: driveTime,
      driveStatus: positionInfo.status,
      driveStatusKey: positionInfo.key,
      driveMode: driveModeInfo.status,
      ecoMode: ecoModeInfo.ecoMode,
      ecoModeFlg: ecoModeInfo.ecoModeFlg,
      autoModeFlg: driveModeInfo.autoModeFlg,
      sensorInfo: sensorInfo,
      errorCount: errors.length,
      errors: errors
    };

    return res;
  }

  /**
    binaryデータからシートの位置情報を解析して返す
  */
  parseDriveStatus(positionInfo, binValue, driveStatusKey){
    binValue.split("").forEach((bVal, i) => {
      if(bVal === "1"){
        const key = driveStatusKey[i];
        if(key){
          positionInfo["key"] = key;
          positionInfo["status"] = DRIVE_STATUS_TAG[key];
        }
      }
    });

    return positionInfo;
  }

  /**
    binaryデータから運転モード情報(自動・手動)を解析して返す
  */
  parseDriveMode(driveModeInfo, binValue, modeMap){
    if(modeMap["manual"] === undefined){
      driveModeInfo["status"] = "手動";
      driveModeInfo["autoModeFlg"] = false;
    }

    binValue.split("").forEach((bVal, i) => {
      if(bVal === "1"){
        if(i === modeMap["auto"]){
          driveModeInfo["status"] = "自動";
          driveModeInfo["autoModeFlg"] = true;
        }else if(i === modeMap["manual"]){
          driveModeInfo["status"] = "手動";
          driveModeInfo["autoModeFlg"] = false;
        }
      }
    });

    return driveModeInfo;
  }

  /**
    binaryデータからECOモード情報を解析して返す
  */
  parseEcoMode(ecoModeInfo, binValue, modeMap){
    if(modeMap["eco"] === undefined && modeMap["eco1"] === undefined &&
        modeMap["eco2"] === undefined){
      ecoModeInfo["ecoMode"] = "-";
      ecoModeInfo["ecoModeFlg"] = undefined;
    }

    binValue.split("").forEach((bVal, i) => {
      if(bVal === "1"){
        if(i === modeMap["eco"] || i === modeMap["eco1"] || i === modeMap["eco2"]){
          ecoModeInfo["ecoMode"] = "ON";
          ecoModeInfo["ecoModeFlg"] = true;
        }
      }
    });

    return ecoModeInfo;
  }

  parseIoStatus(binValue){

  }

  toBinData(n){
    return this.reverseStr(n.toString(2));
  }

  reverseStr(word){
    return word.split("").reverse().join("");
  }
}

export function convDriveStatus(status) {
  const tag = DRIVE_STATUS_TAG[status];
  if(tag === undefined) return "-";

  return tag;
}

export default BaseDevice;
