import MimeTypeDoc from 'images/mime_type_doc.png'
import MimeTypeDwg from 'images/mime_type_dwg.png'
import MimeTypeDxf from 'images/mime_type_dxf.png'
import MimeTypePdf from 'images/mime_type_pdf.png'
import MimeTypePpt from 'images/mime_type_ppt.png'
import MimeTypeTxt from 'images/mime_type_txt.png'
import MimeTypeXls from 'images/mime_type_xls.png'
import MimeTypeNobody from 'images/mime_type_nobody.png'

export const WEEKS = ["日","月","火","水","木","金","土"];

export function getTodayString(format = "yyyymmdd") {
  const dt = new Date();
  const y = dt.getFullYear();
  const m = ('00' + (dt.getMonth()+1)).slice(-2);
  const d = ('00' + dt.getDate()).slice(-2);
  return formatDateTime(`${y}${m}${d}`, format);
}

export function getNowDateTimeString(format = "yyyymmddHHMMDD") {
  const dt = new Date();
  const y = dt.getFullYear();
  const m = ('00' + (dt.getMonth()+1)).slice(-2);
  const d = ('00' + dt.getDate()).slice(-2);
  const hh = ('00' + dt.getHours()).slice(-2);
  const mm = ('00' + dt.getMinutes()).slice(-2);
  const ss = ('00' + dt.getSeconds()).slice(-2);
  return formatDateTime(`${y}${m}${d}${hh}${mm}${ss}`, format);
}

/*
  設備名称を返す
*/
export function shutterName(props) {
  const shutters = props.main_map.shutters;
  let name = "";
  if(shutters.length){
    const shutterId = Number(props.match.params.shutterId);
    const shutter = shutters.find(item => item.id === shutterId);
    if(shutter) name = shutter.name
  }
  return name
}

/*
  yyyymmddHHMMDDの日時データをformatに沿った値に変換する
  format => yyyy/mm/dd HH:MM:SS
*/
export function formatDateTime(dateTime, format = "") {
  if(dateTime === undefined || dateTime === null) return "";
  if(dateTime.length < 4) return dateTime;

  if(dateTime.length >= 4 && format.includes("yyyy")){
    format = format.replace( 'yyyy', dateTime.substr(0, 4));
  }
  if(dateTime.length >= 6 && format.includes("mm")){
    format = format.replace( 'mm', dateTime.substr(4, 2));
  }
  if(dateTime.length >= 8 && format.includes("dd")){
    format = format.replace( 'dd', dateTime.substr(6, 2));
  }
  if(dateTime.length >= 10 && format.includes("HH")){
    format = format.replace( 'HH', dateTime.substr(8, 2));
  }
  if(dateTime.length >= 12 && format.includes("MM")){
    format = format.replace( 'MM', dateTime.substr(10, 2));
  }
  if(dateTime.length >= 14 && format.includes("SS")){
    format = format.replace( 'SS', dateTime.substr(12, 2));
  }
  return format;
}

export function isPassTimeTrendData(dataTime, stepMin = 1){
  if(dataTime === undefined || dataTime === null){
    return true;
  }

  const passTime = new Date() - dataTime;
  // データ取得してから、STEP_MIN分以上経過している場合はtrueを返す
  if(passTime >= stepMin * 60 * 1000){
    return true;
  }else{
    return false;
  }
}

export function sliceText(text, size) {
  if(text === undefined) return "";
  return text.length > size ? (text).slice(0, size - 3) + "..." : text;
}

// value=値 size=桁数
export function zeroPadding(value, size){
	return ( Array(size).join('0') + value ).slice(-size);
}

export function fileTypeIcon(item){
  let icon = MimeTypeNobody
  switch(item.file_type){
    case "doc":
      icon = MimeTypeDoc
      break;
    case "dwg":
      icon = MimeTypeDwg
      break;
    case "dxf":
      icon = MimeTypeDxf
      break;
    case "pdf":
      icon = MimeTypePdf
      break;
    case "ppt":
      icon = MimeTypePpt
      break;
    case "txt":
      icon = MimeTypeTxt
      break;
    case "xls":
      icon = MimeTypeXls
      break;
    default:
      icon = MimeTypeNobody
      break;
  }

  return icon;
}

export function successDownload(data){
  return (data.type !== "application/json");
}

export function download(data, fileName){
  const blob = new Blob([ data ]);
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  a.remove();
}

export function isSmartWidth(width = 640) {
  return (window.innerWidth <= width);
}

export function isToucheMobile() {
  return 'ontouchend' in document
}

export function shutterSize(data){
  let size = "";
  if(data.width !== null) size += `W${data.width}`
  if(data.height !== null) {
    if(size !== "") size += " x "
    size += `H${data.height}`
  }
  if(size === "") size = "-";
  return size;
}
