import React, { Component } from 'react';
import Auth from 'components/auths/Auth';
import Login from 'components/auths/Login';
import Logout from 'components/auths/Logout';
import BaseContent from 'components/common/BaseContent';

import { Switch, Route, Redirect } from 'react-router-dom';

class MainRoute extends Component {
  render() {

    return (
      <Switch>
      　{/* ルートパス：：ログイン画面 */}
        <Route exact path="/" render={() => <Redirect to="/login" />}/>
        <Route path="/login" component={ Login }/>
        <Route path="/logout" component={ Logout }/>

        <Auth>
          <BaseContent />
        </Auth>
      </Switch>
    );
  }
}

export default MainRoute;
