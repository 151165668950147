import BaseDevice from 'devices/baseDevice'

class Sensor extends BaseDevice{
  getDriveShutterDiNo() {
    return 0;
  }

  parseRawData(rData) {
    return super.parseRawData(rData, {}, {}, {}, 0);
  }

}

export default Sensor;
