import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/mainMapAction';
import * as module from 'modules/moduleMainMap.js';
import * as cookies from 'modules/cookies.js';
import { Row, Col } from 'react-bootstrap';
import ModalShutterInfo from './ModalShutterInfo';
import IconEdit from 'images/icon_edit.png'
import IconAdd from 'images/icon_plus.png'
import NoImage from 'images/noimage2.png'

class ContentFacilityList extends Component {
  constructor(props) {
    super(props);

    this.showModal = false;
  }

  componentDidMount() {
    cookies.set("menuKey", "main_maps");
    const shutters = this.props.main_map.shutters;

    if(shutters.length){
      const factoryId = cookies.get("factoryId")
      this.props.getFactoryMapData(factoryId);
    }
  }

  render(){
    const photoInfo = this.props.main_map.photoInfo;
    const shutters = this.props.main_map.shutters;
    const selectedId = Number(cookies.get("shutterId"));
    return(
      <div className="main-contents-layout map-facility-list">
        <div className="card-header card-header-common">
          設備リスト
          <img className="new-shutter-icon ml-2" src={IconAdd} alt="IconAdd"
               onClick={() => this.onClickNewShutterModal()}/>
        </div>

        <table className="table table-hover map-facility-table">
          <tbody>
            {
              shutters.map(data => {
                let rowColor = "";
                if(selectedId === data.id){
                  rowColor = "table-warning";
                }
                const driveMode = module.shutterMarkDriveMode(data);
                const driveStatus = module.shutterMarkDriveStatus(data);
                let size = "-"
                if(data.width !== "-" || data.height !== "-"){
                  size = `${data.width} x ${data.height}`
                }

                let drItem = (
                  <div>
                    <span className="item-title-drcount">総開閉回数</span>
                    <span className="text-navy">{data.total_drive_count}</span>
                  </div>
                )
                
                // 新型の型式が決まるまでとりあえず。。。
                let productType = data.product_type;
                if(productType === "RW01")  productType = "-";

                let constructDate = data.construction_date
                if(constructDate === "-")  constructDate = "-年 -月";

                let img
                if(photoInfo[data.id] !== undefined){
                  const url = (window.URL || window.webkitURL).createObjectURL(photoInfo[data.id]);
                  img = (<img src={url} alt="ShutterPhoto"/>);
                }else{
                  img = (<img src={NoImage} alt="NoImage"/>);
                }

                return(
                  <tr className={rowColor} key={data.id} id={`shutter-id-${data.id}`}
                      onClick={() => this.selectedList(data.id)}>
                    <td>
                      <Row noGutters={true} className="align-items-center">
                        <Col xs="auto" className="item-photo">
                          {img}
                        </Col>
                        <Col className="ml-2">
                          <Row noGutters={true} className="align-items-center mb-1">
                            <Col xs="auto" className={`mr-1 status-icon ${driveStatus}`}>
                              {driveMode}
                            </Col>
                            <Col className="item-facility-name">
                              {data.name}
                            </Col>
                            <Col xs="auto" >
                              <span className="mr-1">設置年月</span>
                              <span className="text-navy">{constructDate}</span>
                            </Col>
                          </Row>
                          <Row noGutters={true}>
                            <Col>
                              <Row noGutters={true} className="facility-info">
                                <Col xs={12} md={3} className="item-info1">
                                  <div><span className="item-title">製造No</span>
                                       <span className="text-navy">{data.product_no}</span></div>
                                  <div><span className="item-title">型式</span>
                                  <font className="text-navy">{productType}</font></div>
                                </Col>

                                <Col xs={12} md={3}  className="item-info2">
                                  <div><span className="item-title">サイズ</span>
                                       <span className="text-navy">{size}</span></div>
                                  <div><span className="item-title">メーカー</span>
                                       <span className="text-navy">{data.maker}</span></div>
                                </Col>

                                <Col className="item-info-count">
                                  <div>{drItem}</div>
                                  <div><span className="item-title-mainte">ﾒﾝﾃﾅﾝｽ回数</span>
                                       <span className="text-navy">{data.maintenace_count}</span></div>
                                </Col>
                              </Row>
                            </Col>

                            <Col xs="auto" className="edit-icon">
                              <img className="" src={IconEdit} alt="IconEdit"
                                   onClick={() => this.onClickShowModal(data)}/>
                            </Col>
                          </Row>

                        </Col>
                      </Row>
                    </td>

                  </tr>
                )

              })
            }
          </tbody>
        </table>

        {this.formModalInfo()}
      </div>
    );
  }

  formModalInfo(){
    if(!this.props.main_map.showModal){
      return null
    }else{
      return(
        <ModalShutterInfo showModal={this.props.main_map.showModal}
                          clearModal={() => {this.onClickHideModal()}}/>
      )
    }
  }

  selectedList(shutterId){
    module.cancelMapPosiotion(-1, this.props)

    if(this.showModal) return;
    if(shutterId < 1) return;
    
    let showPopover = !this.props.main_map.showPopover[shutterId];
    if(window.innerWidth < 576) showPopover = false;

    if(showPopover){
      this.props.getShutterPhoto(shutterId);
    }

    this.props.showShutterPopover(shutterId, showPopover);
  }

  onClickShowModal(data){
    this.showModal = true;
    this.props.getShutterSpec(data.id);
  }

  onClickHideModal(){
    this.showModal = false;
    this.props.hideModal();
  }

  onClickNewShutterModal(){
    module.cancelMapPosiotion(-1, this.props);
    this.props.showNewShutterModal();
  }

  // コンポーネントの更新後に呼び出される
  //  >ブラウザ更新時における設備選択処理の実行
  componentDidUpdate(){
    const selectedId = Number(cookies.get("shutterId"));
    const shutters = this.props.main_map.shutters;
    const stateShutterId = this.props.main_map.shutterId;
    const mapObj = document.getElementById('main-map-file-img');
    const mapW = mapObj !== null ? mapObj.clientWidth : 0;

    if(!isNaN(selectedId) && shutters.length > 0 && stateShutterId === "" && mapW > 0){
      module.scrollShutterList(selectedId);
      this.selectedList(selectedId);
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentFacilityList);
