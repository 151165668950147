import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/trendAction';
import DriveGraph from 'modules/driveGraph';

class DiTrend extends Component {
  constructor(props) {
    super(props);
    this.imsi = this.props.imsi;
    this.shutterId = this.props.shutterId;
    this.dataNo = this.props.dataNo;
    this.startDate = this.props.startDate;
    this.endDate = this.props.endDate;
    this.idKey = `${this.props.shutterId}-${this.props.dataNo}`
    this.canvasKey = `di-trend-canvas-${this.idKey}`

    this.areaPadding = 15;
    this.headerH = 25;
    this.valAreaPadding = 15;
    this.isConnect = true;

    this.state = {
      diData: [],
      driveCount: "-",
      driveTime: "-",
      maxDriveTime: "-",
      graphHeight: 0,
      valueSize: 13
    };

    this.driveGraph = new DriveGraph(this.canvasKey);
    window.addEventListener('resize', () => {
      this.onResize();
    });

  }

  componentDidMount() {
    this.connectGetDiTrend();
    this.onResize();
  }

  render(){
    let name;
    if(this.props.name){ name = `（${this.props.name}）` }
    if(this.props.enableDiReload) {this.connectGetDiTrend(); }

    this.driveGraph.draw(this.state.diData);

    return(
      <div className="di-trend-area" id={`di-trend-area-${this.idKey}`}>
        <div className="di-trend-title">
          シャッター開 動作{name}
        </div>

        <canvas className="di-trend-canvas" id={this.canvasKey}/>

        <div className="row no-gutters justify-content-between"
             style={{fontSize: `${this.state.valueSize}px`}}>
          <div className="col di-trend-v-area">
            <div className="row no-gutters justify-content-between">
              <div className="col-auto di-trend-v-title">開閉回数</div>
              <div className="col-auto di-trend-v-value">
                <span className="di-trend-v-value-font">{this.state.driveCount}</span>回
              </div>
            </div>
          </div>

          <div className="col di-trend-v-area di-trend-v-area-space">
            <div className="row no-gutters justify-content-between">
              <div className="col-auto di-trend-v-title">開時間合計</div>
              <div className="col-auto di-trend-v-value">
                <span className="di-trend-v-value-font">{this.state.driveTime}</span>分
              </div>
            </div>
          </div>

          <div className="col di-trend-v-area">
            <div className="row no-gutters justify-content-between">
              <div className="col-auto di-trend-v-title">最大開時間</div>
              <div className="col-auto di-trend-v-value">
                <span className="di-trend-v-value-font">{this.state.maxDriveTime}</span>分
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }


  onResize(){
    const obj = document.getElementById(`di-trend-area-${this.idKey}`);

    if(obj != null){
      const height = obj.clientHeight;
      const graphH = height * 0.5
      const valueArea = height - this.headerH - graphH
                          - this.areaPadding - this.valAreaPadding;
      this.setState({
        graphHeight: graphH,
        valueSize: valueArea * 0.44
      });
    }
  }

  connectGetDiTrend(){
    if(!this.isConnect) return;
    this.isConnect = false;

    const callback = (data) => {
      this.setState({
        diData: data.diData,
        driveCount: data.driveCount,
        driveTime: data.driveTime,
        maxDriveTime: data.maxDriveTime
      });
      this.isConnect = true;
    }

    this.props.getDiTrend(this.shutterId, this.imsi, this.dataNo,
                          this.startDate, this.endDate, callback);
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(DiTrend);
