import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/reportAction';

class DailyDrData extends Component {

  render() {
    const drData = this.props.report.drData;

    return (
      <div className="content-report-data"
           style={{height:`${this.props.contentH}px`, paddingRight: "2px"}}>
        <table className="table table-sm rp-table rp-daily-dr-tbl">
          <tbody>
            <tr className="rp-header-record rp-h-daily-dr">
              <td>No</td>
              <td>信号名称</td>
              <td>日計(回数)</td>
              <td>日計(時:分)</td>
              <td>累計(回数)</td>
              <td>累計(時:分)</td>
            </tr>

            {this.contentData(drData)}
          </tbody>
        </table>
      </div>
    );
  }

  contentData(drData){
    let backColor = "change-bk-color";

    return(
      drData.map((dr, i) => {
        const totalCount = dr.total_count !== null ? dr.total_count : "-";
        const totalTime = dr.total_time !== null ? dr.total_time : "-";
        let dailyCount = dr.daily_count !== null ? dr.daily_count : "-";
        let dailyTime = dr.daily_time !== null ? dr.daily_time : "-";
        let editedCount = dr.changed_count !== null ? "rp-edit" : "";
        let editedTime  = dr.changed_time !== null ? "rp-edit" : "";

        if(dr.changed_count) dailyCount = dr.changed_count;
        if(dr.changed_time)  dailyTime  = dr.changed_time;
        if(i % 4 === 0){
          backColor = backColor === "" ? "change-bk-color" : "";
        }

        return(
          <tr key={dr.item_no} className={`rp-p-data ${backColor}`}>
            <td>{i + 1}</td>
            <td className="rp-p-data-name">{dr.name}</td>
            <td className={editedCount}>{dailyCount}</td>
            <td className={editedTime}>{dailyTime}</td>
            <td>{totalCount}</td>
            <td>{totalTime}</td>
          </tr>
        )
      })
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(DailyDrData);
