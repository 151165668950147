import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/reportAction';
import IconEdit from 'images/icon_edit.png'
import YearlyDrDataEdit from './YearlyDrDataEdit'

class YearlyDrData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false, itemNo: -1, name: null
    };
  }


  render() {
    const drData = this.props.report.drData;

    return (
      <div className="content-report-data"
           style={{height:`${this.props.contentH}px`}}>
        <table className="table-sm rp-table">
          <tbody>
            <tr className="rp-header-record">
              <td className="rp-h-item-title">
                <div className="rp-h-cell"></div>
                <div className="rp-h-cell"></div>
                <div className="rp-h-cell l-b-1"></div>
                <div className="rp-h-cell l-b-1">累計</div>
                <div className="rp-h-cell l-b-1">年計</div>
                <div className="rp-h-cell l-b-1">最大</div>
                <div className="rp-h-cell l-b-1">最小</div>
                <div className="rp-h-cell l-b-1">平均</div>
              </td>

              { this.headerData(drData) }
            </tr>

            { this.monthlyData(drData) }
          </tbody>
        </table>

        {this.formEditModalInfo()}
      </div>
    );
  }

  headerData(drData){
    return(
      drData.map(dr => {
        const totalCount = dr.total_count !== null ? dr.total_count : "-";
        const totalTime = dr.total_time !== null ? dr.total_time : "-";
        const yearlyCount = dr.yearly_count !== null ? dr.yearly_count : "-";
        const yearlyTime = dr.yearly_time !== null ? dr.yearly_time : "-";
        const countMax = dr.count_max !== null ? dr.count_max : "-";
        const countMin = dr.count_min !== null ? dr.count_min : "-";
        const countAve = dr.count_ave !== null ? dr.count_ave : "-";
        const timeMax  = dr.time_max !== null ? dr.time_max : "-";
        const timeMin  = dr.time_min !== null ? dr.time_min : "-";
        const timeAve  = dr.time_ave !== null ? dr.time_ave : "-";

        return(
          <td key={dr.item_no} className="rp-h-data">
            <div className="rp-h-cell rp-h-d-w-dr rp-h-d-edit-icon"
                 title="帳票データの編集が行えます"
                 onClick={() => this.onClickShowModal(dr)}>
              <img className="" src={IconEdit} alt="IconEdit"/>
            </div>
            <div className="rp-h-cell rp-h-d-w-dr rp-h-d-title">{dr.name}</div>
            <Row noGutters={true} className="rp-h-cell rp-h-d-category">
              <Col xm={6} >回数</Col>
              <Col xm={6} >時間(時:分)</Col>
            </Row>
            <Row noGutters={true} className="rp-h-cell rp-h-d-val l-b-1">
              <Col xm={6} >{totalCount}</Col>
              <Col xm={6} >{totalTime}</Col>
            </Row>
            <Row noGutters={true} className="rp-h-cell rp-h-d-val l-b-1">
              <Col xm={6} >{yearlyCount}</Col>
              <Col xm={6} >{yearlyTime}</Col>
            </Row>
            <Row noGutters={true} className="rp-h-cell rp-h-d-val l-b-1">
              <Col xm={6} >{countMax}</Col>
              <Col xm={6} >{timeMax}</Col>
            </Row>
            <Row noGutters={true} className="rp-h-cell rp-h-d-val l-b-1">
              <Col xm={6} >{countMin}</Col>
              <Col xm={6} >{timeMin}</Col>
            </Row>
            <Row noGutters={true} className="rp-h-cell rp-h-d-val l-b-1">
              <Col xm={6} >{countAve}</Col>
              <Col xm={6} >{timeAve}</Col>
            </Row>
          </td>
        )
      })
    );
  }

  monthlyData(drData){
    const mdata = [];
    let backColor = "";

    for(let m = 0; m < 12; m++){
      const tdData = [];
      let date = "-";

      drData.forEach((dr, i) => {
        const md = dr.monthly_data[m];
        if(md === undefined) return;

        if(i === 0) {
          if(m === 0 || md.month === 1){
            date = `${md.year}年${md.month}月`;
          }else{
            date = `${md.month}月`;
          }
        }

        let driveCount = md.dr_count !== null ? md.dr_count : "-";
        let driveTime  = md.dr_time !== null ? md.dr_time : "-";
        let editedCount = md.ch_count !== null ? "rp-edit" : "";
        let editedTime  = md.ch_time !== null ? "rp-edit" : "";

        if(md.ch_count) driveCount = md.ch_count;
        if(md.ch_time)  driveTime  = md.ch_time;

        tdData.push(
          <td key={`${dr.item_no}-${md.month}`}>
            <Row noGutters={true}>
              <Col xm={6} className={editedCount}>{driveCount}</Col>
              <Col xm={6} className={editedTime}>{driveTime}</Col>
            </Row>
          </td>
        )
      })
      if(tdData.length === 0) continue;
      if(m % 4 === 0){
        backColor = backColor === "" ? "change-bk-color" : "";
      }

      mdata.push(
        <tr key={m} className={`rp-p-data ${backColor}`}>
          <td>{date}</td>
          {tdData}
        </tr>
      );
    }

    return mdata;
  }

  formEditModalInfo(){
    if(!this.state.showModal){
      return null
    }else{
      return(
        <YearlyDrDataEdit shutterId={this.props.shutterId}
                          itemNo={this.state.itemNo}
                          name={this.state.name}
                          contentH={this.props.contentH}
                          showModal={this.state.showModal}
                          hideModal={() => {this.onClickHideModal()}}/>
      )
    }
  }

  onClickShowModal(dr){
    this.props.clearShutterPopover();
    this.setState({ showModal: true, itemNo: dr.item_no, name: dr.name });
  }

  onClickHideModal(){
    this.setState({ showModal: false, itemNo: -1, name: null });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(YearlyDrData);
