import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from 'modules/actions/reportAction';
import { Form, Row, Col, Button } from 'react-bootstrap';
import DailyDrData from './DailyDrData';
import DailyAiData from './DailyAiData';
import DailyPiData from './DailyPiData';
import MonthlyDrData from './MonthlyDrData';
import MonthlyAiData from './MonthlyAiData';
import MonthlyPiData from './MonthlyPiData';
import YearlyDrData from './YearlyDrData';
import YearlyAiData from './YearlyAiData';
import YearlyPiData from './YearlyPiData';
import Loading from 'components/common/Loading';

class ContentReportData extends Component {
  constructor(props) {
    super(props);

    this.displys = [
      { name:"1時間表示", value: 60},
      { name:"30分表示", value: 30},
      { name:"10分表示", value: 10},
      { name:"5分表示",  value: 5},
      { name:"1分表示",  value: 1}
    ]

    this.state = {
      contentH: 600,
      drBtn: "navy",
      aiBtn: "outline-navy",
      piBtn: "outline-navy"
    };

    window.addEventListener('resize', () => {
      this.onResize();
    });
  }

  componentDidMount() {
    this.onResize();
  }

  render() {
    const shutterId = this.props.shutterId;
    const loading = this.props.report.loading;
    const drData = this.props.report.drData;
    const aiData = this.props.report.aiData;
    const piData = this.props.report.piData;
    const displayDate = this.props.report.displayDate;
    const dataType = this.props.report.dataType;
    const drBtn = dataType === "drData" ? "navy" : "outline-navy";
    const aiBtn = dataType === "aiData" ? "navy" : "outline-navy";
    const piBtn = dataType === "piData" ? "navy" : "outline-navy";

    if(loading){
      return (
        <div className="report-alert-message">
          <Loading loading={loading}/>
        </div>
      );
    }else if(this.props.report.emptyData){
      return (
        <div className="report-alert-message">
          指定の日付にはデータがありませんでした。別の日付を指定してください。
        </div>
      );
    }else if(drData.length === 0 && aiData.length === 0 && piData.length === 0){
      return (
        <div className="report-alert-message">
          日付を指定して、表示ボタンを押してください
        </div>
      );
    }

    const drButton = (drData.length === 0) ? null : (
      <Col sm="col-auto">
        <Button variant={drBtn} className="btn-data-tab"
                onClick={() => {this.onClickDataType("drData")}}>
          運転時間
        </Button>
      </Col>
    )
    const aiButton = (aiData.length === 0) ? null : (
      <Col sm="col-auto">
        <Button variant={aiBtn} className="btn-data-tab"
                onClick={() => {this.onClickDataType("aiData")}}>
          計測値
        </Button>
      </Col>
    )
    const piButton = (piData.length === 0) ? null : (
      <Col sm="col-auto">
        <Button variant={piBtn} className="btn-data-tab"
                onClick={() => {this.onClickDataType("piData")}}>
          積算値
        </Button>
      </Col>
    )

    return (
      <>
        <Row noGutters={true} className="report-data-type-layout">
          {drButton}
          {aiButton}
          {piButton}
          {this.formSelectAiTime(aiData)}

          <Col className="disp-report-date">
            {displayDate}
          </Col>
        </Row>

        {this.contentData(shutterId)}
      </>
    );
  }

  contentData(shutterId){
    const reportType = this.props.report.reportType;
    const dataType = this.props.report.dataType;

    if(reportType === "daily"){
      if(dataType === "drData"){
        return(<DailyDrData shutterId={shutterId} contentH={this.state.contentH}/>);
      }else if(dataType === "aiData"){
        return(<DailyAiData shutterId={shutterId} contentH={this.state.contentH}/>);
      }else if(dataType === "piData"){
        return(<DailyPiData shutterId={shutterId} contentH={this.state.contentH}/>);
      }
    }else if(reportType === "monthly"){
      if(dataType === "drData"){
        return(<MonthlyDrData shutterId={shutterId} contentH={this.state.contentH}/>);
      }else if(dataType === "aiData"){
        return(<MonthlyAiData shutterId={shutterId} contentH={this.state.contentH}/>);
      }else if(dataType === "piData"){
        return(<MonthlyPiData shutterId={shutterId} contentH={this.state.contentH}/>);
      }
    }else if(reportType === "yearly"){
      if(dataType === "drData"){
        return(<YearlyDrData shutterId={shutterId} contentH={this.state.contentH}/>);
      }else if(dataType === "aiData"){
        return(<YearlyAiData shutterId={shutterId} contentH={this.state.contentH}/>);
      }else if(dataType === "piData"){
        return(<YearlyPiData shutterId={shutterId} contentH={this.state.contentH}/>);
      }
    }

    return null;
  }

  onClickDataType(dataType){
    this.props.changeDataType(dataType);
  }

  formSelectAiTime(aiData){
    const reportType = this.props.report.reportType;
    const dataType = this.props.report.dataType;

    if(reportType !== "daily" || dataType !== "aiData") return null;
    return(
      <Col sm="col-auto">
        <Form.Control as="select" size="sm" className="form-control"
                      id="select-item" defaultValue={ this.props.report.displyTime }
                      onChange={e => { this.props.changeDailyAiTime(e.target.value) }}>
          {
            this.displys.map(item => {
              return(
                <option key={item.value} value={item.value}>
                  {item.name}
                </option>
              )
            })
          }
        </Form.Control>

      </Col>
    );
  }

  onResize(){
    const diff = (window.innerWidth < 944) ? 260 : 228;
    this.setState({
      contentH: window.innerHeight - diff
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentReportData);
