import BaseDevice from 'devices/baseDevice'

const IO_MAP = {
  position: [0],
  driveMode: 4,
  ecoMode: 4,
  sensor: [0, 1, 2, 3]
};

const MODE_MAP = {
  auto: 0,
  manual: 1,
  eco:2
}

const DRIVE_STATUS = {
  0:{
    0: "init",
    1: "open",
    2: "opening",
    3: "eco",
    4: "stop",
    5: "closing",
    6: "close"
  }
}

const DRIVE_SHUTTER_DINO = 2;
const RESOLUTION = 16;

class GaController extends BaseDevice{
  getDriveShutterDiNo() {
    return DRIVE_SHUTTER_DINO;
  }

  parseRawData(rData) {
    return super.parseRawData(rData, IO_MAP, MODE_MAP, DRIVE_STATUS, RESOLUTION);
  }

}


export default GaController;
